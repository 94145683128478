import React from 'react'
import BannerBody from './BannerBody'
import RenderIf from '../../../hoc/RenderIf'

const BannerWrapper = ({ isShowBanner, setBannerStatus }) => {
  return (
    <RenderIf condition={!isShowBanner}>
      <BannerBody setBannerStatus={setBannerStatus} />
    </RenderIf>
  )
}

export default BannerWrapper
