import React, { useCallback } from 'react'
import { inject } from 'mobx-react'

import Profiles, { myProfilesConfig } from '../../Profiles'

const MyPublications = ({ getAll, UserId }) => {
  const customizedGetAll = useCallback(
    (fields) => getAll({ ...fields, UserId }),
    [getAll, UserId],
  )

  return <Profiles getAll={customizedGetAll} {...myProfilesConfig} />
}

const withData = inject((stores) => ({
  getAll: stores.CommunityStore.getAll,
  UserId: stores.AuthStore.user?.id,
}))

export default withData(MyPublications)
