const Profile = [{ labelKey: 'profile' }]

const Contact = [{ labelKey: 'contact' }]

const Community = [{ labelKey: 'community', url: '/community' }]

const CommunityProfile = [...Community, { labelKey: 'community_profile' }]

const Catalog = [{ labelKey: 'catalog', url: '/catalog' }]

const DeviceProfiles = [
  { labelKey: 'device_profiles', url: '/device-profiles' },
]
const DeviceProfile = [...DeviceProfiles, { labelKey: 'device_profile' }]

const Device = [...Catalog, { labelKey: 'device' }]

const RulesPage = [{ labelKey: 'rules', url: '/rules' }]

const Promotions = [{ labelKey: 'promotions', url: 'promotions' }]

const DesktopVersion = [
  { labelKey: 'desktop_version', url: '/desktop-version' },
]

export const Model = {
  Profile,
  Contact,
  Community,
  Catalog,
  Device,
  DeviceProfiles,
  DeviceProfile,
  RulesPage,
  CommunityProfile,
  Promotions,
  DesktopVersion,
}
