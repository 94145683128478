import React, { useCallback, useMemo } from 'react'
import { Button } from 'primereact/button'
import { inject } from 'mobx-react'

import { buildQuery } from '../../../../../helpers/http'
import { CommunityProfileStatus } from '../../../../../constants/community'
import SecondaryButton from '../../../../buttons/SecondaryButton'
import DownLoadButton from '../../../CommunityItem/ActionButtonGroup/DownLoadButton'
import ReportButton from '../../../CommunityItem/ActionButtonGroup/ReportButton'
import DeleteProfile from '../../../CommunityItem/ActionButtonGroup/DeleteProfile'

const MyPublicationsActionBody = ({ id, status, name, deleteOne }) => {
  const handleDelete = useCallback(() => deleteOne(id), [id, deleteOne])

  const config = useMemo(
    () => ({
      [CommunityProfileStatus.Pending]: {
        Component: <DeleteProfile onClick={handleDelete} />,
      },
      [CommunityProfileStatus.Published]: {
        Component: <DownLoadButton id={id} />,
      },
      [CommunityProfileStatus.Rejected]: {
        Component: <ReportButton name={name} />,
      },
    }),
    [handleDelete, name],
  )
  return config[status].Component
}

const withData = inject((stores) => ({
  deleteOne: stores.CommunityStore.deleteOne,
}))

export default withData(MyPublicationsActionBody)
