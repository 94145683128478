import React, { useCallback, useMemo, useState } from 'react'

import './CommonModalWindow.scss'
import { bindClassName } from '../../../utils/react'
import { CommonModalContext } from '../../../context'
import RenderIf from '../../hoc/RenderIf'
import CommonHeader from './CommonHeader'
import CommonBody from './CommonBody'

const CommonModalWindow = ({
  isActive,
  children,
  withBody = true,
  className,
  toggleModal,
  headerKey,
  icon,
}) => {
  const [isUIOpened, setIsUIOpened] = useState(isActive)

  const onTransitionEnd = useCallback(() => setIsUIOpened(isActive), [
    isActive,
    setIsUIOpened,
  ])

  const ctx = useMemo(() => ({ isUIOpened }), [isUIOpened])

  return (
    <CommonModalContext.Provider value={ctx}>
      <div
        {...bindClassName(
          'common-modal-wrapper',
          [isActive, 'active'],
          [className],
        )}
      >
        <div className="common-modal-content" onTransitionEnd={onTransitionEnd}>
          <RenderIf condition={isActive}>
            <CommonHeader
              toggleModal={toggleModal}
              labelKey={headerKey}
              icon={icon}
            />
            {withBody ? <CommonBody>{children}</CommonBody> : children}
          </RenderIf>
        </div>
      </div>
    </CommonModalContext.Provider>
  )
}

export default CommonModalWindow
