import React from 'react'
import { useTranslation } from 'react-i18next'

import './StatusTemplate.scss'
import { bindClassName } from '../../../../../utils/react'

const StatusTemplate = ({ rowData: { status } }) => {
  const { t } = useTranslation()

  return (
    <div {...bindClassName('common-status-template', [status])}>
      {t(`labels.${status}`)}
    </div>
  )
}

export default StatusTemplate
