import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'

import usePagination from '../../../hooks/table/usePagination'
import FilterForm from './FilterForm'
import View from './View'
import { CommunityError } from '../../messages/Error'
import { CommunityProfilesContext } from '../../../context'

export * from './config'

const Profiles = (props) => {
  const {
    getAll,
    cleanUp,
    Filters,
    values,
    columnList,
    columnPropsMapping,
    list,
  } = props
  const paginationData = usePagination(cleanUp, list)
  const ctx = useMemo(
    () => ({
      getAll,
      columnList,
      columnPropsMapping,
      Filters,
      cleanUp,
      values,
    }),
    [getAll, columnList, columnPropsMapping, Filters, cleanUp, values, list],
  )

  return (
    <CommunityProfilesContext.Provider value={ctx}>
      <FilterForm {...paginationData} />
      <CommunityError />
      <View {...paginationData} />
    </CommunityProfilesContext.Provider>
  )
}

const withData = inject((stores) => ({
  cleanUp: stores.CommunityStore.cleanUp,
  list: stores.CommunityStore.list,
}))

export default withData(observer(Profiles))
