import React, { useMemo, useState } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'

const config = {
  chart: {
    type: 'line',

    style: {
      color: `#76859c`,
    },
  },
  title: {},
  subtitle: {
    text: '',
  },
  xAxis: {
    categories: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
  },
  yAxis: {},
  plotOptions: {
    line: {
      dataLabels: {
        enabled: true,
      },
      enableMouseTracking: false,
    },
  },

  series: [],
}
const LineChart = ({ option }) => {
  const options = useMemo(() => {
    const chartConfig = { ...config }
    const chartKey = Object.keys(option)
    chartKey.forEach((item) => (chartConfig[item] = option[item]))
    return chartConfig
  }, [])

  return (
    <div className="card common-cart-style">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        style={{ width: '100%' }}
      />
    </div>
  )
}
export default LineChart
