import React, { useEffect } from 'react'
import ApplicationNav from '../statisticsType/ChartsStatistic/ApplicationNav'
import StatisticsContent from './StatisticContent'
import { inject } from 'mobx-react'

const CommonStatistic = ({
  activeApp,
  setActiveApp,
  devicesFilter,
  setDevicesFilter,
  dates,
  deviceName,
  setDeviceName,
  setDates,
  getAllStatistic,
}) => {
  useEffect(() => {
    getAllStatistic()
  }, [])

  return (
    <div className="page-statistic-wrapper">
      <ApplicationNav {...{ activeApp, setActiveApp }} />
      <StatisticsContent
        {...{
          deviceName,
          setDeviceName,
          devicesFilter,
          setDevicesFilter,
          dates,
          setDates,
        }}
      />
    </div>
  )
}
const withData = inject((stores) => ({
  getAllStatistic: stores.StatisticsStore.getAllStatistic,
  cleanUp: stores.StatisticsStore.cleanUp,
}))

export default withData(CommonStatistic)
