import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { inject } from 'mobx-react'

const DownLoad = ({ label, toggleModal }) => {
  const { t } = useTranslation()

  const handleClick = useCallback(() => {
    toggleModal()
  }, [toggleModal])
  return (
    <button
      className="see-more-notification-modal p-button-secondary p-button"
      onClick={handleClick}
    >
      {t(`actions.${label}`)}
    </button>
  )
}

const withData = inject((stores) => ({
  toggleModal: stores.DesktopApplicationStore.toggleModal,
}))

export default withData(DownLoad)
