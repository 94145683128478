import React from 'react'

import './ProfilePhoto.scss'
import Avatar from './Avatar'
import { UsersAvatarError } from '../../messages/Error'
import CropperModal from './CropperModal'
import useToggler from '../../../hooks/useToggler'
import { inject, observer } from 'mobx-react'
import { TiDeleteOutline } from 'react-icons/ti'

const ProfilePhoto = ({ removeAvatar }) => {
  const [isModalOpen, toggleModalOpen] = useToggler()

  return (
    <div>
      <CropperModal
        isModalOpen={isModalOpen}
        toggleModalOpen={toggleModalOpen}
      />
      <UsersAvatarError />
      <div className="p-col-12 p-d-flex p-justify-center user-profile-avatar-block">
        <div className="avatar-submit-btn" onClick={removeAvatar}>
          <TiDeleteOutline />
        </div>
        <Avatar toggleCropperModal={toggleModalOpen} />
      </div>
    </div>
  )
}

const withData = inject((stores) => ({
  removeAvatar: stores.ProfileStore.removeAvatar,
}))

export default withData(observer(ProfilePhoto))
