import React from 'react'
import { tabsConfig } from '../config'
import { useTranslation } from 'react-i18next'
import './Navigation.scss'

const Navigation = ({ setPage, activePage }) => {
  const { t } = useTranslation()

  const menu = tabsConfig.map(({ header }) => (
    <div
      className={`${activePage === header ? 'active' : ''} community-nav-item`}
      onClick={() => setPage(header)}
      key={header}
    >
      {t(`devices.tab_labels.${header}`)}
    </div>
  ))
  return <div className="community-nav-bar">{menu}</div>
}

export default Navigation
