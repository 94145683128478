import React from 'react'

export { formattedDate, formattedCreatedAt } from './date'

/**
 * @example
 * <MyComponent
 *    {...bindClassName (
 *      "classNameThatAlwaysWillBeShown",
 *      [someCondition, "showThisClassNameIfThisConditionIsTrue", "showThisClassNameIfThisConditionIsFalse"],
 *      [someCondition2, "showThisClassNameIfThisConditionIsTrue", "showThisClassNameIfThisConditionIsFalse"],
 *      [someCondition3, "showThisClassNameIfThisConditionIsTrue"] // bind additional className only if condition is true, otherwise do nothing
 *    )}
 * />
 * @example
 * this component accepts optional prop 'className'
 * if 'className' has been passed then it will be shown, otherwise empty className will be bounded
 * <MyComponent {...bindClassName([className])} />
 * */
export const bindClassName = (baseClassName = '', ...config) => {
  if (typeof baseClassName !== 'string') {
    config = [baseClassName, ...config]
    baseClassName = ''
  }
  const className = config.reduce(
    (acc, [condition, ifMatch = condition, ifNotMatch = '']) =>
      (acc + ` ${condition ? ifMatch : ifNotMatch}`).trim(),
    baseClassName,
  )
  return { className }
}

export const bool = (key) => (rowData) => (rowData[key] ? 'Yes' : 'No')

export const renderIntoTable = (Component, props) => (rowData) => (
  <Component rowData={rowData} {...rowData} {...props} />
)

export const mapValueFromEnum = (Enum, key) => (rowData) => Enum[rowData[key]]

export const renderActionBody = renderIntoTable

export const isElemMatchesOrHasClosest = (elem, selector) =>
  elem.matches(selector) || elem.closest(selector)

export const download = (filename, blobLink) => {
  if (typeof blobLink !== 'string')
    blobLink = window.URL.createObjectURL(blobLink)
  const a = document.createElement('a')
  a.download = filename
  a.href = blobLink
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  return blobLink
}
