import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import './NotificationItem.scss'
import { config } from './config'
import { NotificationType } from '../../../../constants/system-notifications'
import RenderIf from '../../../hoc/RenderIf'
import { MdClear } from 'react-icons/md'
import ActionItem from './ActionItem'

const NotificationItem = ({
  notification,
  deleteNotificationsById,
  toggleModalOpen,
}) => {
  const { t } = useTranslation()
  const { type, createdAt, payload, id } = notification
  const deleteItem = useCallback(() => deleteNotificationsById(id), [id])
  const { getLink, getInfo } = config[type]
  const message =
    type === NotificationType.Promotions
      ? notification.Promotion.shortText
      : t(`system-events.message.${type}`)

  const to = getLink?.(payload)
  return (
    <div className="notification-item-wrapper">
      <div className="notification-item-header">
        <span className="notification-item-headline">
          {' '}
          {t(`system-events.title.${type}`)}
        </span>
        <span className="notification-time">
          {moment(createdAt).format('DD.MM.YYYY HH:mm')}
        </span>
      </div>
      <div className="notification-item-body">
        <span className="event-description">
          {message}
          <RenderIf condition={!!getInfo}>
            <span>{getInfo?.(notification)}</span>
          </RenderIf>
        </span>
      </div>
      <div className="notification-item-footer">
        <button className="clear-notification-modal" onClick={deleteItem}>
          <MdClear />
          {t('actions.clear')}
        </button>
        <ActionItem
          notification={notification}
          toggleModalOpen={toggleModalOpen}
          to={to}
          type={type}
        />
      </div>
    </div>
  )
}

const withData = inject((store) => ({
  toggleModalOpen: store.NotificationsStore.toggleModalOpen,
  deleteNotificationsById: store.NotificationsStore.deleteNotificationsById,
}))

export default withData(observer(NotificationItem))
