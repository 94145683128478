import platform from 'platform'
import i18next from 'i18next'
import { ErrorMsg } from '../constants/errorMsg'
import api from '../configs/api'

export const authorizationHeaderName = 'Authorization'
export const refreshTokenHeaderName = 'x-refresh-token'
export const postDeleteTokenHeaderName = 'x-post-delete-token'
export const osPlatformName = 'x-os-platform'
export const languageName = 'x-language'

let csrfToken

if (platform.os.family === 'OS X') {
  platform.os.family = 'Mac ' + platform.os.family
}

/**
 * @example buildQuery({ a: 2, b:3, c:[1,2,3] }) --> '?a=2&b=3&c=1&c=2&c=3'
 * @example buildQuery({ a: '', b: '   ', c: [] }) --> ''
 * */
export const buildQuery = (queryObj = {}) => {
  const query = Object.entries(queryObj)
    .filter(([, value = '']) =>
      Array.isArray(value)
        ? !!value.length
        : !!(value !== void 0 && value !== null ? value : '').toString().trim()
            .length,
    )
    .map(([key, value]) => {
      const build = (value) => `${key}=${window.encodeURIComponent(value)}`
      return Array.isArray(value) ? value.map(build).join('&') : build(value)
    })
    .join('&')

  return query ? `?${query}` : ''
}

export default class Http {
  static AuthorizationHeader = authorizationHeaderName

  static HEADERS = {
    'Content-Type': 'application/json',
    [osPlatformName]: platform.os.toString(),
  }

  static async get(url, config) {
    try {
      return await request(url, 'GET', null, config)
    } catch (e) {
      return e
    }
  }

  static async post(url, data = {}, config) {
    try {
      return await request(url, 'POST', data, config)
    } catch (e) {
      return e
    }
  }

  static async patch(url, data = {}, config) {
    try {
      return await request(url, 'PATCH', data, config)
    } catch (e) {
      return e
    }
  }

  static async put(url, data = {}, config) {
    try {
      return await request(url, 'PUT', data, config)
    } catch (e) {
      return e
    }
  }

  static async delete(url, data = null, config) {
    try {
      return await request(url, 'DELETE', data, config)
    } catch (e) {
      return e
    }
  }
}

async function request(
  url,
  method = 'GET',
  data,
  {
    isJson = true,
    isBlob,
    isText,
    isArrayBuffer,
    isFormData,
    isCacheable = true,
    withCredentials = true,
    Authorization,
    headers = {},
  } = {},
) {
  const config = {
    method,
    headers: { ...Http.HEADERS, ...headers },
  }

  config.headers[languageName] = i18next.language
  if (withCredentials) config.credentials = 'include'
  if (csrfToken) config.headers['X-CSRF-Token'] = csrfToken
  if (!isCacheable) config.headers['Cache-Control'] = 'no-cache'
  if (Authorization) config.headers[Http.AuthorizationHeader] = Authorization

  if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method)) {
    if (data instanceof FormData) {
      config.body = data
      delete config.headers['Content-Type']
    } else if (data) {
      config.body = JSON.stringify(data)
    }
  }

  try {
    const response = await fetch(url, config)

    const handleResponse = async (res) => {
      if (res.status === 204) return

      switch (true) {
        case isBlob:
          return res.blob()
        case isText:
          return res.text()
        case isJson:
          return res.json()
        case isArrayBuffer:
          return res.arrayBuffer()
        case isFormData:
          return res.formData()
        default:
          return res
      }
    }

    if (response.ok) return handleResponse(response)

    const result = await response.json()
    const { message } = result
    if (response.status === 401 && message !== ErrorMsg.EMPTY_TOKEN) {
      try {
        await refreshTokenRequest()
        return fetch(url, config).then(handleResponse)
      } catch (e) {
        document.location.href = '/'
      }
    }
    if (message) result.message = message
    return result
  } catch (e) {
    return Promise.reject(e)
  }
}

export const refreshTokenRequest = async () => {
  await fetch(`${api.auth}/v1/token/refresh`, {
    credentials: 'include',
    headers: Http.HEADERS,
  })
}

export const csrfTokenRequest = async () => {
  const data = await Http.get(`${api.auth}/v1/csrf-token`, {
    headers: Http.HEADERS,
  })
  csrfToken = data.csrfToken
}
