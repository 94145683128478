import React, { useEffect } from 'react'
import { inject } from 'mobx-react'

import ForgotPasswordLink from './ForgotPasswordLink'
import SignHeadLine from './Form/SignHeadLine'
import OAuth, { Action } from './OAuth'
import { bindClassName } from '../../utils/react'
import AuthHeadLine from './AuthHeadLine'
import { useTranslation } from 'react-i18next'

import LanguageSelect from '../Language/LanguageSelect'

export { Action }

const Auth = ({
  form: Form,
  children,
  oauthAction,
  cleanUp,
  className,
  withHeadline = true,
}) => {
  const { t } = useTranslation()
  useEffect(() => cleanUp, [])

  return (
    <>
      <div {...bindClassName('p-fluid auth-page-wrapper', [className])}>
        <AuthHeadLine oauthAction={oauthAction} Action={Action} />
        <div className="sign-card relative">
          {Form && (
            <div className="sign-card-form-block">
              <Form />
            </div>
          )}
          <span className="auth-head-line-title">
            {t('auth.oauth.sub_title')}
          </span>
          <OAuth action={oauthAction} />
          {withHeadline && <SignHeadLine oauthAction={oauthAction} />}
          {children}
        </div>
      </div>
      <LanguageSelect className="not-auth-lng" />
    </>
  )
}

const withData = inject((stores) => ({
  cleanUp: stores.AuthStore.cleanUp,
}))

const Enhanced = withData(Auth)

Enhanced.ForgotPasswordLink = ForgotPasswordLink

export default Enhanced
