import React from 'react'

const Header = ({ closeModal, activeDate }) => (
  <div className="statistic-daily-modal-header">
    <span>{`${activeDate?.date} ${activeDate?.month}`}</span>
    <i className="pi pi-times" onClick={closeModal} />
  </div>
)

export default Header
