import React, { useCallback, useRef, useEffect } from 'react'
import { Field } from 'formik'

import SegmentInput from './SegmentInput'
import useLabel from '../../../hooks/form/useLabel'

const CommonInputSegmented = ({
  name,
  label: labelText,
  segmentsCount,
  children,
}) => {
  const inputRefs = useRef(new Array(segmentsCount).fill(null))

  const addFocus = useCallback((e, index) => {
    const inputs = inputRefs.current

    //refocus on prev input if no value, otherwise find next empty or take last
    const focusField =
      index && e && !e.target.value?.toString()
        ? inputs[--index]
        : inputs.find((elem) => !elem.inputRef?.current?.value?.toString()) ||
          inputs[inputs.length - 1]
    if (!focusField?.inputRef?.current) return
    focusField.inputRef.current.focus()
    focusField.inputRef.current.setSelectionRange(1, 1)
  }, [])

  useEffect(() => {
    addFocus()
  }, [])

  const inputGroup = new Array(segmentsCount).fill(0).map((item, index) => (
    <Field key={index} name={name}>
      {({ field, form }) => (
        <SegmentInput
          onChange={addFocus}
          index={index}
          field={field}
          form={form}
          inputRefs={inputRefs}
        />
      )}
    </Field>
  ))

  const { label } = useLabel({ labelText, className: 'm-o' })

  return (
    <div className="p-grid mt-3">
      {label}
      {children}
      <div className="p-inputgroup p-grid p-justify-between p-col-12 my-2">
        {inputGroup}
      </div>
    </div>
  )
}

export default CommonInputSegmented
