import React, { useCallback, useEffect } from 'react'
import { InputNumber } from 'primereact/inputnumber'

import { addListener } from '../../../../utils/function'
import { inRange } from '../../../../utils/string'

const SegmentInput = ({ field, onChange, index, inputRefs }) => {
  const currValue = field.value?.[index]
  const value = typeof currValue === 'number' ? currValue : null

  const customOnChange = useCallback(
    (e) => {
      e = e.originalEvent
      if (!inRange(0, 9, e.target.value)) e.target.value = value
      onChange(e, index)
      field.onChange(e)
    },
    [field.onChange, index, value],
  )

  const setRef = useCallback(
    (ref) => {
      inputRefs.current[index] = ref
    },
    [inputRefs, index],
  )

  useEffect(() => {
    const input = inputRefs?.current[index]?.inputRef?.current
    if (!input) return
    return addListener(
      inputRefs.current[index].inputRef.current,
      'paste',
      (e) => {
        const pasted = (e.clipboardData || window.clipboardData).getData('text')
        let currIndex = index
        const allCount = inputRefs.current.length
        for (let char of pasted) {
          if (currIndex === allCount) break
          if (/\d/.test(char))
            field.onChange({
              target: { name: `${field.name}.${currIndex++}`, value: +char },
            })
        }
        onChange()
      },
    )
  }, [field.onChange, index, onChange])

  return (
    <InputNumber
      ref={setRef}
      name={`${field.name}.${index}`}
      value={value}
      onChange={customOnChange}
      className="verify-code-input"
      required
    />
  )
}

export default SegmentInput
