import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

const useCommonSubmit = (item, submitOne) => {
  const history = useHistory()

  return useCallback(
    ({ ...values }, helpers) => {
      if (item) {
        values.id = item.id
      }

      submitOne(values, helpers, history)
    },
    [item, submitOne, history],
  )
}

export default useCommonSubmit
