import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import Condition, { If, Else } from '../../../hoc/Condition'
import NotificationItem from '../NotificationItem'

const ModalBody = ({ list }) => {
  const { t } = useTranslation()

  const elements = list.map((notification) => (
    <NotificationItem notification={notification} key={notification.id} />
  ))

  return (
    <Condition condition={!!list.length}>
      <If>
        <div className="common-modal-body">{elements}</div>
      </If>
      <Else>
        <div className="common-notification-title">
          {t('labels.no_notifications')}
        </div>
      </Else>
    </Condition>
  )
}

export default observer(ModalBody)
