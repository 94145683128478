import React, { useCallback } from 'react'
import { RadioButton } from 'primereact/radiobutton'

import { DesktopPlatform } from '../../../../../constants/desktop'
import { windowsPlatforms } from '../../../../../helpers/platform'

const labels = {
  [DesktopPlatform.Windows64]: 'Win 64',
  [DesktopPlatform.Windows32]: 'Win 32',
}

const WindowsRadioButtons = ({ setWinBit, winBit }) => {
  const setValue = useCallback((e) => setWinBit(e.target.value), [setWinBit])

  return (
    <div className="p-col-12 check-windows-dit-button">
      {windowsPlatforms.map((winPlatform) => {
        const inputId = `win-select-${winPlatform}`

        return (
          <React.Fragment key={winPlatform}>
            <RadioButton
              inputId={inputId}
              value={winPlatform}
              onChange={setValue}
              checked={winBit === winPlatform}
            />
            <label className="my-0" htmlFor={inputId}>
              {labels[winPlatform]}
            </label>
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default WindowsRadioButtons
