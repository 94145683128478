import React from 'react'

import './Empty.scss'
import useLabel from '../../hooks/form/useLabel'
import { bindClassName } from '../../utils/react'

const Empty = ({ labelKey, className }) => {
  const { labelText } = useLabel({ labelKey })

  return <p {...bindClassName('p-col-12 empty', [className])}>{labelText}</p>
}

export default Empty
