import React, { useCallback } from 'react'
import { inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import useRedirect from '../../../../../hooks/useRedirect'

const ItemLink = ({
  to,
  toggleModalOpen,
  label,
  setPromotionViewId,
  PromotionId,
}) => {
  const { t } = useTranslation()
  const redirect = useRedirect(to)

  const handleClick = useCallback(() => {
    toggleModalOpen()
    redirect()
    if (PromotionId) setTimeout(setPromotionViewId, 200, PromotionId)
  }, [toggleModalOpen, redirect])

  return (
    <button
      className="see-more-notification-modal p-button-secondary p-button"
      onClick={handleClick}
    >
      {t(`actions.${label}`)}
    </button>
  )
}

const withData = inject((stores) => ({
  setPromotionViewId: stores.PromotionsStore.setPromotionViewId,
}))

export default withData(ItemLink)
