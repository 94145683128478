import React from 'react'

const Boolean = ({ rowData, field }) =>
  rowData[field] ? (
    <i className="pi pi-check active-icon" />
  ) : (
    <i className="pi pi-times danger-icon" />
  )

export default Boolean
