export const pipe = (...fns) => (value) =>
  fns.reduce((acc, curr) => curr(acc), value)
export const compose = (...fns) => (value) =>
  fns.reduceRight((acc, curr) => curr(acc), value)

export const curry = (fn) => {
  const argsExpectedLen = fn.length

  const given = (alreadyGiven) => (...args) => {
    const updated = [...alreadyGiven, ...args]
    if (updated.length >= argsExpectedLen) return fn(...updated)
    return given(updated)
  }

  return given([])
}

export const partial = (fn, ...args) => (...rest) => fn(...args, ...rest)
export const partialReverse = (fn, ...rest) => (...args) => fn(...args, ...rest)

export const bind = (ctx, ...names) =>
  names.forEach((name) => (ctx[name] = ctx[name].bind(ctx)))

export const executeAll = (fns) => fns.map((fn) => fn())

export const addListener = (target, eventType, handler, config) => {
  target.addEventListener(eventType, handler, config)
  return () => target.removeEventListener(eventType, handler)
}

export const noop = () => {}

export const wrapThrowable = async (throwable) => {
  try {
    await throwable()
  } catch (e) {}
}

export const bindAllMethods = (ctx) => {
  let currCtx = ctx
  const names = []

  while (currCtx !== Object.prototype) {
    let nextCtx = Object.getPrototypeOf(currCtx)
    names.push(...Object.getOwnPropertyNames(currCtx))
    currCtx = nextCtx
  }

  names
    .filter((name) => name !== 'constructor' && typeof ctx[name] === 'function')
    .forEach((name) => bind(ctx, name))
}
