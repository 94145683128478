import React, { useMemo, useState } from 'react'

import { DesktopVersionTab } from '../../config'
import { isWindows, osDesktopPlatform } from '../../../../helpers/platform'
import { DesktopPlatform } from '../../../../constants/desktop'

import './DownloadButtonGroup.scss'
import WindowsRadioButtons from './WindowsRadioButtons'
import DownloadButton from './DownloadButton'
import RenderIf from '../../../hoc/RenderIf'

const DownloadButtons = ({ versionList, tabName }) => {
  const [winBit, setWinBit] = useState(() => {
    if (tabName !== DesktopVersionTab.Windows) return null

    return isWindows ? osDesktopPlatform : DesktopPlatform.Windows64
  })

  const versions = useMemo(() => {
    const item = winBit
      ? versionList.find(({ platform }) => platform === winBit)
      : versionList[0]

    return item?.versions || []
  }, [versionList, winBit])

  return (
    <div className="p-grid">
      <RenderIf condition={winBit}>
        <WindowsRadioButtons winBit={winBit} setWinBit={setWinBit} />
      </RenderIf>
      {versions.map((item) => (
        <DownloadButton {...item} key={item.id} />
      ))}
    </div>
  )
}

export default DownloadButtons
