import React, { useMemo } from 'react'

import api from '../../../../configs/api'
import detectDefaultDeviceImage from '../../../../helpers/detectDefaultDeviceImage'

const DevicesBlock = ({ item }) => {
  const {
    DeviceCategory: { name },
    id,
    images,
  } = item

  const altImg = useMemo(() => detectDefaultDeviceImage(name), [name])
  const url = useMemo(() => {
    const filename = !!images && images.find((el) => el?.filename)?.filename
    return !!filename
      ? `${api.devices}/v1/devices/${id}/images/${filename}`
      : altImg?.filename
  }, [images])
  return (
    <div className="devise-community-block">
      <div className="community-devise-img-block">
        <img src={url} />
      </div>
    </div>
  )
}

export default DevicesBlock
