import React, { useCallback, useState } from 'react'
import Cropper from 'react-easy-crop'
import { inject, observer } from 'mobx-react'

import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

const Redactor = ({ avatarUrl, closeModal, setCroppedImage, saveAvatar }) => {
  const { t } = useTranslation()

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels),
    [setCroppedAreaPixels],
  )

  const onSetCroppedImage = useCallback(
    () => setCroppedImage(croppedAreaPixels, closeModal),
    [setCroppedImage, croppedAreaPixels, closeModal, saveAvatar],
  )

  return (
    <>
      <div className="crop-container">
        <Cropper
          image={avatarUrl}
          crop={crop}
          zoom={zoom}
          aspect={1}
          cropShape="round"
          showGrid={false}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
        />
      </div>
      <Button
        className="secondary-btn button-save-preview"
        label={t('actions.crop')}
        onClick={onSetCroppedImage}
      />
    </>
  )
}

const withData = inject((stores) => ({
  avatarUrl: stores.ProfileStore.avatarUrl,
  setCroppedImage: stores.ProfileStore.setCroppedImage,
  saveAvatar: stores.ProfileStore.saveAvatar,
}))

export default withData(observer(Redactor))
