import React from 'react'
import { Message } from 'primereact/message'
import { useTranslation } from 'react-i18next'

import Condition, { If, Else } from '../../hoc/Condition'

const CustomMessage = ({
  msgPath = 'messages',
  msg,
  noWrapper,
  replacers,
  ...rest
}) => {
  const { t } = useTranslation()

  const message = msg && (
    <Message text={t(`${msgPath}.${msg}`, msg, replacers || {})} {...rest} />
  )

  return !msg ? null : (
    <Condition condition={noWrapper}>
      <If>{message}</If>
      <Else>
        <div className="w-100">{message}</div>
      </Else>
    </Condition>
  )
}

export default CustomMessage
