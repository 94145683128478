import { useCallback, useState } from 'react'

const useToggler = (defaultValue = false) => {
  const [active, setIsActive] = useState(defaultValue)

  const toggle = useCallback(() => setIsActive((active) => !active), [
    setIsActive,
  ])
  const open = useCallback(() => setIsActive(true), [])
  const close = useCallback(() => setIsActive(false), [])

  return [active, toggle, open, close, setIsActive]
}

export default useToggler
