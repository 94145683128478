import React from 'react'
import CommonCard from '../../common/CommonCard'
import { useTranslation } from 'react-i18next'

const ActiveProfilePage = ({ pageConfig }) => {
  const { t } = useTranslation()
  const { title, Component } = pageConfig
  const headTitle = title ? t(`profile.tab_labels.${title}`) : ''

  return (
    <CommonCard title={headTitle} className="profile-card-wrapper p-mx-auto">
      <Component />
    </CommonCard>
  )
}

export default ActiveProfilePage
