import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'

import Loadable from '../../hoc/Loadable'
import CustomInput from '../../common/CustomInput'
import CustomTextarea from '../../common/CustomTextarea'
import Configurations from './Configurations'
import ButtonSubmit from '../../buttons/ButtonSubmit'
import useInitialValues from '../../../hooks/form/useInitialValues'
import { DeviceProfileContext, ShareContext } from '../../../context'
import { config } from './config'
import useCommonSubmit from '../../../hooks/form/useCommonSubmit'
import { ErrorMsg } from '../../../constants/errorMsg'
import { MAX_COMMUNITY_DESCRIPTION_LENGTH } from '../../../constants/community'
import { RiArrowLeftSLine } from 'react-icons/ri'
import useRedirect from '../../../hooks/useRedirect'

const gridClassName = 'p-col-12'

const DeviceProfile = ({
  profile,
  isLoading,
  itemActionType,
  onItemClick,
  className,
  Error,
  FormEntities,
  onSubmit,
  submitLabelKey,
  values,
  shape,
  children,
}) => {
  const { defaultValues, ...rest } = config[itemActionType]
  const shareContext = useContext(ShareContext)
  const redirect = useRedirect('/community')
  const goBack = shareContext?.[1] || redirect
  const [activeDevice, setActiveDevice] = useState()

  useEffect(() => {
    if (!profile?.devices) return
    setActiveDevice(profile?.devices.find((item) => item?.id).Device)
  }, [profile])

  const mergedDefaultValues = useMemo(
    () => ({ ...defaultValues, ...(values || {}) }),
    [defaultValues, values],
  )

  const initialValues = useInitialValues(mergedDefaultValues, profile)

  const ctx = useMemo(
    () => ({ profile, itemActionType, onItemClick, ...rest }),
    [profile, itemActionType, onItemClick],
  )

  const handleSubmit = useCommonSubmit(profile, onSubmit)
  const cloceShare = useCallback(() => goBack(null), [])

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().trim().required(ErrorMsg.NAME_REQUIRED),
        desc: yup
          .string()
          .trim()
          .required(ErrorMsg.DESC_REQUIRED)
          .max(
            MAX_COMMUNITY_DESCRIPTION_LENGTH,
            ErrorMsg.COMMUNITY_DESCRIPTION_INVALID,
          ),

        ...(shape || {}),
      }),
    [shape],
  )

  return (
    <div className="device-card-content">
      <div className="go-back-button" onClick={cloceShare}>
        <RiArrowLeftSLine />
      </div>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        className={className}
        validationSchema={validationSchema}
      >
        {({ dirty }) => (
          <DeviceProfileContext.Provider value={ctx}>
            <Form className="device-configuration-block">
              {Error && <Error />}
              <Loadable loading={isLoading}>
                <div className="p-col-12 p-md-6">
                  <Configurations
                    activeDevice={activeDevice}
                    setActiveDevice={setActiveDevice}
                  />
                </div>
                <div className="p-col-12 p-md-6 p-d-flex p-dir-col p-justify-center">
                  <div className={gridClassName}>
                    <Field
                      labelText="name"
                      name="name"
                      component={CustomInput}
                      isRequired
                    />
                  </div>
                  <div className={gridClassName}>
                    <Field
                      labelText="description"
                      name="desc"
                      component={CustomTextarea}
                    />
                  </div>
                  {FormEntities && <FormEntities />}
                  {children}
                  <ButtonSubmit
                    dirty={dirty}
                    isLoading={isLoading}
                    submitLabelKey={submitLabelKey}
                  />
                </div>
              </Loadable>
            </Form>
          </DeviceProfileContext.Provider>
        )}
      </Formik>
    </div>
  )
}

export default DeviceProfile
