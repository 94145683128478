import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

const DownLoadButton = ({ id }) => {
  const { t } = useTranslation()
  return (
    <Link to={`/community/profile/${id}`} className="profile-download-link">
      <Button
        type="download"
        label={t('actions.download')}
        className="common-button"
      />
    </Link>
  )
}

export default DownLoadButton
