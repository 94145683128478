import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'

import './DesktopVersionPage.scss'
import { mapPlatformToTabName } from './config'
import useSearchParams from '../../hooks/useSearchParams'
import { buildQuery } from '../../helpers/http'
import CommonModalWindow from '../common/dialogWindow/CommonModalWindow'
import { createPortal } from 'react-dom'
import ModalComponent from './ModalComponent'
import useLabel from '../../hooks/form/useLabel'

const DesktopVersionPage = ({
  getAll,
  cleanUp,
  isLoading,
  isOpenModal,
  toggleModal,
}) => {
  const { labelText } = useLabel({ labelKey: 'download_desktop_title' })

  const { tab, ...searchParams } = useSearchParams()

  const history = useHistory()
  const { pathname } = history.location

  useEffect(() => {
    getAll()

    if (!tab) {
      const query = buildQuery({
        tab: mapPlatformToTabName(),
        ...searchParams,
      })
      history.push(`${pathname}${query}`)
    }

    return cleanUp
  }, [])

  return createPortal(
    <CommonModalWindow
      isActive={isOpenModal}
      headerKey={labelText}
      toggleModal={toggleModal}
    >
      <ModalComponent isLoading={isLoading} />
    </CommonModalWindow>,
    document.body,
  )
}

const withData = inject((stores) => ({
  getAll: stores.DesktopApplicationStore.getAll,
  cleanUp: stores.DesktopApplicationStore.cleanUp,
  isLoading: stores.DesktopApplicationStore.isLoading,
  isOpenModal: stores.DesktopApplicationStore.isOpenModal,
  toggleModal: stores.DesktopApplicationStore.toggleModal,
}))

export default withData(observer(DesktopVersionPage))
