import React from 'react'
import { inject, observer } from 'mobx-react'
import * as yup from 'yup'
import AuthForm, { shape } from '../Auth/Form'
import { pipe } from '../../utils/function'
import RenderIf from '../hoc/RenderIf'
import InputVerifyCode, {
  google2faLabel,
  smsLabel,
} from '../Auth/Form/InputVerifyCode'
import { useTranslation } from 'react-i18next'

const validationSchema = yup.object().shape(shape)

const values = { login: '', password: '', twoFactorAuthenticationCode: [] }

const Form = ({ enabled2fa, enabledSms, tryAuth, generateSMS2FA }) => {
  const isDisabled = enabled2fa || enabledSms
  const { t } = useTranslation()
  return (
    <AuthForm
      validationSchema={validationSchema}
      values={values}
      onSubmit={tryAuth}
      submitLabel={t('actions.sign_in')}
    >
      <AuthForm.MixLogin disabled={isDisabled} />
      <AuthForm.Password disabled={isDisabled} />
      <RenderIf condition={enabled2fa}>
        <InputVerifyCode
          label={google2faLabel}
          name="twoFactorAuthenticationCode"
        />
      </RenderIf>
      <RenderIf condition={enabledSms}>
        <InputVerifyCode
          handleResend={generateSMS2FA}
          label={smsLabel}
          name="twoFactorAuthenticationCode"
        />
      </RenderIf>
    </AuthForm>
  )
}

const withData = inject((stores) => ({
  enabled2fa: stores.AuthStore.enabled2fa,
  enabledSms: stores.AuthStore.enabledSms,
  tryAuth: stores.AuthStore.tryAuth,
  generateSMS2FA: stores.AuthStore.generateSMS2FA,
}))

export default pipe(observer, withData)(Form)
