import { curry } from './function'
import { mapToArray } from './array'
import { formatDate, isValidDate } from './date'

/**
 * @param currentTarget {object}
 * @param fields {Array<string> | string}; ["foo", "bar", "baz"] or "foo.bar.baz"
 * @param notFoundResponse? {any}
 * */
export const findValue = (currentTarget, fields, notFoundResponse) => {
  if (!fields) return notFoundResponse
  if (!Array.isArray(fields)) fields = fields.split('.')
  if (currentTarget === void 0) return notFoundResponse
  if (!fields.length) return currentTarget
  if (Array.isArray(currentTarget)) {
    for (const value of currentTarget) {
      const data = findValue(value, fields)
      if (data !== void 0) return data
    }
    return notFoundResponse
  }
  currentTarget =
    typeof currentTarget === 'object' && currentTarget
      ? currentTarget[fields.shift()]
      : void 0
  return findValue(currentTarget, fields, notFoundResponse)
}

/**
 * @param fields {string | string[]}
 * @param source {object}
 * @param target? {object}
 * @desc copy fields from source object to target
 * */
export const copy = curry((fields, source, target = {}) => {
  fields = mapToArray(fields)

  return Object.entries(source)
    .filter(([key]) => fields.includes(key))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), target)
})

export const defaultDatePredicate = (k, value) => {
  return (
    k.endsWith('At') ||
    (value && value instanceof Date) ||
    k.toLowerCase().includes('date')
  )
}
export const formatQueryFields = (
  fields = {},
  datePredicate = defaultDatePredicate,
) =>
  Object.entries(fields).reduce((acc, [key, value]) => {
    if (datePredicate(key) && isValidDate(value)) value = formatDate(value)
    return { ...acc, [key]: value }
  }, {})

export const merge = (target, source) => {
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object)
      Object.assign(source[key], merge(target[key], source[key]))
  }

  Object.assign(target || {}, source)
  return target
}

export const compareObjects = (v1, v2) => {
  if (typeof v1 !== typeof v2) {
    return false
  }

  if (typeof v1 === 'function') {
    return v1.toString() === v2.toString()
  }

  if (v1 instanceof Object && v2 instanceof Object) {
    if (Object.keys(v1).length !== Object.keys(v2).length) {
      return false
    }
    let r = true
    for (const k in v1) {
      r = compareObjects(v1[k], v2[k])
      if (!r) {
        return false
      }
    }
    return true
  } else {
    return v1 === v2
  }
}

export const isNil = (value) => value === void 0 || value === null
