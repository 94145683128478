import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

import Agreement from '../Agreement'
import useError from '../../hooks/form/useError'

const RegistrationByTokenPage = (props) => {
  const { registerByToken, cleanUp, isLoading, mobxErrorMsg } = props

  const { token } = useParams()
  const errorMsg = useError(mobxErrorMsg)

  useEffect(() => cleanUp, [])

  const onAgree = useCallback(() => registerByToken(token), [
    registerByToken,
    token,
  ])

  return (
    <Agreement onAgree={onAgree} isLoading={isLoading} errorMsg={errorMsg} />
  )
}

const withData = inject((stores) => ({
  registerByToken: stores.AuthStore.registerByToken,
  cleanUp: stores.AuthStore.cleanUp,
  isLoading: stores.AuthStore.isLoading,
  mobxErrorMsg: stores.AuthStore.errorMsg,
}))

export default withData(observer(RegistrationByTokenPage))
