import React from 'react'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

const DeleteProfile = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <div className="profile-download-link">
      <Button
        label={t(`actions.delete`)}
        className="danger--button"
        onClick={onClick}
      />
    </div>
  )
}

export default DeleteProfile
