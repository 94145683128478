import React from 'react'
import Loadable from '../../hoc/Loadable'
import PageTabs from '../../PageTabs'
import { tabs } from '../config'

const ModalComponent = ({ isLoading }) => {
  return (
    <div className="page-content-wrapper desktop-version-page">
      <Loadable loading={isLoading}>
        <PageTabs
          section="desktop_version"
          className="common-desktop-version-block"
          tabs={tabs}
        />
      </Loadable>
    </div>
  )
}

export default ModalComponent
