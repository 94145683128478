import React from 'react'
import { daily_statistic } from '../config'
import DailyStatisticsItem from './DailyStatisticsItem'

const DailyStatisticsContent = ({ activeDate, setActiveDate }) => (
  <div className="daily-statistics-content-wrapper">
    {daily_statistic.map((item) => (
      <DailyStatisticsItem
        item={item}
        key={item.date}
        setActiveDate={setActiveDate}
        activeDate={activeDate}
      />
    ))}
  </div>
)

export default DailyStatisticsContent
