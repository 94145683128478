import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TabPanel, TabView } from 'primereact/tabview'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useSearchParams from '../../hooks/useSearchParams'
import { buildQuery } from '../../helpers/http'
import usePrevious from '../../hooks/usePrevious'

const PageTabs = ({
  tabs,
  section,
  className,
  defaultActiveIndex,
  beforeTabChange,
  afterTabChange,
  ...props
}) => {
  className = className || 'common-profile-tab-wrapper'
  defaultActiveIndex = defaultActiveIndex || 0

  const history = useHistory()
  const { pathname } = history.location

  const { t } = useTranslation()
  const { tab, ...searchParams } = useSearchParams()

  const push = useCallback(
    (tab) => history.push(`${pathname}${buildQuery({ ...searchParams, tab })}`),
    [history, pathname, searchParams],
  )

  const filteredTabs = useMemo(
    () => tabs.filter(({ isActive = true }) => isActive),
    [tabs],
  )

  const findTabIndex = useCallback(
    (tab) => tabs.findIndex(({ tabName }) => tabName && tabName == tab),
    [tabs],
  )

  const initialState = useMemo(() => {
    let result = defaultActiveIndex
    if (tab) {
      const idx = findTabIndex(tab)
      if (~idx) result = idx
    } else if (defaultActiveIndex) {
      const { tabName } = tabs[defaultActiveIndex] || {}
      if (tabName) push(tabName)
    }
    return result
  }, [defaultActiveIndex, tab, tabs, push, findTabIndex])

  const [activeIndex, setActiveIndex] = useState(initialState)

  const prev = usePrevious({ tab })

  useEffect(() => {
    if (tab != prev.tab) {
      const idx = findTabIndex(tab)
      if (~idx && idx != activeIndex) setActiveIndex(idx)
    }
  }, [tab, activeIndex, filteredTabs, findTabIndex, setActiveIndex])

  const onTabChange = useCallback(
    (e) => {
      const { index } = e
      const { tabName } = filteredTabs[index] || {}

      if (beforeTabChange) beforeTabChange(e)

      if (tabName) push(tabName)
      setActiveIndex(index)

      if (afterTabChange) afterTabChange(e)
    },
    [setActiveIndex, tab, push, filteredTabs],
  )

  return (
    <TabView
      className={className}
      activeIndex={activeIndex}
      onTabChange={onTabChange}
      {...props}
    >
      {filteredTabs.map(({ header, Component, key = header, ...rest }) => {
        const title = t(`${section}.tab_labels.${header}`)

        return (
          <TabPanel
            key={key}
            header={title}
            headerClassName="profile-tab-headline"
          >
            <Component title={title} {...props} {...rest} />
          </TabPanel>
        )
      })}
    </TabView>
  )
}

export default React.memo(PageTabs)
