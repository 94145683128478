import { action, computed, observable } from 'mobx'

import api from '../../configs/api'
import Http, { buildQuery } from '../../helpers/http'
import { injectIntoTemplate } from '../../utils/string'

class DevicesStore {
  resource = `${api.devices}/v1/devices`
  resourceCategories = `${api.devices}/v1/devices/categories`

  itemUrlTemplate = '/catalog/device/{id}'

  @observable isLoading = false
  @observable list = []
  @observable categoriesList = []
  @observable categoriesCount = 0
  @observable count = 0
  @observable errorMsg = null
  @observable item = null

  @computed get i18n() {
    return this.item?.i18n || []
  }

  @action
  getDeviceCategories = async (fields = {}) => {
    this.isLoading = true
    fields = { isActive: true, ...fields }
    const query = buildQuery(fields)
    const { message = null, result = [], count = 0 } = await Http.get(
      `${this.resourceCategories}${query}`,
    )
    this.errorMsg = message
    this.categoriesList = result
    this.categoriesCount = count
    this.isLoading = false
  }

  @action
  getAll = async (fields) => {
    this.isLoading = true
    const query = buildQuery(fields)
    const { message = null, result = [], count = 0 } = await Http.get(
      `${this.resource}${query}`,
    )
    this.errorMsg = message
    this.list = result
    this.count = count
    this.isLoading = false
  }

  @action
  getOne = async (id) => {
    this.isLoading = true
    const { message = null, result = null } = await Http.get(
      `${this.resource}/${id}`,
    )
    this.errorMsg = message
    this.item = result
    this.isLoading = false
  }

  getItemUrl = (item) => {
    return injectIntoTemplate(this.itemUrlTemplate, item)
  }

  @action
  cleanUp = () => {
    this.isLoading = false
    this.list = []
    this.count = 0
    this.errorMsg = null
    this.item = null
    this.categoriesList = []
    this.categoriesCount = []
  }

  @action
  deviceCategoriesCleanUp = () => {
    this.categoriesList = []
    this.categoriesCount = []
  }
}

export default new DevicesStore()
