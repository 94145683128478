import React from 'react'

import Auth from '../Auth'
import ContactForm from '../ContactForm'
import GoToMainPage from '../buttons/GoToMainPage'

const ContactByTokenPage = () => {
  return (
    <Auth className="public-page contact" withHeadline={false}>
      <ContactForm headerBtn={<GoToMainPage />} />
    </Auth>
  )
}

export default ContactByTokenPage
