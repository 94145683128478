import React from 'react'
import ItemLink from '../../../../Notifications/NotificationModalWindow/NotificationItem/ItemLink'
import { inject } from 'mobx-react'

const Footer = ({ PromotionId, changeActiveItem }) => {
  return (
    <div className="promotion-banner-footer">
      <ItemLink
        to="/promotions"
        label="view"
        PromotionId={PromotionId}
        toggleModalOpen={changeActiveItem}
      />
    </div>
  )
}
const withData = inject((stores) => ({
  changeActiveItem: stores.NotificationsStore.changeActiveItem,
}))

export default withData(Footer)
