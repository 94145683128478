import React, { useCallback } from 'react'
import { Calendar } from 'primereact/calendar'
import { useTranslation } from 'react-i18next'

import useLabel from '../../../hooks/form/useLabel'
import { useFormError } from '../../../hooks/form/useError'

const CustomCalendarInput = ({
  form,
  labelText,
  field,
  overrideOnChange,
  onChange,
  placeholder,
  ...rest
}) => {
  const { t } = useTranslation()
  const { error } = useFormError(form, field)
  let { label, _placeholder } = useLabel({
    labelKey: labelText,
    field,
    placeholder,
  })

  placeholder = _placeholder || t('common.date')

  const handleChange = useCallback(
    (e) => {
      if (onChange) onChange(e)
      field.onChange(e)
    },
    [onChange, field.onChange],
  )

  return (
    <div className="p-grid p-dir-col p-nogutter">
      {label}
      <Calendar
        name={field.name}
        onChange={overrideOnChange || handleChange}
        value={field.value}
        monthNavigator
        yearNavigator
        yearRange="1900:2100"
        mask="99/99/9999"
        dateFormat="dd/mm/yy"
        placeholder={placeholder}
        showIcon
        showButtonBar
        {...rest}
      />
      {error}
    </div>
  )
}

export default CustomCalendarInput
