import React from 'react'
import { awardsConfig } from '../../config'
import AwardsGroup from './AwardsGroup'

const AwardsContent = () => (
  <>
    {Object.keys(awardsConfig).map((item, index) => (
      <AwardsGroup
        groupName={item}
        items={Object.values(awardsConfig)[index]}
        key={item}
      />
    ))}
  </>
)

export default AwardsContent
