import React from 'react'

import './OAuth.scss'
import Buttons, { Action } from './Buttons'

export { Action }

const OAuth = ({ action }) => <Buttons action={action} />

export default OAuth
