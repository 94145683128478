import React, { useCallback } from 'react'
import './DailyStatisticsItem.scss'
import { AiOutlineFieldTime } from 'react-icons/all'
import logo from '../../../../../../assets/images/sad-logo.png'

const DailyStatisticsItem = ({ item, setActiveDate, activeDate }) => {
  const handleClick = useCallback(() => {
    setActiveDate(item)
  }, [])

  const activeClass = Number(item?.time) ? '' : 'disabled'

  const activeColorClass =
    Number(item?.time) > 8
      ? 'green'
      : Number(item?.time) > 4 && Number(item?.time) <= 8
      ? 'yellow'
      : 'red'

  const itemInfo = (
    <div className={`total-active-time ${activeColorClass}`}>
      <AiOutlineFieldTime />
      {item?.time}
    </div>
  )

  return (
    <div
      className={'daily-statistics-item' + ' ' + activeClass}
      onClick={handleClick}
    >
      <span>{`${item?.date} ${item?.month}`}</span>
      <div className="total-active-time">
        {!!Number(item?.time) ? itemInfo : <img src={logo} />}
      </div>
      <div className="daily-statistics-item-total">
        {!!Number(item?.time) ? (
          <>
            <span>{`activity ratio - ${item?.['activity ratio']}`}</span>
            <span>{`distance- ${item?.['distance']}`}</span>
          </>
        ) : (
          <span>No statistics</span>
        )}
      </div>
    </div>
  )
}

export default DailyStatisticsItem
