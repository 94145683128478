import { action, observable } from 'mobx'

import { success, Notification } from '../../helpers/notifications'
import api from '../../configs/api'
import Http, { postDeleteTokenHeaderName } from '../../helpers/http'

class ContactStore {
  resource = `${api.auth}/v1/feedback`

  @observable isLoading = false
  @observable errorMsg = null

  @action
  submitContact = async ({ token, ...values }, { resetForm }) => {
    this.isLoading = true

    const headers = token ? { [postDeleteTokenHeaderName]: token } : {}

    const { message = null, result } = await Http.post(this.resource, values, {
      headers,
    })
    this.errorMsg = message
    if (result) {
      success(Notification.REQUEST_SUBMITTED)
      resetForm()
    }
    this.isLoading = false
  }

  @action
  cleanUp = () => {
    this.isLoading = false
    this.errorMsg = null
  }
}

export default new ContactStore()
