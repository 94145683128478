import { observable, action } from 'mobx'

import Deferred from '../../../helpers/Deferred'

let deferred

class ConfirmDialogStore {
  @observable dialogProps = null

  @action
  accept = () => {
    this.resolve(true)
  }

  @action
  reject = () => {
    this.resolve(false)
  }

  @action
  open = async (dialogProps) => {
    deferred = new Deferred()
    this.dialogProps = dialogProps
    return deferred.promise
  }

  /**@private*/
  resolve = (value) => {
    if (!deferred) return
    deferred.resolve(value)
    deferred = null
    this.dialogProps = null
  }
}

export default new ConfirmDialogStore()
