import React, { useCallback } from 'react'
import { observer, inject } from 'mobx-react'
import { Button } from 'primereact/button'

import OAuthButton from '../../../../Auth/OAuth/Buttons/Button'
import { pipe } from '../../../../../utils/function'
import { ErrorMsg } from '../../../../../constants/errorMsg'

const SocialButton = ({
  linkedOAuth,
  hasPassword,
  isLoading,
  unlinkSocial,
  setSettingsError,
  className = '',
  ...props
}) => {
  const { type } = props

  const handleUnlink = useCallback(() => {
    if (
      !hasPassword &&
      linkedOAuth &&
      Object.values(linkedOAuth).filter((v) => v).length <= 1
    )
      return setSettingsError(
        ErrorMsg.OAUTH_CANT_UNLINK_SINGLE_ACCOUNT_NO_PASSWORD,
      )

    unlinkSocial({ oauth: type })
  }, [linkedOAuth, hasPassword, setSettingsError, unlinkSocial, type])

  return (
    <OAuthButton
      {...props}
      disabled={isLoading || (linkedOAuth && linkedOAuth[type])}
      wrapperClassName="p-flex p-align-center"
      className="mr-2"
    >
      <Button
        type="button"
        icon="pi pi-times"
        className={className}
        disabled={isLoading || (linkedOAuth && !linkedOAuth[type])}
        onClick={handleUnlink}
      />
    </OAuthButton>
  )
}

const withData = inject((stores) => ({
  linkedOAuth: stores.AuthStore.user?.linkedOAuth,
  hasPassword: stores.AuthStore.user?.hasPassword,
  isLoading: stores.UsersStore.isLoading,
  unlinkSocial: stores.UsersStore.unlinkSocial,
  setSettingsError: stores.UsersStore.setSettingsError,
}))

export default pipe(observer, withData)(SocialButton)
