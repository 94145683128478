import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import Headline from './HeadLine'
import Gallery from './Gallery'
import Loadable from '../hoc/Loadable'
import Documents from './Documents'
import { DevicesError } from '../messages/Error'
import { RiArrowLeftSLine } from 'react-icons/ri'
import useRedirect from '../../hooks/useRedirect'

const DevicePreviewPage = ({ getOne, cleanUp, item, isLoading }) => {
  const { id } = useParams()

  useEffect(() => {
    if (id) getOne(id)
    return cleanUp
  }, [id, getOne])
  const redirect = useRedirect('/catalog')

  return (
    <div className="p-fluid page-content-wrapper p-d-flex p-dir-col">
      <DevicesError />
      <div className="go-back-button" onClick={redirect}>
        <RiArrowLeftSLine />
      </div>
      <div className="p-grid common-gallery-wrapper">
        <Loadable loading={isLoading}>
          <Gallery />
          <div className="device-detail-info p-col-12 p-md-6">
            <Headline item={item} />
            <Documents id={id} />
          </div>
        </Loadable>
      </div>
    </div>
  )
}

const withData = inject((stores) => ({
  i18n: stores.DevicesStore.i18n,
  getOne: stores.DevicesStore.getOne,
  cleanUp: stores.DevicesStore.cleanUp,
  item: stores.DevicesStore.item,
  isLoading: stores.DevicesStore.isLoading,
}))

export default withData(observer(DevicePreviewPage))
