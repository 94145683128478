import React from 'react'

const ContentGroup = ({ name, disabled, Component }) => {
  return (
    <div className={`awards-content-item ${disabled ? 'disabled' : ''}`}>
      <h4>{name}</h4>
      <Component />
    </div>
  )
}

export default ContentGroup
