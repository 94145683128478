import React from 'react'

export const googleIcon = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M24.5001 12.5239C24.5001 11.6916 24.4326 10.8549 24.2886 10.0361H12.7407V14.7507H19.3537C19.0793 16.2712 18.1975 17.6163 16.9064 18.471V21.53H20.8517C23.1685 19.3977 24.5001 16.2487 24.5001 12.5239Z"
        fill="#4285F4"
      />
      <path
        d="M12.7404 24.4858C16.0424 24.4858 18.827 23.4016 20.8559 21.5302L16.9106 18.4712C15.8129 19.2179 14.3959 19.6408 12.7449 19.6408C9.55089 19.6408 6.84273 17.486 5.87103 14.5889H1.7998V17.7424C3.87816 21.8766 8.11133 24.4858 12.7404 24.4858Z"
        fill="#34A853"
      />
      <path
        d="M5.86663 14.5891C5.35379 13.0686 5.35379 11.4221 5.86663 9.90156V6.74805H1.7999C0.0634443 10.2075 0.0634443 14.2832 1.7999 17.7426L5.86663 14.5891Z"
        fill="#FBBC04"
      />
      <path
        d="M12.7404 4.84532C14.4858 4.81833 16.1728 5.47513 17.4369 6.68075L20.9323 3.18534C18.719 1.10699 15.7814 -0.035652 12.7404 0.000336755C8.11133 0.000336755 3.87816 2.60952 1.7998 6.74823L5.86653 9.90174C6.83373 7.00015 9.54638 4.84532 12.7404 4.84532Z"
        fill="#EA4335"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="24"
          height="24.4858"
          fill="white"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)

export const facebookIcon = (
  <svg
    width="13"
    height="24"
    viewBox="0 0 13 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1482 13.3981L12.8219 9.08738H8.60731V6.29126C8.60731 5.11194 9.19617 3.96117 11.0838 3.96117H13V0.291262C13 0.291262 11.261 0 9.59833 0C6.12722 0 3.85845 2.06447 3.85845 5.80194V9.08738H0V13.3981H3.85845V23.8188C5.43183 24.0604 7.03392 24.0604 8.60731 23.8188V13.3981H12.1482Z"
      fill="#0369CC"
    />
  </svg>
)
