import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'

import defaultLogo from '../../../../assets/images/promotions/default-logo.png'
import Header from './Header'
import Footer from './Footer'
import { getMediaLink } from '../../../../helpers/promotions'
import MediaItem from '../../MediaItem'

const TIME_CORRECTION = 1000

const BannerBody = ({ changeActiveItem, notSeenPromotionsList, banner }) => {
  const { visualizationTime, shortText, startDate, mediaType, id } =
    banner?.Promotion
  const duration = visualizationTime * TIME_CORRECTION

  useEffect(() => {
    const timerId = setTimeout(() => {
      changeActiveItem()
    }, duration)
    return () => clearTimeout(timerId)
  }, [notSeenPromotionsList])

  return (
    <>
      <Header startDate={startDate} />
      <div className="promotion-banner-content">
        <MediaItem
          mediaType={mediaType}
          mediaLink={getMediaLink(banner?.Promotion)}
        />
        <span>{shortText}</span>
      </div>
      <Footer PromotionId={id} />
    </>
  )
}

const withData = inject((stores) => ({
  notSeenPromotionsList: stores.NotificationsStore.notSeenPromotionsList,
  changeActiveItem: stores.NotificationsStore.changeActiveItem,
  activeBannerId: stores.NotificationsStore.activeBannerId,
}))

export default withData(observer(BannerBody))
