import React, { useCallback, useMemo } from 'react'
import { inject } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import AuthForm, { confirmPasswordShape } from '../Auth/Form'
import { loginFieldsExcludes, loginFieldsShape } from '../../constants/user'
import { countryShape } from '../Auth/Form/Country'
import { DEFAULT_COUNTRY_ISO, find } from '../common/CustomInputPhone/helpers'
import CountryBlock from './CountryBlock'
import { useTranslation } from 'react-i18next'

const validationSchema = yup.object().shape(
  {
    ...confirmPasswordShape,
    ...loginFieldsShape,
    ...countryShape,
  },
  loginFieldsExcludes,
)

const Form = ({ register, geoInfo }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const values = useMemo(() => {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      country: find(geoInfo?.country || DEFAULT_COUNTRY_ISO)?.name || null,
    }
  }, [geoInfo])

  const onSubmit = useCallback(
    (values) => register(values, history),
    [register, history],
  )

  return (
    <AuthForm
      validationSchema={validationSchema}
      values={values}
      onSubmit={onSubmit}
      submitLabel={t('actions.sign_up')}
    >
      <AuthForm.Email />
      <AuthForm.Password />
      <AuthForm.Password name="confirmPassword" labelText="confirm_password" />
      <CountryBlock />
    </AuthForm>
  )
}

const withData = inject((stores) => ({
  register: stores.AuthStore.register,
  geoInfo: stores.AuthStore.geoInfo,
}))

export default withData(Form)
