import { observable } from 'mobx'

import { noop } from '../../../utils/function'

class InfoDialogStore {
  @observable messageKey = null
  @observable callback = noop

  @observable
  open = (messageKey, callback) => {
    this.messageKey = messageKey
    if (typeof callback === 'function') this.callback = callback
  }

  @observable
  close = () => {
    this.callback()
    this.messageKey = null
    this.callback = noop
  }
}

export default new InfoDialogStore()
