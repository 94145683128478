import React from 'react'

import ErrorBase, { createError } from '../../../messages/Error'
import useError from '../../../../hooks/form/useError'

const Error = ({ errorMsg }) => {
  const error = useError(errorMsg)

  return <ErrorBase errorMsg={error} />
}

export default createError(Error, 'UsersStore', 'settingsErrorMsg')
