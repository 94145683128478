import React from 'react'
import Header from './Header'
import Body from './Body'

import './BannerBody.scss'

const BannerBody = ({ setBannerStatus }) => {
  return (
    <div className="body-verified-wrapper">
      <Header setBannerStatus={setBannerStatus} />
      <Body />
    </div>
  )
}

export default BannerBody
