import React, { useMemo } from 'react'
import { inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'

const DownloadButton = ({ getDownloadLink, id, version }) => {
  const { t } = useTranslation()
  const to = useMemo(() => getDownloadLink(id), [getDownloadLink, id])

  return (
    <div className="download-group-wrapper">
      <a href={to} target="_blank" rel="noopener noreferrer">
        <Button
          label={`${t('actions.version')}  ${version}`}
          icon="pi pi-download"
          className="p-button-secondary"
        />
      </a>
    </div>
  )
}

const withData = inject((stores) => ({
  getDownloadLink: stores.DesktopApplicationStore.getDownloadLink,
}))

export default withData(DownloadButton)
