import React from 'react'

import Country from '../../Auth/Form/Country'
import CountryInfo from './CountryInfo'

import './CountryBlock.scss'

const CountryBlock = () => {
  return (
    <div className="country-wrapper w-100 p-my-2 ">
      <Country />
      <CountryInfo />
    </div>
  )
}

export default CountryBlock
