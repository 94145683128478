import React from 'react'

const StatisticsBar = () => {
  return (
    <div className="common-bar-wrapper">
      <div className="quantity-call-info">
        <div className="quantity-call-info__elem">
          <div className="quantity-statistics-info__elem-value">
            <p className="quantity-call-info__label action-type-label">
              Action type
            </p>
            <span
              className="quantity-call-info__label "
              title="За предидущий период"
            >
              Average ratio (last 30 days)
            </span>
            <span className="primary-meaning" title="За выбранный период">
              Total count
            </span>
          </div>
        </div>
        <div className="quantity-call-info__elem">
          <p className="quantity-call-info__label action-type-label">
            Left clicks
          </p>
          <div className="quantity-statistics-info__elem-value ">
            <span
              className="quantity-call-info__label"
              title="За предидущий период"
            >
              234
            </span>
            <span className="primary-meaning" title="За выбранный период">
              2312
            </span>
          </div>
        </div>
        <div className="quantity-call-info__elem">
          <p className="quantity-call-info__label action-type-label">
            Right clicks
          </p>
          <div className="quantity-statistics-info__elem-value ">
            <span
              className="quantity-call-info__label"
              title="За предидущий период"
            >
              12
            </span>
            <span className="primary-meaning  " title="За выбранный период">
              245
            </span>
          </div>
        </div>
        <div className="quantity-call-info__elem">
          <p className="quantity-call-info__label action-type-label">
            Middle clicks
          </p>
          <div className="quantity-statistics-info__elem-value">
            <span
              className="quantity-call-info__label"
              title="За предидущий период"
            >
              7
            </span>
            <span className="primary-meaning" title="За выбранный период">
              12
            </span>
          </div>
        </div>
        <div className="quantity-call-info__elem d-flex flex-column justify-content-start align-items-start">
          <p className="quantity-call-info__label action-type-label">
            Mouse 4/5
          </p>
          <div className="quantity-statistics-info__elem-value d-flex flex-nowrap justify-content-around align-items-center">
            <span
              className="quantity-call-info__label"
              title="За предидущий период"
            >
              2
            </span>
            <span className="primary-meaning" title="За выбранный период">
              25
            </span>
          </div>
        </div>
        <div className="quantity-call-info__elem d-flex flex-column justify-content-start align-items-start">
          <p className="quantity-call-info__label action-type-label">Scroll</p>
          <div className="quantity-statistics-info__elem-value d-flex flex-nowrap justify-content-around align-items-center">
            <span
              className="quantity-call-info__label"
              title="За предидущий период"
            >
              223
            </span>
            <span className="primary-meaning" title="За выбранный период">
              23215
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatisticsBar
