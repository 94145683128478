import { curry } from './function'
import { Order } from '../constants'

export const unique = (arr, comparator) => {
  if (!comparator) return [...new Set(arr)]
  return arr.filter(
    (item, index) =>
      index ===
      arr.findIndex((_item, _index) => comparator(item, _item, index, _index)),
  )
}

export const mapToArray = (data) =>
  Array.isArray(data) ? data : data ? [data] : []

export const sum = (arr, valueExtractor = (value) => value) =>
  arr.reduce((acc, curr) => acc + +valueExtractor(curr), 0)

export const forkFilter = curry((predicate, arr) => {
  const first = []
  const second = []
  arr.forEach((item, index, arr) => {
    if (predicate(item, index, arr)) first.push(item)
    else second.push(item)
  })
  return [first, second]
})

export const findInCollection = curry(
  (prop, collection, value, strongCompare = false) =>
    collection.find((item) =>
      strongCompare ? item[prop] === value : item[prop] == value,
    ),
)

export const findById = findInCollection('id')

export const findIndexInCollection = curry(
  (prop, collection, value, strongCompare = false) =>
    collection.findIndex((item) =>
      strongCompare ? item[prop] === value : item[prop] == value,
    ),
)

export const findIndexById = findIndexInCollection('id')

export const makeSelectOptionsFromEnum = (Enum) =>
  Object.entries(Enum).reduce(
    (acc, [label, value]) => [...acc, { label, value }],
    [],
  )

export const sortCompare = (config) => (curr, next) => {
  return config.reduce((acc, { order, extract = (v) => v }) => {
    if (acc) return acc

    const getCurr = () => extract(curr)
    const getNext = () => extract(next)

    const { value1, value2 } =
      order === Order.ASC
        ? { value1: getCurr(), value2: getNext() }
        : { value1: getNext(), value2: getCurr() }

    if (value1 === value2) return 0
    return value1 < value2 ? -1 : 1
  }, 0)
}

export const sort = curry((config, arr) => arr.sort(sortCompare(config)))
