import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import { mapToArray } from '../utils/array'

export const mapSearchParamsToObj = (search) => {
  const obj = {}

  for (const [key, value] of new URLSearchParams(search).entries()) {
    const existing = obj[key]
    obj[key] = existing ? [...mapToArray(existing), value] : value
  }

  return obj
}

const useSearchParams = () => {
  const { search } = useLocation()

  return useMemo(() => mapSearchParamsToObj(search), [search])
}

export default useSearchParams
