import React from 'react'
import { inject, observer } from 'mobx-react'
import { pipe } from '../../../utils/function'
import ImgPreview from '../../ImgPreview'
import avatar from '../../../assets/images/avatar.png'
import { Link } from 'react-router-dom'

const UserInfo = ({ avatarUrl }) => (
  <Link to="/profile">
    <ImgPreview className="user-avatar-info" src={avatarUrl || avatar} />
  </Link>
)

const withData = inject((stores) => ({
  avatarUrl: stores.UsersStore.currentUserAvatarUrl,
}))

export default pipe(observer, withData)(UserInfo)
