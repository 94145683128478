import React, { useContext } from 'react'
import { inject } from 'mobx-react'
import FilterForm from './FilterForm'
import View from './View'
import { DeviceProfilesError } from '../../messages/Error'
import useToggler from '../../../hooks/useToggler'
import { BsFilter } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import Condition, { Else, If } from '../../hoc/Condition'
import MyDeviceProfile from '../MyDeviceProfile'
import { ShareContext } from '../../../context'
import { bindClassName } from '../../../utils/react'

const MyPrivateProfiles = () => {
  const { t } = useTranslation()
  const [isFilter, toggleFilter] = useToggler(false)
  const [shareContext, setShareContext] = useContext(ShareContext)

  return (
    <Condition condition={!!shareContext}>
      <If>
        <MyDeviceProfile
          isPublish
          id={shareContext}
          postPublish={setShareContext}
        />
      </If>
      <Else>
        <button
          {...bindClassName(
            'p-button p-component secondary-btn filter-button p-ml-auto',
            [isFilter, 'active'],
          )}
          onClick={toggleFilter}
        >
          <BsFilter />
          {t('labels.filters')}
        </button>
        <FilterForm isFilter={isFilter} />
        <DeviceProfilesError />
        <View />
      </Else>
    </Condition>
  )
}

const withData = inject((stores) => ({
  deviceCategoriesCleanUp: stores.DevicesStore.deviceCategoriesCleanUp,
  cleanUp: stores.DeviceProfilesStore.cleanUp,
}))

export default withData(MyPrivateProfiles)
