import { useEffect, useRef, useCallback } from 'react'
import { mapToArray } from '../../utils/array'

export * from './keyboard'

const useKeyboard = (config, target = window) => {
  const buffer = useRef([]).current

  const isMatch = useCallback((keys) => {
    keys = mapToArray(keys)

    return (
      keys.length === buffer.length &&
      keys.every((keyCode, index) => buffer.indexOf(keyCode) === index)
    )
  }, [])

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!buffer.includes(e.keyCode)) buffer.push(e.keyCode)

      config
        .filter(({ keys }) =>
          Array.isArray(keys[0]) ? keys.some(isMatch) : isMatch(keys),
        )
        .forEach((item) => item.handler(e))
    }

    const handleKeyUp = (e) => {
      const index = buffer.indexOf(e.keyCode)
      if (~index) buffer.splice(index, 1)
    }

    target.addEventListener('keydown', handleKeyDown)
    target.addEventListener('keyup', handleKeyUp)

    return () => {
      target.removeEventListener('keydown', handleKeyDown)
      target.removeEventListener('keyup', handleKeyUp)
    }
  }, [config])
}

export default useKeyboard
