import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ForgotPasswordLink = () => {
  const { t } = useTranslation()
  return (
    <div className="p-col-12 p-mr-3 action-link-btn">
      <Link to="/forgot-password">
        <span className="alternative-sign-title w-content">
          {t(`labels.forgot_password`)}
        </span>
      </Link>
    </div>
  )
}

export default ForgotPasswordLink
