const api = {
  desktopAppUri: process.env.REACT_APP_DESKTOP_APP_URI,
  auth: process.env.REACT_APP_API_DOMAIN_NAME,
  devices: process.env.REACT_APP_API_DOMAIN_NAME,
  notifications: process.env.REACT_APP_API_DOMAIN_NAME,
  applications: process.env.REACT_APP_API_DOMAIN_NAME,
  statistics: process.env.REACT_APP_API_DOMAIN_NAME,
  desktop: process.env.REACT_APP_API_DOMAIN_NAME,
  ws: process.env.REACT_APP_WS_DOMAIN_NAME,
}

export default api
