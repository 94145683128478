import React from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { documentationTab } from '../../../../constants'

const DownLoadBtn = ({ activePage, language }) => {
  const { t } = useTranslation()
  const documentCondition = activePage === documentationTab.Documents
  const dirPas = documentCondition
    ? `${activePage}/manual`
    : `policy/${activePage}`
  const docPath = `/assets/i18n/documents/${language}/${dirPas}.pdf`
  return (
    <button className="download-btn">
      <a href={docPath} download>
        {t('labels.download')}
      </a>
    </button>
  )
}
const withData = inject((stores) => ({
  language: stores.LanguageStore.language,
}))

export default withData(observer(DownLoadBtn))
