import React from 'react'

import { buildAppImageUrl } from '../../../../../mobx'
import { Avatar } from 'primereact/avatar'

const ApplicationAvatar = ({ item }) => {
  const image = item.name === 'all' ? item.image : buildAppImageUrl(item)

  const avatarImgProps = image ? { image } : { icon: 'pi pi-question' }

  return <Avatar {...avatarImgProps} />
}

export default ApplicationAvatar
