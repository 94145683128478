import React from 'react'
import { observer, inject } from 'mobx-react'

import { curry, pipe } from '../../../utils/function'
import CustomMessage from '../CustomMessage'

const Error = ({ errorMsg, ...rest }) => (
  <CustomMessage {...rest} msgPath="errors" msg={errorMsg} severity="error" />
)

export const createError = curry((Error, storeName, errorKey = 'errorMsg') => {
  const withData = inject((stores) => ({
    errorMsg: stores[storeName][errorKey],
  }))

  return pipe(observer, withData)(Error)
})

const _createError = createError(Error)
export const ContactError = _createError('ContactStore')
export const ChangePasswordError = _createError(
  'ProfileStore',
  'passwordErrorMsg',
)
export const UsersError = _createError('UsersStore')
export const UserSettingsError = _createError('UsersStore', 'settingsErrorMsg')
export const UsersNotificationSettingsError = _createError(
  'UsersStore',
  'notificationSettingsErrorMsg',
)
export const UsersAvatarError = _createError('UsersStore', 'avatarErrorMsg')

export const DevicesError = _createError('DevicesStore')
export const DeviceProfilesError = _createError('DeviceProfilesStore')
export const CommunityError = _createError('CommunityStore')
export const DesktopApplicationError = _createError('DesktopApplicationStore')

export default Error
