export const ErrorMsg = {
  /**
   * Validation
   */
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  EMAIL_REQUIRED: 'EMAIL_REQUIRED',
  LOGIN_REQUIRED: 'LOGIN_REQUIRED',
  PHONE_REQUIRED: 'PHONE_REQUIRED',
  PASSWORD_REQUIRED: 'PASSWORD_REQUIRED',
  CONFIRM_PASSWORD_REQUIRED: 'CONFIRM_PASSWORD_REQUIRED',
  CODE_2FA_REQUIRED: 'CODE_2FA_REQUIRED',
  RESET_TOKEN_REQUIRED: 'RESET_TOKEN_REQUIRED',
  ACTION_FORBIDDEN: 'ACTION_FORBIDDEN',
  /**
   * USER
   * */
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  FIRST_NAME_LATIN_CHAR: 'FIRST_NAME_LATIN_CHAR',
  LAST_NAME_LATIN_CHAR: 'LAST_NAME_LATIN_CHAR',
  FIRST_NAME_INVALID_LENGTH: 'FIRST_NAME_INVALID_LENGTH',
  LAST_NAME_INVALID_LENGTH: 'LAST_NAME_INVALID_LENGTH',
  CANT_DELETE_THIS_USER: 'CANT_DELETE_THIS_USER',
  USER_FILE_NOT_FOUND: 'USER_FILE_NOT_FOUND',
  USER_AVATAR_NOT_FOUND: 'USER_AVATAR_NOT_FOUND',
  EMAIL_NOT_VALID: 'EMAIL_NOT_VALID',
  PHONE_NOT_VALID: 'PHONE_NOT_VALID',
  FIRST_NAME_NOT_VALID: 'FIRST_NAME_NOT_VALID',
  LAST_NAME_NOT_VALID: 'LAST_NAME_NOT_VALID',
  NICKNAME_NOT_VALID: 'NICKNAME_NOT_VALID',
  CURRENT_PASSWORD_NOT_VALID: 'CURRENT_PASSWORD_NOT_VALID',
  NEW_PASSWORD_NOT_VALID: 'NEW_PASSWORD_NOT_VALID',
  PASSWORD_NOT_VALID: 'PASSWORD_NOT_VALID',
  PASSWORD_IS_SHORT: 'PASSWORD_IS_SHORT',
  WRONG_PASSWORD: 'WRONG_PASSWORD',
  NO_CURRENT_PASSWORD: 'NO_CURRENT_PASSWORD',
  CONFIRM_PASSWORD_NOT_VALID: 'CONFIRM_PASSWORD_NOT_VALID',
  PASSWORD_NOT_MATCH: 'PASSWORD_NOT_MATCH',
  PASSWORD_MUST_BE_NEW: 'PASSWORD_MUST_BE_NEW',
  USER_ALREADY_EXIST: 'USER_ALREADY_EXIST',
  USER_EMAIL_ALREADY_EXIST: 'USER_EMAIL_ALREADY_EXIST',
  USER_NICKNAME_ALREADY_EXIST: 'USER_NICKNAME_ALREADY_EXIST',
  USER_PHONE_ALREADY_EXIST: 'USER_PHONE_ALREADY_EXIST',
  USER_NOT_ADMIN: 'USER_NOT_ADMIN',
  USER_NOT_SUPER_ADMIN: 'USER_NOT_SUPER_ADMIN',
  OAUTH_CANT_UNLINK_SINGLE_ACCOUNT_NO_PASSWORD:
    'OAUTH_CANT_UNLINK_SINGLE_ACCOUNT_NO_PASSWORD',
  OAUTH_CANCELED: 'OAUTH_CANCELED',
  CANT_GRANT_SUPER_ADMIN_WITHOUT_ADMIN: 'CANT_GRANT_SUPER_ADMIN_WITHOUT_ADMIN',
  USER_AVATAR_HAS_NOT_BEEN_CHANGED: 'USER_AVATAR_HAS_NOT_BEEN_CHANGED',
  USER_ACCOUNT_NOT_VERIFIED: 'USER_ACCOUNT_NOT_VERIFIED',
  USER_ACCOUNT_ALREADY_VERIFIED: 'USER_ALREADY_VERIFIED_ACCOUNT',
  /**
   * AUTH
   * */
  AUTH_FAILED: 'AUTH_FAILED',
  INVALID_TOKEN: 'INVALID_TOKEN',
  EMPTY_TOKEN: 'EMPTY_TOKEN',
  LOW_PERMISSIONS: 'LOW_PERMISSIONS',
  INNER_REQUEST_SIGN_NOT_VALID: 'INNER_REQUEST_SIGN_NOT_VALID',
  LOGIN_INFO_INVALID: 'LOGIN_INFO_INVALID',
  COUNTRY_INVALID: 'COUNTRY_INVALID',
  /**
   * SMS
   * */
  CANT_SEND_SMS_RIGHT_NOW: 'CANT_SEND_SMS_RIGHT_NOW',
  CANT_REGENERATE_SMS_RIGHT_NOW: 'CANT_REGENERATE_SMS_RIGHT_NOW',
  SMS_2FA_DISABLED: 'SMS_2FA_DISABLED',
  SMS_2FA_NO_PHONE: 'SMS_2FA_NO_PHONE',
  CODE_REQUIRED: 'CODE_REQUIRED',
  CODE_INVALID: 'CODE_INVALID',
  /**
   * 2FA Google
   * */
  GOOGLE_2FA_ENABLED: 'GOOGLE_2FA_ENABLED',
  WRONG_2FA: 'WRONG_2FA',
  /**
   * LOG
   * */
  LOG_NOT_FOUND: 'LOG_NOT_FOUND',
  /**
   * FILES
   * */
  FILES_REQUIRED: 'FILES_REQUIRED',
  FILE_REQUIRED: 'FILE_REQUIRED',
  EXCEEDED_FILE_LIMIT: 'EXCEEDED_FILE_LIMIT',
  EXCEEDED_FILES_LIMIT: 'EXCEEDED_FILES_LIMIT',
  UNACCEPTABLE_FILE: 'UNACCEPTABLE_FILE',
  /**
   * CONTACT
   * */
  CONTACT_SUBJECT_NOT_VALID: 'CONTACT_SUBJECT_NOT_VALID',
  CONTACT_BODY_NOT_VALID: 'CONTACT_BODY_NOT_VALID',
  /**
   * PROFILE
   * */
  NAME_REQUIRED: 'NAME_REQUIRED',
  DESC_REQUIRED: 'DESC_REQUIRED',
  ACTIVE_TO_REQUIRED: 'ACTIVE_TO_REQUIRED',

  /**
   * COMMUNITY PROFILES
   * */
  COMMUNITY_DESCRIPTION_INVALID: 'COMMUNITY_DESCRIPTION_INVALID',
  COMMUNITY_PROFILE_AT_LEAST_ONE_CONFIG_NEEDED:
    'COMMUNITY_PROFILE_AT_LEAST_ONE_CONFIG_NEEDED',
  COMMUNITY_PROFILE_NOT_FOUND: 'COMMUNITY_PROFILE_NOT_FOUND',
  COMMUNITY_PROFILE_REQUIRED: 'COMMUNITY_PROFILE_REQUIRED',
  COMMUNITY_PROFILE_ALREADY_EXISTS: 'COMMUNITY_PROFILE_ALREADY_EXISTS',
  COMMUNITY_PROFILE_ALREADY_EXISTS_PENDING_BY_CURRENT_USER:
    'COMMUNITY_PROFILE_ALREADY_EXISTS_PENDING_BY_CURRENT_USER',
  COMMUNITY_PROFILE_LIKE_ALREADY_EXISTS:
    'COMMUNITY_PROFILE_LIKE_ALREADY_EXISTS',
  COMMUNITY_PROFILE_LIKE_NOT_FOUND: 'COMMUNITY_PROFILE_LIKE_NOT_FOUND',
  /**
   * USER COMMUNITY DEVICES
   * */
  COMMUNITY_PROFILE_DEVICE_NOT_FOUND: 'COMMUNITY_PROFILE_DEVICE_NOT_FOUND',
  COMMUNITY_PROFILE_DEVICE_ALREADY_EXISTS:
    'COMMUNITY_PROFILE_DEVICE_ALREADY_EXISTS',

  /**
   * PROMOTIONS
   * */
  PROMOTION_NOT_FOUND: 'PROMOTION_NOT_FOUND',
  PROMOTION_FILE_NOT_FOUND: 'PROMOTION_FILE_NOT_FOUND',
  PROMOTION_ALREADY_EXISTS: 'PROMOTION_ALREADY_EXISTS',
  PROMOTION_FAILED_TO_PARSE_TARGET_FILES:
    'PROMOTION_FAILED_TO_PARSE_TARGET_FILES',
  PROMOTION_NOT_DELETABLE: 'PROMOTION_NOT_DELETABLE',
  PROMOTION_NOT_ARCHIVABLE: 'PROMOTION_NOT_ARCHIVABLE',
  PROMOTION_NOT_UPDATABLE: 'PROMOTION_NOT_UPDATABLE',
  PROMOTION_AFTER_EXPIRE_END_DATE_MUST_BE_GREATER_THAN_NOW:
    'PROMOTION_AFTER_EXPIRE_END_DATE_MUST_BE_GREATER_THAN_NOW',
  PROMOTION_NOT_AVAILABLE: 'PROMOTION_NOT_AVAILABLE',
  PROMOTION_USERS_NOT_EXIST: 'PROMOTION_USERS_NOT_EXIST',
  PROMOTION_MEDIA_REQUIRED: 'PROMOTION_MEDIA_REQUIRED',
  PROMOTION_FAILED_TO_LOAD: 'PROMOTION_FAILED_TO_LOAD',

  PROMOTION_VISUALIZATION_TIME_INVALID: 'PROMOTION_VISUALIZATION_TIME_INVALID',
  PROMOTION_START_DATE_INVALID: 'PROMOTION_START_DATE_INVALID',
  PROMOTION_END_DATE_INVALID: 'PROMOTION_END_DATE_INVALID',
  PROMOTION_DATES_INVALID: 'PROMOTION_DATES_INVALID',
  /**
   * PROMOTION TARGETS
   * */
  PROMOTION_TARGET_NOT_FOUND: 'PROMOTION_TARGET_NOT_FOUND',
  PROMOTION_TARGET_ALREADY_EXISTS: 'PROMOTION_TARGET_ALREADY_EXISTS',
}
