import React from 'react'
import CalendarMultiple from './CalendarMultiple'
import './CalendarBlock.scss'

const calendarBlockConfig = {}

const CalendarBlock = ({ dates, setDates }) => {
  return (
    <div className="calendar-block">
      <CalendarMultiple {...{ dates, setDates }} />
    </div>
  )
}

export default CalendarBlock
