import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { Field, Form, Formik } from 'formik'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

import CustomInput from '../../common/CustomInput'
import CustomInputPhone from '../../common/CustomInputPhone'
import { defaultSchema, inputList } from './config'
import { UsersError, UserSettingsError } from '../../messages/Error'
import AuthError from '../../Auth/Form/Error'
import Country from '../../Auth/Form/Country'
import DeleteAccBtn from '../../ProfilePage/PersonalInfo/DeleteAccBtn'

export * from './config'

const PersonalInfo = ({
  user,
  values,
  children,
  validationSchema = defaultSchema,
  isLoading,
  onSubmit,
}) => {
  const { t } = useTranslation()

  const inputListRender = inputList.map(
    ({ label, value, Icon, ...rest }, i) => (
      <div key={i} className="profile-info-input-wrapper p-col-12 p-md-6">
        <Field
          type="text"
          name={value}
          placeholder={label}
          component={CustomInput}
          {...rest}
        />
        {Icon}
      </div>
    ),
  )

  const initialValues = useMemo(() => {
    const keys = [
      'nickname',
      'firstName',
      'lastName',
      'country',
      'email',
      'phone',
      'country',
    ]
    if (user) keys.push('id')

    return keys.reduce(
      (acc, key) => ({ ...acc, [key]: user?.[key] || '' }),
      values || {},
    )
  }, [user, values])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ dirty, ...rest }) => (
        <Form>
          <div className="p-grid">
            <UsersError />
            <UserSettingsError />
            <AuthError />
            {inputListRender}
            <div className="p-col-12 p-md-6">
              <Field
                type="text"
                name="phone"
                labelText="phone_number"
                component={CustomInputPhone}
              />
            </div>
            <div className="p-col-12 p-md-6">
              <Country />
            </div>
            {typeof children === 'function' ? children(rest) : children}
            <div className="p-col-12 profile-info-submit-wrapper">
              <DeleteAccBtn />
              <Button
                type="submit"
                label={t('actions.update')}
                disabled={!(dirty && !isLoading)}
                className="common-button p-button p-component profile-info-submit-btn"
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const withData = inject((store) => ({
  isLoading: store.UsersStore.isLoading,
  geoInfo: store.AuthStore.geoInfo,
}))

export default withData(observer(PersonalInfo))
