import { action, observable } from 'mobx'

import api from '../../configs/api'
import Http, { buildQuery } from '../../helpers/http'
import { Notification, success } from '../../helpers/notifications'
import { injectIntoTemplate } from '../../utils/string'
import InfoDialogStore from '../dialogs/InfoDialog/mobx'
import { CommunityTab } from '../CommunityPage/tabs/config'
import { ErrorMsg } from '../../constants/errorMsg'
import ConfirmDialogStore from '../dialogs/CustomConfirmDialog/mobx'

class DeviceProfilesStore {
  resource = `${api.devices}/v1/users/current/device-profiles`

  itemUrlTemplate = '/device-profiles/device-profile/{id}'

  NOTIFICATION_CREATED = Notification.USER_DEVICE_PROFILE_CREATED
  NOTIFICATION_UPDATED = Notification.USER_DEVICE_PROFILE_UPDATED
  NOTIFICATION_DELETED = Notification.USER_DEVICE_PROFILE_DELETED

  @observable isLoading = false
  @observable list = []
  @observable count = 0
  @observable errorMsg = null
  @observable deleteProfileId = null
  @observable deleteProfileDeviceId = null
  @observable item = null

  @action
  publishProfile = async (
    { id, ...values },
    { resetForm },
    history,
    postPublish,
  ) => {
    if (!values.ids?.length) {
      this.errorMsg = ErrorMsg.COMMUNITY_PROFILE_AT_LEAST_ONE_CONFIG_NEEDED
      return
    }

    this.isLoading = true
    const { message = null, result } = await Http.post(
      `${this.resource}/${id}/community-profiles`,
      values,
    )
    this.isLoading = false
    this.errorMsg = message
    if (result) {
      InfoDialogStore.open('profile_published', () => {
        if (typeof postPublish === 'function') postPublish()

        const query = buildQuery({
          tab: CommunityTab.MyPublications,
          forceSearch: true,
        })
        history.push(`/community${query}`)
      })
    }
  }

  @action
  submitOne = async ({ id, ...values }, { resetForm }, history) => {
    this.isLoading = true

    let response, onSuccess

    if (id) {
      response = await Http.put(`${this.resource}/${id}`, values)
      onSuccess = async (result) => {
        resetForm()
        this.item = { ...this.item, ...result }
        success(this.NOTIFICATION_UPDATED)
      }
    } else {
      response = await Http.post(this.resource, values)
      onSuccess = (result) => {
        history.push(this.getItemUrl(result))
        success(this.NOTIFICATION_CREATED)
      }
    }

    const { message = null, result } = response
    this.errorMsg = message
    if (result) {
      onSuccess(result)
    }
    this.isLoading = false
  }

  @action
  getAll = async (fields = {}) => {
    this.isLoading = true
    fields = { ...fields, withNested: 'true' }
    const query = buildQuery(fields)
    const { message = null, result = [], count = 0 } = await Http.get(
      `${this.resource}${query}`,
    )
    this.errorMsg = message
    this.list = [...this.list, ...result]
    this.count = count
    this.isLoading = false
  }

  @action
  getOne = async (id) => {
    const profile = this.list.find((elm) => elm.id === id)
    if (profile?.devices) {
      this.item = profile
      return
    }

    this.isLoading = true
    const { message = null, result } = await Http.get(`${this.resource}/${id}`)
    this.errorMsg = message
    if (result) {
      if (profile) profile.devices = result?.devices
      this.item = result
    }
    this.isLoading = false
  }

  @action
  deleteOne = async (id) => {
    const isConfirmed = await ConfirmDialogStore.open({
      message: 'delete_device_profile_message',
      header: 'delete_device_profile',
    })
    if (!isConfirmed) return

    this.isLoading = true
    const response = await Http.delete(`${this.resource}/${id}`)
    if (response?.message) {
      this.errorMsg = response.message
    } else {
      this.list = this.list.filter((elem) => elem.id != id)
      success(this.NOTIFICATION_DELETED)
    }
    this.isLoading = false
  }

  @action
  deleteProfileDevice = async (profileDeviceId) => {
    const isConfirmed = await ConfirmDialogStore.open({
      message: 'delete_device_profile_item_message',
      header: 'delete_device_profile_item',
    })
    if (!isConfirmed) return

    this.isLoading = true
    const response = await Http.delete(
      `${this.resource}/${this.item.id}/devices/${profileDeviceId}`,
    )
    if (response?.message) {
      this.errorMsg = response.message
    } else {
      this.item.devices = this.item.devices.filter(
        (elem) => elem.id != profileDeviceId,
      )
      success(Notification.USER_DEVICE_PROFILE_CONFIGURATION_DELETED)
    }
    this.isLoading = false
  }

  getItemUrl = (item) => {
    return injectIntoTemplate(this.itemUrlTemplate, item)
  }

  @action
  cleanUp = () => {
    this.isLoading = false
    this.list = []
    this.count = 0
    this.errorMsg = null
    this.item = null
  }
}

export default new DeviceProfilesStore()
