import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { inject } from 'mobx-react'

import '../assets/styles/index.scss'
import PrivateRoute from '../PrivateRoute'
import NotAuthRoute from '../NotAuthRoute'
import LoginPage from '../components/LoginPage'
import RegistrationPage from '../components/RegistrationPage'
import ForgotPasswordPage from '../components/ForgotPasswordPage'
import ChangePasswordPage from '../components/ChangePasswordPage'
import ContactPage from '../components/ContactPage'
import ContactByTokenPage from '../components/ContactByTokenPage'
import ProfilePage from '../components/ProfilePage'
import CallbackPage from '../components/CallbackPage'
import Layout from '../components/hoc/Layout'
import CommunityPage from '../components/CommunityPage'
import DevicesCatalog from '../components/DevicesCatalog'
import DevicePage from '../components/DevicePage'
import DeviceProfilesPage from '../components/DeviceProfilesPage'
import DeviceProfilePage from '../components/DeviceProfilePage'
import CommunityProfilePage from '../components/CommunityProfilePage'
import RulesPage from '../components/RulesPage'
import PromotionsPage from '../components/PromotionsPage'
import DesktopVersion from '../components/DesktopVersion'
import RegistrationByTokenPage from '../components/RegistrationByTokenPage'
import DesktopRedirectPage from '../components/DesktopRedirectPage'
import DocumentationPage from '../components/DocumentationPage'
import StatisticsPage from '../components/StatisticsPage'

const App = ({ init }) => {
  useEffect(() => {
    init()
  }, [])

  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route exact path="/callback" component={CallbackPage} />
          <Route exact path="/desktop-version" component={DesktopVersion} />
          <Route
            exact
            path="/desktop-redirect"
            component={DesktopRedirectPage}
          />
          <NotAuthRoute exact path="/" component={LoginPage} />
          <NotAuthRoute
            exact
            path="/registration"
            component={RegistrationPage}
          />
          <NotAuthRoute
            exact
            path="/registration/:token"
            component={RegistrationByTokenPage}
          />
          <NotAuthRoute
            exact
            path="/forgot-password"
            component={ForgotPasswordPage}
          />
          <NotAuthRoute
            exact
            path="/change-password/:token"
            component={ChangePasswordPage}
          />
          <NotAuthRoute
            exact
            path="/contact/:token"
            component={ContactByTokenPage}
          />
          <PrivateRoute
            exact
            path="/profile"
            component={ProfilePage}
            title="Profile"
          />
          <PrivateRoute
            exact
            path="/statistic"
            component={StatisticsPage}
            title="Statistic"
          />
          <PrivateRoute
            exact
            path="/rules"
            component={RulesPage}
            title="Rules"
          />
          <PrivateRoute
            exact
            path="/device-profiles"
            component={DeviceProfilesPage}
            title="Devices profile"
          />
          <PrivateRoute
            exact
            path="/device-profiles/device-profile/:id"
            component={DeviceProfilePage}
            title="Devices profile"
          />
          <PrivateRoute
            exact
            path="/community"
            component={CommunityPage}
            title="Community"
          />
          <PrivateRoute
            exact
            path="/information"
            component={DocumentationPage}
            title="Information"
          />
          <PrivateRoute
            exact
            path="/promotions"
            component={PromotionsPage}
            title="Promotions"
          />
          <PrivateRoute
            exact
            path="/community/profile/:id"
            component={CommunityProfilePage}
            title="Community profile"
          />
          <PrivateRoute
            exact
            path="/catalog"
            component={DevicesCatalog}
            title="Catalog"
          />
          <PrivateRoute
            exact
            path="/catalog/device/:id"
            component={DevicePage}
            title="device"
          />
          <PrivateRoute
            exact
            path="/contact"
            component={ContactPage}
            title="Contact"
          />
        </Switch>
      </Layout>
    </BrowserRouter>
  )
}

const withData = inject((stores) => ({
  init: stores.AuthStore.init,
}))

export default withData(App)
