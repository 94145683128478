import React from 'react'
import './CommunityItem.scss'
import withEffect from '../../../assets/images/commutity/lightning-effect/with.png'
import without from '../../../assets/images/commutity/lightning-effect/without.png'
import { MdFileDownload } from 'react-icons/md'
import ToggleLike from '../../devices/ToggleLike'
import DevicesBlock from './DevicesBlock'
import moment from 'moment'
import { GoCalendar } from 'react-icons/go'
import { useCommunityProfilesContext } from '../../../context'
import CommunityItemHeader from './Header'
import MyPublicationsActionBody from '../../CommunityPage/Profiles/View/MyPublicationsActionBody'

const CommunityItem = ({ item }) => {
  const {
    User,
    desc,
    id,
    isLightingEffectsOnly,
    name,
    CommunityProfileDownloads,
    CommunityProfileLikes,
    devices,
    activeTo,
    createdAt,
    status,
  } = item
  const { columnList } = useCommunityProfilesContext()
  const myPublicationCondition = columnList.includes('status')

  const activeDate = moment(activeTo).format('DD.MM.YYYY')
  const createdDate = moment(createdAt).format('DD.MM.YYYY')

  const effectImg = isLightingEffectsOnly ? withEffect : without
  return (
    <div className="common-community-item-wrapper">
      <CommunityItemHeader
        User={User}
        status={status}
        myPublicationCondition={myPublicationCondition}
        activeDate={activeDate}
        createdDate={createdDate}
      />
      <div className="common-community-item-body">
        <img className="light-effect-icon" src={effectImg} />
        <DevicesBlock devices={devices} />
      </div>
      <div className="common-community-item-content">
        <span className="community-item-name">{name}</span>
        <span className="community-item-desc">{desc}</span>
      </div>
      <div className="common-community-item-footer">
        <span className="community-item-download">
          <MdFileDownload />
          <span className="quantity">{CommunityProfileDownloads.length}</span>
        </span>
        <span className="community-item-like">
          <ToggleLike id={id} />
          <span className="quantity">{CommunityProfileLikes.length}</span>
        </span>
        <span className="community-item-active-date">
          <GoCalendar />
          <span className="active-date">{activeDate}</span>
        </span>
        <MyPublicationsActionBody id={id} name={name} status={status} />
      </div>
    </div>
  )
}

export default CommunityItem
