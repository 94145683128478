import React, { useEffect } from 'react'
import BannerWrapper from './BannerWrapper'
import useToggler from '../../../hooks/useToggler'

const BannerVerifiedInfo = ({ isVerified }) => {
  useEffect(() => {
    setTimeout(() => setBannerStatus(isVerified), 1000)
  }, [])

  const [isShowBanner, setBannerStatus] = useToggler(true)
  return (
    <div
      className={`promotion-banner-component ${!isShowBanner ? 'active' : ''}`}
    >
      <BannerWrapper
        isShowBanner={isShowBanner}
        setBannerStatus={setBannerStatus}
      />
    </div>
  )
}

export default BannerVerifiedInfo
