import React from 'react'
import './AwardsPage.scss'
import AwardsContent from './AwardsContent'

const AwardsPage = () => {
  return (
    <div className="awards-page-wrapper">
      <h1>Awards</h1>
      <AwardsContent />
    </div>
  )
}

export default AwardsPage
