import React from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'

const options = {
  chart: {
    type: 'pie',
    options3d: {
      enabled: true,
      alpha: 45,
      beta: 0,
    },
    width: '300',
  },
  title: {
    text: 'Devices',
    style: {
      color: `#76859c`,
    },
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    style: {
      color: `#76859c`,
    },
  },
  accessibility: {
    point: {
      valueSuffix: '%',
    },
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      depth: 35,
      dataLabels: {
        enabled: true,
        format: '{point.name}',
      },
    },
  },
  series: [
    {
      name: 'Brands',
      colorByPoint: true,
      data: [
        {
          name: 'GM-20',
          y: 41.41,
          sliced: true,
          selected: true,
          color: '#92E66A',
        },
        {
          name: 'GK-8',
          y: 11.84,
          color: '#073373',
        },
        {
          name: 'GM-12',
          y: 34.67,
          color: '#730738',
        },
        {
          name: 'DF-5',
          y: 4.18,
          color: '#d5c519',
        },
      ],
      showInLegend: true,
    },
  ],
}

const DevicePaiChart = () => (
  <HighchartsReact highcharts={Highcharts} options={options} />
)

export default DevicePaiChart
