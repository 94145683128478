import React, { useCallback } from 'react'
import ApplicationAvatar from './ApplicationAvatar'

const ApplicationItem = ({ item, activeApp, setActiveApp }) => {
  const activeClass =
    item.name === activeApp ? 'active app-nav-item' : 'app-nav-item'
  const handleChange = useCallback(() => {
    setActiveApp(item.name)
  }, [item, activeApp])
  return (
    <div className={activeClass} onClick={handleChange}>
      <ApplicationAvatar item={item} />
      <span className="app-name">{item.name}</span>
    </div>
  )
}

export default ApplicationItem
