import React, { useState } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'

const options = {
  chart: {
    type: 'line',
    width: '1300',
  },
  title: {
    text: 'Keystroke clicks',
    style: {
      color: `#76859c`,
    },
  },
  subtitle: {
    text: '',
  },
  xAxis: {
    categories: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
  },
  yAxis: {
    title: {
      text: 'Keystroke ',
      style: {
        color: `#76859c`,
      },
    },
    style: {
      color: `#76859c`,
    },
  },
  plotOptions: {
    line: {
      dataLabels: {
        enabled: true,
      },
      enableMouseTracking: false,
    },
  },

  series: [
    {
      data: [3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0, 16.6, 14.2, 10.3, 6.6, 4.8],
      color: `#26ff2a`,
    },
  ],
}
const KeyboardChart = () => {
  return (
    <div className="bar-chart">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}
export default KeyboardChart
