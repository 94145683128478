import React from 'react'
import UserInfo from '../../Profiles/View/UserInfo'
import Condition, { Else, If } from '../../../hoc/Condition'
import StatusComponent from './StatusComponent'
import ActiveDates from './ActiveDates'

const CommunityItemHeader = ({
  User,
  status,
  myPublicationCondition,
  activeDate,
  createdDate,
}) => {
  const myPublicationHeader = (
    <>
      <StatusComponent status={status} />
      <ActiveDates createdDate={createdDate} activeDate={activeDate} />
    </>
  )

  return (
    <div className="common-community-item-header">
      <Condition condition={myPublicationCondition}>
        <If>{myPublicationHeader}</If>
        <Else>
          <UserInfo User={User} className="community-avatar-item" />
        </Else>
      </Condition>
    </div>
  )
}

export default CommunityItemHeader
