import React from 'react'
import { inject, observer } from 'mobx-react'
import ImgPreview from '../ImgPreview'
import defaultAvatar from '../../assets/images/avatar.png'

const UserAvatar = ({ user, resource, className }) => {
  const { avatar, id } = user || {}

  const src = avatar && `${resource}/${id}/avatars/${avatar}`

  return (
    <ImgPreview
      className={`user-avatar-profile ${className}`}
      src={src || defaultAvatar}
    />
  )
}

const withData = inject((stores) => ({
  resource: stores.UsersStore.resource,
}))

export default withData(observer(UserAvatar))
