import React from 'react'

import './PageButtons.scss'

const PageActionsWrapper = ({ children }) => (
  <div className="page-actions-wrapper">
    {React.Children.map(children, (child) =>
      React.cloneElement(child, {
        className: 'btn secondary-btn page-action',
      }),
    )}
  </div>
)

export default PageActionsWrapper
