import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { Form, Formik } from 'formik'
import { Button } from 'primereact/button'

import Error from './Error'
import SocialButtons from './SocialButtons'
import { pipe } from '../../../utils/function'
import TwoFaSwitch from './TwoFaSwitch'
import TwoFaConfirm from './TwoFaConfirm'
import { useTranslation } from 'react-i18next'

const SecuritySettings = ({ userInfo, isLoading, updateSettings, title }) => {
  const { t } = useTranslation()
  const initialValues = useMemo(
    () => ({
      id: userInfo.id,
      enabledSms: false,
      enabled2fa: userInfo.enabled2fa || false,
      twoFactorAuthenticationCode: [],
    }),
    [userInfo],
  )

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={updateSettings}
    >
      {({ dirty, values, setFieldValue }) => (
        <Form>
          <div className="p-grid">
            <Error />
            <TwoFaSwitch
              setFieldValue={setFieldValue}
              enabledSms={values.enabledSms}
              is2faAlreadyEnabled={userInfo.enabled2fa}
            />
            <TwoFaConfirm />
            <SocialButtons className="profile-delete-oauth-btn" />
            <div className="p-col-12 profile-info-submit-wrapper">
              <Button
                type="submit"
                label={t('actions.update')}
                disabled={!(dirty && !isLoading)}
                className="common-button p-button p-component profile-info-submit-btn"
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const withData = inject((stores) => ({
  isLoading: stores.UsersStore.isLoading,
  userInfo: stores.AuthStore.user,
  updateSettings: stores.UsersStore.updateSettings,
  generate2FA: stores.UsersStore.generate2FA,
}))

export default pipe(observer, withData)(SecuritySettings)
