import React from 'react'
import { IoIosArrowDown } from 'react-icons/io'

const SettingsButton = ({ toggleMenu }) => {
  return (
    <button onClick={toggleMenu} className="settings-btn">
      <IoIosArrowDown />
    </button>
  )
}

export default SettingsButton
