import React, { useEffect } from 'react'
import useSearchParams from '../../hooks/useSearchParams'
import { buildQuery } from '../../helpers/http'
import { mapPlatformToTabName, tabs } from '../DesktopVersionPage/config'
import { useHistory } from 'react-router-dom'

import { inject, observer } from 'mobx-react'
import Loadable from '../hoc/Loadable'
import PageTabs from '../PageTabs'
import './DeskTopVersion.scss'

const DesktopVersion = ({ getAll, cleanUp, isLoading }) => {
  const { tab, ...searchParams } = useSearchParams()

  const history = useHistory()
  const { pathname } = history.location

  useEffect(() => {
    getAll()

    if (!tab) {
      const query = buildQuery({
        tab: mapPlatformToTabName(),
        ...searchParams,
      })
      history.push(`${pathname}${query}`)
    }

    return cleanUp
  }, [])

  return (
    <div className="page-content-wrapper desktop-page-wrapper">
      <Loadable loading={isLoading}>
        <PageTabs
          section="desktop_version"
          className="desktop-version-block"
          tabs={tabs}
        />
      </Loadable>
    </div>
  )
}

const withData = inject((stores) => ({
  getAll: stores.DesktopApplicationStore.getAll,
  cleanUp: stores.DesktopApplicationStore.cleanUp,
  isLoading: stores.DesktopApplicationStore.isLoading,
}))

export default withData(observer(DesktopVersion))
