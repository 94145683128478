import React from 'react'
import { useTranslation } from 'react-i18next'

import { bindClassName } from '../../utils/react'

const useLabel = ({
  labelKey,
  isRequired,
  field = {},
  htmlFor = field?.name,
  placeholder,
  className,
} = {}) => {
  const { t } = useTranslation()

  const translated = labelKey && t(`labels.${labelKey}`, labelKey)
  const labelText =
    translated && translated + (isRequired || field.isRequired ? '*' : '')

  const _placeholder = placeholder && t(`labels.${placeholder}`)

  const label = (
    <label
      {...bindClassName([!labelText, 'p-d-none'], [className])}
      htmlFor={htmlFor}
    >
      {labelText}
    </label>
  )

  return {
    label,
    labelText,
    translated,
    _placeholder,
  }
}

export default useLabel
