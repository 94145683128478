import React from 'react'
import { inject, observer } from 'mobx-react'
import { Formik, Form, Field } from 'formik'

import { NotificationType } from '../../../constants/system-notifications'
import useInitialValues from '../../../hooks/form/useInitialValues'
import Loadable from '../../hoc/Loadable'
import CustomSwitch from '../../common/CustomSwitch'
import { UsersNotificationSettingsError } from '../../messages/Error'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

const types = Object.values(NotificationType)
const defaultValues = types.reduce((acc, key) => ({ ...acc, [key]: '' }), {})

const className = 'p-col-12'

const NotificationSettings = ({
  updateNotificationSettings,
  currentSettings,
  isLoading,
}) => {
  const { t } = useTranslation()
  const initialValues = useInitialValues(defaultValues, currentSettings)

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={updateNotificationSettings}
    >
      {({ dirty }) => (
        <Form className="mt-2">
          <Loadable loading={isLoading}>
            <div className="p-grid">
              <UsersNotificationSettingsError />
              {types.map((type) => (
                <div className={className} key={type}>
                  <Field
                    name={type}
                    labelText={type}
                    component={CustomSwitch}
                  />
                </div>
              ))}
              <div className="p-col-12 profile-info-submit-wrapper">
                <Button
                  type="submit"
                  label={t('actions.update')}
                  disabled={!(dirty && !isLoading)}
                  className="common-button p-button p-component profile-info-submit-btn"
                />
              </div>
            </div>
          </Loadable>
        </Form>
      )}
    </Formik>
  )
}

const withData = inject((stores) => ({
  updateNotificationSettings: stores.UsersStore.updateNotificationSettings,
  isLoading: stores.UsersStore.isLoading,
  currentSettings: stores.AuthStore.user?.UserNotificationSettings,
}))

export default withData(observer(NotificationSettings))
