import React from 'react'

import { PromotionMediaType } from '../../../constants/promotions'
import Video from './media/Video'
import DefaultMedia from './media/DefaultMedia'
import Image from './media/Image'

const mapping = {
  [PromotionMediaType.None]: DefaultMedia,
  [PromotionMediaType.Video]: Video,
  [PromotionMediaType.Image]: Image,
}

const MediaItem = ({ mediaType, mediaLink }) => {
  const Component = mapping[mediaType]
  return Component && <Component mediaLink={mediaLink} />
}

export default MediaItem
