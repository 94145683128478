export const Notification = {
  ERROR_OCCURRED: 'ERROR_OCCURRED',

  VERIFY_ACCOUNT_EMAIL_SENT: 'VERIFY_ACCOUNT_EMAIL_SENT',
  FAILED_TO_CROP_IMAGE: 'FAILED_TO_CROP_IMAGE',
  PASSWORD_CHANGED: 'PASSWORD_CHANGED',
  NEW_AVATAR_UPLOADED: 'NEW_AVATAR_UPLOADED',
  AVATAR_REMOVED: 'AVATAR_REMOVED',
  RESET_PASSWORD_LINK_SENT: 'RESET_PASSWORD_LINK_SENT',
  INFO_UPDATED: 'INFO_UPDATED',
  SETTINGS_UPDATED: 'SETTINGS_UPDATED',
  NOTIFICATION_SETTINGS_UPDATED: 'NOTIFICATION_SETTINGS_UPDATED',
  REQUEST_SUBMITTED: 'REQUEST_SUBMITTED',
  YOUR_ACCOUNT_HAS_BEEN_DELETED: 'YOUR_ACCOUNT_HAS_BEEN_DELETED',

  USER_REMOVED: 'USER_REMOVED',
  USER_RESTORED: 'USER_RESTORED',
  USER_CREATED: 'USER_CREATED',

  USER_DEVICE_PROFILE_CREATED: 'USER_DEVICE_PROFILE_CREATED',
  USER_DEVICE_PROFILE_UPDATED: 'USER_DEVICE_PROFILE_UPDATED',
  USER_DEVICE_PROFILE_DELETED: 'USER_DEVICE_PROFILE_DELETED',
  USER_DEVICE_PROFILE_CONFIGURATION_DELETED:
    'USER_DEVICE_PROFILE_CONFIGURATION_DELETED',

  COMMUNITY_PROFILE_DELETED: 'COMMUNITY_PROFILE_DELETED',
  COMMUNITY_PROFILE_LIKED: 'COMMUNITY_PROFILE_LIKED',
  COMMUNITY_PROFILE_UN_LIKED: 'COMMUNITY_PROFILE_UN_LIKED',
  COMMUNITY_PROFILE_DOWNLOADED: 'COMMUNITY_PROFILE_DOWNLOADED',

  NEW_NOTIFICATION: 'NEW_NOTIFICATION',
  NOTIFICATION_DELETED: 'NOTIFICATION_DELETED',
  ALL_NOTIFICATIONS_DELETED: 'ALL_NOTIFICATIONS_DELETED',
}
