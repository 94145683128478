import React from 'react'
import { Checkbox } from 'primereact/checkbox'

import useLabel from '../../../hooks/form/useLabel'
import { useFormError } from '../../../hooks/form/useError'

const CustomCheckbox = ({
  form,
  placeholder,
  labelText: labelKey = placeholder,
  field,
  onChange,
  disabled,
}) => {
  const { error } = useFormError(form, field)
  const { labelText } = useLabel({ labelKey, field })

  return (
    <>
      <div className="my-2 p-flex">
        <label className="p-checkbox-label">
          <Checkbox
            name={field.name}
            value={field.value}
            checked={field.value}
            disabled={disabled || field.disabled}
            onChange={onChange || field.onChange}
            className="mr-2"
          />
          {labelText}
        </label>
      </div>
      {error}
    </>
  )
}

export default CustomCheckbox
