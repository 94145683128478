import React from 'react'
import './ApplicationNav.scss'
import { inject, observer } from 'mobx-react'
import ApplicationItem from './ApplicationItem'
import logo from '../../../../../assets/images/logo_animation_2eyes.gif'

const allApp = {
  name: 'all',
  id: new Date(),
  image: logo,
}

const ApplicationNav = ({ items, activeApp, setActiveApp }) => {
  if (!items?.result || !items?.result.length) return null
  const list = [allApp, ...items?.result].map((item) => (
    <ApplicationItem key={item.id} {...{ item, activeApp, setActiveApp }} />
  ))

  return <div className="application-nav-wrapper">{list}</div>
}

const withData = inject((stores) => ({
  items: stores.StatisticsStore.applicationsList,
}))

export default withData(observer(ApplicationNav))
