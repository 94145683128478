import React from 'react'
import { inject, observer } from 'mobx-react'

import UserPersonalInfo from '../../user/PersonalInfo'
import VerificationGroup from './VerificationGroup'
import ProfilePhoto from '../ProfilePhoto'

const PersonalInfo = ({ user, updateUserInfo }) => {
  const userName = user?.firstName || user?.nickname || 'GAMER'
  return (
    <>
      <VerificationGroup />
      <div className="profile-content">
        <ProfilePhoto />
        <div className="user-name">{userName}</div>
        <UserPersonalInfo user={user} onSubmit={updateUserInfo} />
      </div>
    </>
  )
}

const withData = inject(({ AuthStore, UsersStore }) => ({
  user: AuthStore.user,
  updateUserInfo: UsersStore.updateUserInfo,
}))

export default withData(observer(PersonalInfo))
