import React from 'react'
import { inject, observer } from 'mobx-react'

const Instructions = ({ language }) => {
  const docPath = `/assets/i18n/documents/${language}/documents/manual.pdf`
  return (
    <iframe
      src={docPath}
      frameBorder="0"
      scrolling="auto"
      height="100%"
      width="100%"
    />
  )
}
const withData = inject((stores) => ({
  language: stores.LanguageStore.language,
}))

export default withData(observer(Instructions))
