import React, { useCallback, useMemo } from 'react'
import useKeyboard, { KEYBOARD } from '../../../../hooks/useKeyboard'
import Header from './Header'

const ModalStatisticContent = ({
  activeDate,
  setActiveDate,
  deviceName,
  devicesFilter,
}) => {
  const closeModal = useCallback(() => setActiveDate(false), [activeDate])

  const keyboardConfig = useMemo(
    () => [
      {
        keys: KEYBOARD.ESCAPE,
        handler: closeModal,
      },
    ],
    [closeModal],
  )

  useKeyboard(keyboardConfig)
  return (
    <>
      <Header closeModal={closeModal} activeDate={activeDate} />
      <span className="daily-modal-device-name">{`${devicesFilter} ${deviceName}`}</span>
      <div className="daily-statistics-full-total">
        <span>{`activity ratio - ${activeDate?.['activity ratio']}`}</span>
        <span>{`distance- ${activeDate?.['distance']}`}</span>
      </div>
      <div className="daily-statistics-full-total">
        {Object.keys(activeDate.data).map((item, index) => {
          return (
            <span
              key={index}
              className="daily-statistics-full-total-item"
            >{`${item} - ${activeDate.data?.[item]}`}</span>
          )
        })}
      </div>
    </>
  )
}

export default ModalStatisticContent
