import * as yup from 'yup'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { ErrorMsg } from './errorMsg'
import { unhumanizeSize } from '../utils/size'

export const MIN_PASSWORD_LENGTH = 8
export const MAX_PASSWORD_LENGTH = 50
export const PASSWORD_REGEXP = /^(?=[^\s]*\d)[^\s]{8,50}$/

export const MIN_USER_NAME_LENGTH = 2
export const MAX_USER_NAME_LENGTH = 32
export const USER_NAME_REGEXP = /^[a-zA-Z][a-zA-Z- ]*[a-zA-Z]$/

export const loginFieldsExcludes = ['email', 'phone']

export const loginFieldsShape = {
  email: yup.string().trim().required(ErrorMsg.EMAIL_REQUIRED),
  phone: yup
    .string()
    .trim()
    .test(
      'valid-phone-number',
      ErrorMsg.PHONE_NOT_VALID,
      (value) => !value || !!parsePhoneNumberFromString(`+${value}`)?.isValid(),
    ),
}

export const nicknameShape = {
  nickname: yup
    .string()
    .trim()
    .test(
      'valid-nickname',
      ErrorMsg.NICKNAME_NOT_VALID,
      (value) => !value || (!/@/.test(value) && !/^\d+$/.test(value)),
    ),
}

export const USER_AVATAR_LIMIT = '5mb'
export const USER_AVATAR_LIMIT_BYTES = unhumanizeSize(USER_AVATAR_LIMIT)
