import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom'
import DevicesBlock from '../DevicesBlock'
import ActionBody from '../../../../CommunityPage/ShareGroup/ShareProfileModal/ModalContent/ActionBody'
import useToggler from '../../../../../hooks/useToggler'
import { deviceCategory } from '../../../../../configs/devices'

const DeviceItem = ({ item }) => {
  const { t } = useTranslation()
  const { name, devices, id } = item
  const [isOpen, toggleOpen] = useToggler()
  return (
    <div className="common-devices-item-wrapper">
      <div className="common-devices-item-body">
        <DevicesBlock devices={devices} />
      </div>
      <div className="common-devices-item-content">
        <span className="devices-item-name">{name}</span>
      </div>
      <div className="common-devices-item-footer">
        <span className="devices-item-download" />
        <span className="devices-item-like" />
        <ActionBody toggleOpen={toggleOpen} id={id} />
      </div>
    </div>
  )
}

export default DeviceItem
