import React from 'react'
import { inject, observer } from 'mobx-react'
import './PromotionActionBanner.scss'
import BannerWrapper from './BannerWrapper'

const PromotionActionBanner = ({ activeBannerId, notSeenPromotionsCount }) => {
  if (!notSeenPromotionsCount) return null

  return (
    <div
      className={`promotion-banner-component ${activeBannerId ? 'active' : ''}`}
    >
      <BannerWrapper />
    </div>
  )
}
const withData = inject((stores) => ({
  activeBannerId: stores.NotificationsStore.activeBannerId,
  notSeenPromotionsCount: stores.NotificationsStore.notSeenPromotionsCount,
}))

export default withData(observer(PromotionActionBanner))
