import React from 'react'
import { inject, observer } from 'mobx-react'

import DeviceItem from './DeviceItem'
import Loadable from '../../hoc/Loadable'
import Condition from '../../hoc/Condition'
import If from '../../hoc/Condition/If'
import RenderIf from '../../hoc/RenderIf'
import Else from '../../hoc/Condition/Else'
import { AiOutlineReload } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { GrDocumentText } from 'react-icons/gr'

const View = ({ onPage, list, isLoading, count }) => {
  const { t } = useTranslation()
  const deviseList = list.map((item) => {
    return <DeviceItem item={item} key={item.id} />
  })
  const loadButton = (
    <button
      className={`p-button p-component secondary-btn load-promotion-btn`}
      onClick={onPage}
    >
      <AiOutlineReload />
      {t('labels.show_more')}
    </button>
  )

  return (
    <div className="devices-scroll-content">
      <Loadable loading={isLoading}>
        <Condition condition={list.length}>
          <If>
            <div className="devices-content">{deviseList}</div>
            <RenderIf condition={list.length < count}>{loadButton}</RenderIf>
          </If>
          <Else>
            <div className="no-profile-msg-block">
              <div className="no-profile-icon">
                <GrDocumentText />
              </div>
              <span className="no-profile-msg">
                {t('labels.no_profiles_found')}
              </span>
            </div>
          </Else>
        </Condition>
      </Loadable>
    </div>
  )
}

const withData = inject((stores) => ({
  list: stores.DevicesStore.list,
  count: stores.DevicesStore.count,
  isLoading: stores.DevicesStore.isLoading,
  categoriesList: stores.DevicesStore.categoriesList,
}))

export default withData(observer(View))
