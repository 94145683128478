import React from 'react'
import './ComparisonBar.scss'

const ComparisonBar = () => {
  return (
    <div className="common-bar-wrapper">
      <div className="quantity-call-info">
        <div className="quantity-call-info__elem ">
          <div className="quantity-call-info__elem-value ">
            <span className="primary-meaning">894</span>
            <span className="percentage-change down">- 13.2% </span>
            <span className="quantity-call-info__label">912</span>
            <div className="trend-change__value-down">
              <svg
                className="down"
                fill="none"
                height="29"
                viewBox="0 0 24 29"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.9753 13.3998L21.6303 23.3084L11.7217 25.9634M21.5857 23.3421L9.3 16.249L12.1894 11.3336L2.39045 5.67672"
                  stroke="#FD8181"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                />
              </svg>
            </div>
          </div>
          <p className="quantity-call-info__label miss-call">
            Total activity time (h)
          </p>
        </div>
        <div className="quantity-call-info__elem">
          <div className="quantity-call-info__elem-value ">
            <span className="primary-meaning">25</span>
            <span className="percentage-change">+ 45.2%</span>
            <span className="quantity-call-info__label">46</span>
            <div className="trend-change__value-up">
              <svg
                className="up"
                fill="none"
                height="19"
                viewBox="0 0 26 19"
                width="26"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.7497 2.49219L24.0079 2.49219V12.7504M24.0289 2.544L13.9976 12.5752L9.99761 8.51211L1.99731 16.513"
                  stroke="#6DD453"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                />
              </svg>
            </div>
          </div>
          <p className="quantity-call-info__label all-call">Activity ratio</p>
        </div>
        <div className="quantity-call-info__elem">
          <div className="quantity-call-info__elem-value ">
            <span className="primary-meaning">321</span>
            <span className="percentage-change">+ 15.4%</span>
            <span className="quantity-call-info__label">564</span>
            <div className="trend-change__value-up">
              <svg
                className="up"
                fill="none"
                height="19"
                viewBox="0 0 26 19"
                width="26"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.7497 2.49219L24.0079 2.49219V12.7504M24.0289 2.544L13.9976 12.5752L9.99761 8.51211L1.99731 16.513"
                  stroke="#6DD453"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                />
              </svg>
            </div>
          </div>
          <p className="quantity-call-info__label all-call">Average speed</p>
        </div>
      </div>
    </div>
  )
}

export default ComparisonBar
