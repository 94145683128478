import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

import WarningMessage from '../../../messages/WarningMessage'
import RenderIf from '../../../hoc/RenderIf'
import { Message } from '../../../../constants/message'

const VerificationGroup = ({ isVerified, sendEmailVerificationLink }) => {
  const { t } = useTranslation()

  const sendLink = useCallback(
    () => !isVerified && sendEmailVerificationLink(),
    [isVerified, sendEmailVerificationLink],
  )

  return (
    <RenderIf condition={!isVerified}>
      <div className="p-grid p-fluid">
        <div className="p-col-12 p-md-8">
          <WarningMessage msg={Message.YOUR_EMAIL_NOT_VERIFIED} noWrapper />
        </div>
        <div className="p-col-12 p-md-4">
          <Button
            label={t('actions.resend_verification_link')}
            type="button"
            className="p-button-secondary w-100"
            onClick={sendLink}
          />
        </div>
      </div>
    </RenderIf>
  )
}

const withData = inject((stores) => ({
  isVerified: stores.AuthStore.user?.isVerified,
  sendEmailVerificationLink: stores.AuthStore.sendEmailVerificationLink,
}))

export default withData(observer(VerificationGroup))
