import React from 'react'
import { MdClear } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'

const ModalHeader = ({ toggleModal, label, language }) => {
  const { t } = useTranslation()
  const docPath = `/assets/i18n/documents/${language}/policy/${label}.pdf`
  return (
    <div className="access-modal-header">
      <div className="access-modal-title p-d-flex p-dir-row">
        <span>{t(`confidentiality.title.${label}`)}</span>
      </div>
      <button className="download-btn">
        <a href={docPath} download>
          {t('labels.download')}
        </a>
      </button>

      <button
        className="p-button-icon-only button-close-dialog"
        onClick={toggleModal}
      >
        <MdClear />
      </button>
    </div>
  )
}
const withData = inject((stores) => ({
  language: stores.LanguageStore.language,
}))

export default withData(observer(ModalHeader))
