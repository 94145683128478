import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

const useRedirect = (toOrCb) => {
  const history = useHistory()

  return useCallback(
    (...args) => {
      const [e] = args

      if (e && e.stopPropagation) {
        e.stopPropagation()
      }

      let to = toOrCb

      if (typeof toOrCb === 'function') {
        to = toOrCb(...args)
      }

      history.push(to)
    },
    [history, toOrCb],
  )
}

export default useRedirect
