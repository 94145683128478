import { useCallback, useEffect, useState } from 'react'
import useToggler from '../../../hooks/useToggler'

export default ({ onAgree } = {}) => {
  const [isPrivacyPolicy, toggleAccess, , , setPrivacyPolicy] = useToggler()
  const [isLicence, toggleLicence, , , setLicence] = useToggler()
  const [isCookie, toggleAccept, , , setCookie] = useToggler()
  const [isAgreement, setAgreement] = useState(false)
  const [isMsg, setMsg] = useState(false)
  const [isAgreed, setIsAgreed] = useState(false)
  const [isModal, setModal] = useState(false)
  const [policyAction, setPolicyAction] = useState(false)

  const agreementCondition = isPrivacyPolicy && isLicence && isCookie

  const checkAgreeStatus = useCallback(() => {
    setAgreement(agreementCondition)
  }, [agreementCondition])

  useEffect(() => {
    if (agreementCondition && isAgreement) setMsg(false)

    checkAgreeStatus()
  }, [agreementCondition, isAgreement])

  const toggleModal = useCallback(
    (policy = false) => {
      setModal(!isModal)
      setPolicyAction(policy)
    },
    [isModal],
  )

  const toggleAgreement = useCallback(() => {
    setAgreement(!isAgreement)
    setLicence(!isAgreement)
    setPrivacyPolicy(!isAgreement)
    setCookie(!isAgreement)
  }, [isAgreement])

  const agree = useCallback(() => {
    if (!isPrivacyPolicy || !isLicence || !isCookie || !isAgreement) {
      return setMsg(true)
    }

    setIsAgreed(true)
    if (onAgree) onAgree()
  }, [isCookie, isPrivacyPolicy, isLicence, isCookie, isAgreement])

  return {
    isMsg,
    policyAction,
    toggleModal,
    toggleAccess,
    toggleLicence,
    toggleAccept,
    toggleAgreement,
    agree,
    isPrivacyPolicy,
    isLicence,
    isAgreement,
    isCookie,
    isAgreed,
    isModal,
  }
}
