import React, { useState } from 'react'
import LineChart from '../LineChart'
import DevicePaiChart from '../../../../CommonStatistics/PaiChartBlock/DevicePaiChart'
import { linerChartRenderConfig } from '../../../../config'
import AppPaiChart from '../../../../CommonStatistics/PaiChartBlock/AppPaiChart'

const SecondaryChart = ({ typeChart }) => {
  return (
    <div className="secondary-chart-wrapper">
      <LineChart option={linerChartRenderConfig[typeChart]} />
      <AppPaiChart />
    </div>
  )
}

export default SecondaryChart
