import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { Field } from 'formik'

import CustomInput from '../../../common/CustomInput'
import CommonFiltersForm from '../../../table/CommonFiltersForm'
import {
  useCommunityProfilesContext,
  useFilterContext,
} from '../../../../context'
import DeviceCategoryId from '../../../devices/DeviceCategoryId'

const className = 'p-col-12 p-sm-6 p-lg-3 p-xl-2'

const FilterForm = ({
  limit,
  offset,
  sortField,
  sortOrder,
  isLoading,
  cleanUp,
}) => {
  const { getAll, Filters, values } = useCommunityProfilesContext()
  const [isFilter] = useFilterContext()
  const initialValues = useMemo(
    () => ({
      dateRange: null,
      name: '',
      desc: '',
      DeviceCategoryId: null,
      deviceModel: '',
      ...(values || {}),
    }),
    [values],
  )

  return (
    <CommonFiltersForm
      cleanUp={cleanUp}
      getAll={getAll}
      limit={limit}
      offset={offset}
      sortField={sortField}
      sortOrder={sortOrder}
      isLoading={isLoading}
      initialValues={initialValues}
      className={isFilter ? 'active' : ''}
    >
      <div className={className}>
        <Field
          type="text"
          name="name"
          placeholder="name"
          component={CustomInput}
        />
      </div>
      <div className={className}>
        <Field
          type="text"
          name="desc"
          placeholder="description"
          component={CustomInput}
        />
      </div>
      <div className={className}>
        <Field
          name="DeviceCategoryId"
          placeholder="DeviceCategoryId"
          component={DeviceCategoryId}
        />
      </div>
      <div className={className}>
        <Field
          type="text"
          name="deviceModel"
          placeholder="model"
          component={CustomInput}
        />
      </div>
      {Filters && <Filters gridClassName={className} />}
    </CommonFiltersForm>
  )
}

const withData = inject((stores) => ({
  isLoading: stores.CommunityStore.isLoading,
  cleanUp: stores.CommunityStore.cleanUp,
}))

export default withData(observer(FilterForm))
