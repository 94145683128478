import AppStore from './App/mobx'
import AuthStore from './components/Auth/mobx'
import ProfileStore from './components/ProfilePage/mobx'
import UsersStore from './components/Users/mobx'
import ContactStore from './components/ContactForm/mobx'
import DevicesStore from './components/DevicesCatalog/mobx'
import DeviceProfilesStore from './components/DeviceProfilesPage/mobx'
import CommunityStore from './components/CommunityPage/mobx'
import LanguageStore from './components/Language/mobx'
import InfoDialogStore from './components/dialogs/InfoDialog/mobx'
import ConfirmDialogStore from './components/dialogs/CustomConfirmDialog/mobx'
import NotificationsStore from './components/Notifications/mobx'
import PromotionsStore from './components/PromotionsPage/mobx'
import DesktopApplicationStore from './components/DesktopVersionPage/mobx'
import StatisticsStore from './components/StatisticsPage/mobx'

export default {
  AppStore,
  StatisticsStore,
  AuthStore,
  ProfileStore,
  UsersStore,
  ContactStore,
  DevicesStore,
  DeviceProfilesStore,
  CommunityStore,
  LanguageStore,
  InfoDialogStore,
  ConfirmDialogStore,
  NotificationsStore,
  PromotionsStore,
  DesktopApplicationStore,
}
