import * as columns from './columns'

import { renderIntoTable } from '../../../../utils/react'
import UsersProfilesActionBody from '../View/UsersProfilesActionBody'
import MyPublicationsActionBody from '../View/MyPublicationsActionBody'
import MyPublicationsFilters from '../View/MyPublicationsFilters'
import { CommunityProfileStatus } from '../../../../constants/profiles'

const commonCols = [
  'name',
  'desc',
  'isLightingEffectsOnly',
  'activeTo',
  'createdAt',
  'likes',
  'downloads',
]

export const publicProfilesConfig = {
  columnList: ['user', ...commonCols, 'actions'],
  columnPropsMapping: ['likes', 'downloads', 'createdAt'].reduce(
    (acc, key) => ({ ...acc, [key]: { sortable: true } }),
    {
      actions: {
        body: renderIntoTable(UsersProfilesActionBody),
        className: 'common-td-style',
      },
    },
  ),
}

export const lightningEffectsConfig = {
  ...publicProfilesConfig,
  columnList: publicProfilesConfig.columnList.filter(
    (col) => col !== 'isLightingEffectsOnly',
  ),
}

export const myProfilesConfig = {
  columnList: [...commonCols, 'status', 'actions'],
  columnPropsMapping: {
    actions: {
      body: renderIntoTable(MyPublicationsActionBody),
      className: 'little-td-style',
    },
  },
  Filters: MyPublicationsFilters,
  values: {
    status: null,
  },
}

export const publicProfilesBaseQuery = {
  withUsers: true,
  status: CommunityProfileStatus.Published,
}

export { columns }
