import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

import { bindClassName } from '../../../../../utils/react'

const AuthLink = ({ to, labelKey }) => {
  const { t } = useTranslation()

  return (
    <Link className="sign-link" to={to}>
      <span>{t(`labels.${labelKey}`)}</span>
    </Link>
  )
}

export default AuthLink
