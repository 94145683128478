import React from 'react'

import Condition, { If, Else } from '../Condition'
import Spinner from '../../common/Spinner'

const Loadable = ({ loading, spinnerClassName, spinnerText, children }) => (
  <Condition condition={loading}>
    <If>
      <Spinner className={spinnerClassName} text={spinnerText} />
    </If>
    <Else>{children || null}</Else>
  </Condition>
)

export default Loadable
