import React, { useCallback } from 'react'
import { inject } from 'mobx-react'

import Profiles, {
  publicProfilesBaseQuery,
  publicProfilesConfig,
} from '../../Profiles'

const PublicProfiles = ({ getAll }) => {
  const customizedGetAll = useCallback(
    (fields) => getAll({ ...fields, ...publicProfilesBaseQuery }),
    [getAll],
  )

  return <Profiles getAll={customizedGetAll} {...publicProfilesConfig} />
}

const withData = inject((stores) => ({
  getAll: stores.CommunityStore.getAll,
}))

export default withData(PublicProfiles)
