import { useCallback, useEffect, useState } from 'react'

import { DEFAULT_LIMIT } from '../../constants'

const defaultPagination = {
  limit: DEFAULT_LIMIT,
  offset: 0,
  sortField: null,
  sortOrder: null,
}

const usePagination = (cleanUp, list) => {
  const [pagination, setPagination] = useState(defaultPagination)

  useEffect(() => {
    cleanUp()
    return setPagination(defaultPagination)
  }, [])

  const onPage = useCallback(() => {
    setPagination((pagination) => ({
      ...pagination,
      limit: DEFAULT_LIMIT,
      offset: list.length,
    }))
  }, [setPagination, list])

  const onSort = useCallback((data) => {
    setPagination((pagination) => ({
      ...pagination,
      ...data,
    }))
  }, [])

  return {
    ...pagination,
    removableSort: true,
    pagination,
    setPagination,
    onSort,
    onPage,
  }
}

export default usePagination
