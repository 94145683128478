import React, { useEffect, useMemo, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { tabsConfig } from './tabs/config'
import ShareGroup from './ShareGroup'
import Navigation from './tabs/Navigation'
import { ShareContext, FilterContext } from '../../context'
import './CommunityPage.scss'
import Condition, { Else, If } from '../hoc/Condition'
import MyDeviceProfile from '../devices/MyDeviceProfile'
import { BsFilter } from 'react-icons/bs'
import useToggler from '../../hooks/useToggler'
import { useTranslation } from 'react-i18next'

const CommunityPage = ({ getDeviceCategories, deviceCategoriesCleanUp }) => {
  const { t } = useTranslation()
  const [activePage, setPage] = useState(
    tabsConfig.find(({ header }) => header).header,
  )
  const [shareContext, setShareContext] = useState(null)
  const [isFilter, toggleFilter] = useToggler(false)

  useEffect(() => {
    getDeviceCategories()
  }, [])

  const FilterButton = (
    <button
      className={`p-button p-component secondary-btn filter-button p-ml-4 ${
        isFilter ? 'active' : ''
      }`}
      onClick={toggleFilter}
    >
      <BsFilter />
      {t('labels.filters')}
    </button>
  )

  const { Component } = useMemo(
    () => tabsConfig.find(({ header }) => header === activePage),
    [activePage],
  )

  return (
    <ShareContext.Provider value={[shareContext, setShareContext]}>
      <FilterContext.Provider value={[isFilter, toggleFilter]}>
        <Condition condition={!!shareContext}>
          <If>
            <MyDeviceProfile
              isPublish
              id={shareContext}
              postPublish={setShareContext}
            />
          </If>
          <Else>
            <div className="page-content-wrapper">
              <div className="community-navigation-wrapper">
                <Navigation activePage={activePage} setPage={setPage} />
                <ShareGroup />
                {FilterButton}
              </div>
              <Component isFilter={isFilter} />
            </div>
          </Else>
        </Condition>
      </FilterContext.Provider>
    </ShareContext.Provider>
  )
}

const withData = inject((stores) => ({
  getDeviceCategories: stores.DevicesStore.getDeviceCategories,
  deviceCategoriesCleanUp: stores.DevicesStore.deviceCategoriesCleanUp,
}))

export default withData(observer(CommunityPage))
