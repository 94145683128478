import React from 'react'
import Auth, { Action } from '../index'
import { useLocation } from 'react-router-dom'

import AuthLink from '../Form/SignHeadLine/AuthLink'
import { useTranslation } from 'react-i18next'
import RenderIf from '../../hoc/RenderIf'

const AlternativeAuthContent = ({ activeItem }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const comment = pathname.startsWith('/forgot-password')
    ? 'remembered_password'
    : activeItem.comment
  const condition = Action.SignIn !== activeItem.action
  return (
    <div className="alternative-sign-link">
      <RenderIf condition={condition}>
        <Auth.ForgotPasswordLink />
      </RenderIf>
      <span className="alternative-sign-title w-content">
        {t(`auth.oauth.${comment}`)}
      </span>
      <AuthLink to={activeItem.to} labelKey={activeItem.labelKey} />
    </div>
  )
}

export default AlternativeAuthContent
