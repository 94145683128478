import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import AuthForm from '../Auth/Form'
import RenderIf from '../hoc/RenderIf'
import Condition, { Else, If } from '../hoc/Condition'
import RecoveryPassMsg from '../Auth/Form/RecoveryPassMsg'
import InputVerifyCode, { smsLabel } from '../Auth/Form/InputVerifyCode'
import { pipe } from '../../utils/function'
import { emailShape } from '../Auth/Form/Email'

const validationSchema = yup.object().shape({
  login: emailShape.email,
})

const values = { login: '', code: [] }

const Form = ({
  forgotPassword,
  verifySMSCode,
  enabledSms,
  resendCode,
  login,
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const verifySMS = useCallback((data) => verifySMSCode(data, history), [
    verifySMSCode,
    history,
  ])

  const { onSubmit, submitLabel } = enabledSms
    ? { onSubmit: verifySMS, submitLabel: t('actions.confirm') }
    : {
        onSubmit: forgotPassword,
        submitLabel: t('actions.send'),
      }

  return (
    <AuthForm
      validationSchema={validationSchema}
      values={values}
      onSubmit={onSubmit}
      submitLabel={submitLabel}
    >
      <Condition condition={login}>
        <If>
          <RecoveryPassMsg login={login} />
        </If>
        <Else>
          <AuthForm.MixLogin disabled={enabledSms} />
        </Else>
      </Condition>
      <RenderIf condition={enabledSms}>
        <InputVerifyCode
          label={smsLabel}
          handleResend={resendCode}
          name="code"
        />
      </RenderIf>
    </AuthForm>
  )
}

const withData = inject((stores) => ({
  enabledSms: stores.AuthStore.enabledSms,
  resendCode: stores.AuthStore.forgotPasswordResendSMSCode,
  verifySMSCode: stores.AuthStore.verifySMSCode,
  forgotPassword: stores.AuthStore.forgotPassword,
  login: stores.AuthStore.login,
}))

export default pipe(observer, withData)(Form)
