import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from 'highcharts/highcharts-3d'
highcharts3d(Highcharts)

const options = {
  chart: {
    type: 'column',
    width: 1200,
  },

  title: {
    text: ' GM-20 total mouse`s buttons clicks',
    style: {
      color: `#76859c`,
    },
  },

  xAxis: {
    categories: ['January', 'February', 'March', 'April', 'May'],
  },

  yAxis: {
    allowDecimals: false,
    min: 0,
    title: {
      text: 'clicks',
      style: {
        color: `#76859c`,
      },
    },
  },

  tooltip: {
    formatter: function () {
      return (
        '<b>' +
        this.x +
        '</b><br/>' +
        this.series.name +
        ': ' +
        this.y +
        '<br/>' +
        'Total: ' +
        this.point.stackTotal
      )
    },
  },

  plotOptions: {
    column: {
      stacking: 'normal',
      style: {
        color: `#76859c`,
      },
    },
  },

  series: [
    {
      name: 'Left clicks',
      data: [5, 3, 4, 7, 2],
      stack: 'mouse',
    },
    {
      name: 'Right clicks',
      data: [3, 4, 4, 2, 5],
      stack: 'mouse',
    },
    {
      name: 'Middle clicks',
      data: [2, 5, 6, 2, 1],
      stack: 'mouse',
    },
    {
      name: 'Mouse 4/5',
      data: [3, 0, 4, 4, 3],
      stack: 'mouse',
    },
  ],
}
const BarChart = () => {
  return (
    <div className="card common-cart-style bar-chart-style">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default BarChart
