import { ProfileDeviceActionType } from '../../../constants'
import DeletableItem from './Configurations/items/DeletableItem'
import SelectableItem from './Configurations/items/SelectableItem'

const profileDefaultValues = {
  name: '',
  desc: '',
}

const profileSelectableItemsDefaultValues = {
  ...profileDefaultValues,
  ids: [],
}

export const config = {
  [ProfileDeviceActionType.Delete]: {
    ConfigurationItem: DeletableItem,
    defaultValues: profileDefaultValues,
  },
  [ProfileDeviceActionType.Select]: {
    ConfigurationItem: SelectableItem,
    defaultValues: profileSelectableItemsDefaultValues,
  },
}
