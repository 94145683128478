import React from 'react'
import '../StatisticsContent.scss'
import DevicesFilter from '../DevicesFilter'
import ChartsWrapper from '../../statisticsType/ChartsStatistic/ChartsWrapper'

const StatisticsContent = ({
  devicesFilter,
  setDevicesFilter,
  dates,
  deviceName,
  setDeviceName,
  setDates,
}) => {
  return (
    <div className="statistic-content-wrapper">
      <div className="statistic-content">
        <DevicesFilter
          {...{
            devicesFilter,
            setDevicesFilter,
            dates,
            deviceName,
            setDeviceName,
            setDates,
          }}
        />
        <ChartsWrapper devicesFilter={devicesFilter} />
      </div>
    </div>
  )
}

export default StatisticsContent
