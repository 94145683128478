import React from 'react'
import { bindClassName } from '../../../utils/react'
import ModalStatisticContent from './ModalStatisticContent'
import './StatisticModal.scss'

const StatisticModal = ({
  activeDate,
  setActiveDate,
  deviceName,
  devicesFilter,
}) => {
  return (
    <div
      {...bindClassName('statistic-daily-modal', [activeDate?.date, 'active'])}
    >
      <div className="statistic-daily-modal-wrapper">
        {activeDate?.date && (
          <ModalStatisticContent
            {...{ activeDate, setActiveDate, deviceName, devicesFilter }}
          />
        )}
      </div>
    </div>
  )
}

export default StatisticModal
