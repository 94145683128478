import React from 'react'
import { Field } from 'formik'

import CustomInputPhone from '../../../common/CustomInputPhone'

const Phone = () => (
  <div className="p-col-12 field-wrapper">
    <Field
      type="text"
      name="phone"
      labelText="Phone Number"
      component={CustomInputPhone}
    />
  </div>
)

export default Phone
