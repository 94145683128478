import * as yup from 'yup'

import { ErrorMsg } from '../../../constants/errorMsg'
import {
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
  PASSWORD_REGEXP,
} from '../../../constants/user'

const baseShape = {
  newPassword: yup
    .string()
    .trim()
    .required(ErrorMsg.PASSWORD_REQUIRED)
    .min(MIN_PASSWORD_LENGTH, ErrorMsg.PASSWORD_IS_SHORT)
    .max(MAX_PASSWORD_LENGTH, ErrorMsg.PASSWORD_IS_SHORT)
    .notOneOf([yup.ref('currentPassword')], ErrorMsg.PASSWORD_MUST_BE_NEW)
    .matches(PASSWORD_REGEXP, ErrorMsg.PASSWORD_NOT_VALID),
  confirmPassword: yup
    .string()
    .required(ErrorMsg.CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref('newPassword')], ErrorMsg.PASSWORD_NOT_MATCH),
}

const schema = yup.object().shape({
  currentPassword: yup
    .string()
    .trim()
    .required(ErrorMsg.PASSWORD_REQUIRED)
    .min(MIN_PASSWORD_LENGTH, ErrorMsg.PASSWORD_IS_SHORT)
    .max(MAX_PASSWORD_LENGTH, ErrorMsg.PASSWORD_IS_SHORT)
    .matches(PASSWORD_REGEXP, ErrorMsg.CURRENT_PASSWORD_NOT_VALID),
  ...baseShape,
})

const noCurrPassSchema = yup.object().shape(baseShape)

const baseValues = { newPassword: '', confirmPassword: '' }
const values = { currentPassword: '', ...baseValues }

export const withPassConfig = {
  submitKey: 'change_password',
  initialValues: values,
  validationSchema: schema,
}

export const noPassConfig = {
  submitKey: 'set_password',
  initialValues: baseValues,
  validationSchema: noCurrPassSchema,
}
