import React from 'react'

import Auth, { Action } from '../Auth'
import Form from './Form'

import useToggler from '../../hooks/useToggler'
import Condition, { If, Else } from '../hoc/Condition'
import Agreement from '../Agreement'

const RegistrationPage = () => {
  const [isAgreed, , agree] = useToggler()

  return (
    <Condition condition={isAgreed}>
      <If>
        <Auth form={Form} oauthAction={Action.SignUp} />
      </If>
      <Else>
        <Agreement onAgree={agree} />
      </Else>
    </Condition>
  )
}

export default RegistrationPage
