import React from 'react'
import ContentGroup from './ContentGroup'

const AwardsGroup = ({ groupName, items }) => {
  return (
    <div className="awards-group-wrapper">
      <h3>{groupName}</h3>
      <div className="awards-content-group">
        {items.map(({ name, icon, disabled }) => (
          <ContentGroup {...{ disabled, name }} Component={icon} />
        ))}
      </div>
    </div>
  )
}

export default AwardsGroup
