export const MessageType = {
  Error: 'Error',
  InvalidMessageType: 'invalid-message-type',
  InvalidMessageResponse: 'invalid-message-response',
  Auth: 'auth',
  AuthFailed: 'auth/failed',
  NotAuth: 'not-auth',

  UserUpdate: 'user/update',
  Notifications: 'notifications',
  NotificationRemove: 'notification/remove',
  NotificationUpdate: 'notification/update',
}

class Message {
  static from(msg) {
    const { type, payload } = JSON.parse(msg)
    return new this(type, payload)
  }

  constructor(type, payload) {
    this.type = type
    this.payload = payload
  }

  toJSON() {
    const data = { type: this.type }
    if (this.payload) data.payload = this.payload

    return JSON.stringify(data)
  }
}

export default Message
