import React from 'react'
import { inject, observer } from 'mobx-react'

import CustomSelect from '../../common/CustomSelect'

const DeviceCategoryId = ({ list, ...rest }) => {
  return (
    <CustomSelect
      options={list}
      optionLabel="name"
      optionValue="id"
      {...rest}
    />
  )
}

const withData = inject((stores) => ({
  list: stores.DevicesStore.categoriesList,
}))

export default withData(observer(DeviceCategoryId))
