export const daily_statistic = [
  {
    date: '19',
    month: 'April',
    time: '03',
    'activity ratio': '23',
    distance: '487',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '20',
    month: 'April',
    time: '0',
    'activity ratio': '0',
    distance: '0',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '21',
    month: 'April',
    time: '05',
    'activity ratio': '231',
    distance: '3452',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '22',
    month: 'April',
    time: '02',
    'activity ratio': '12',
    distance: '2331',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '23',
    month: 'April',
    time: '0',
    'activity ratio': '0',
    distance: '0',
  },
  {
    date: '24',
    month: 'April',
    time: '12',
    'activity ratio': '431',
    distance: '11252',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '25',
    month: 'April',
    time: '01',
    'activity ratio': '43',
    distance: '433',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '26',
    month: 'April',
    time: '11',
    'activity ratio': '543',
    distance: '4987',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '27',
    month: 'April',
    time: '09',
    'activity ratio': '234',
    distance: '2223',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '28',
    month: 'April',
    time: '0',
    'activity ratio': '0',
    distance: '0',
  },
  {
    date: '29',
    month: 'April',
    time: '03',
    'activity ratio': '23',
    distance: '487',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '30',
    month: 'April',
    time: '0',
    'activity ratio': '0',
    distance: '0',
  },
  {
    date: '01',
    month: 'May',
    time: '05',
    'activity ratio': '231',
    distance: '3452',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '02',
    month: 'May',
    time: '02',
    'activity ratio': '12',
    distance: '2331',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '03',
    month: 'May',
    time: '0',
    'activity ratio': '0',
    distance: '0',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '04',
    month: 'May',
    time: '12',
    'activity ratio': '431',
    distance: '11252',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '05',
    month: 'May',
    time: '01',
    'activity ratio': '43',
    distance: '433',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '06',
    month: 'May',
    time: '11',
    'activity ratio': '543',
    distance: '4987',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '07',
    month: 'May',
    time: '09',
    'activity ratio': '234',
    distance: '2223',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '08',
    month: 'May',
    time: '0',
    'activity ratio': '0',
    distance: '0',
  },
  {
    date: '09',
    month: 'May',
    time: '03',
    'activity ratio': '23',
    distance: '487',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '10',
    month: 'May',
    time: '0',
    'activity ratio': '0',
    distance: '0',
  },
  {
    date: '11',
    month: 'May',
    time: '05',
    'activity ratio': '231',
    distance: '3452',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '12',
    month: 'May',
    time: '02',
    'activity ratio': '12',
    distance: '2331',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '13',
    month: 'May',
    time: '0',
    'activity ratio': '0',
    distance: '0',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '14',
    month: 'May',
    time: '12',
    'activity ratio': '431',
    distance: '11252',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '15',
    month: 'May',
    time: '01',
    'activity ratio': '43',
    distance: '433',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '16',
    month: 'May',
    time: '11',
    'activity ratio': '543',
    distance: '4987',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '17',
    month: 'May',
    time: '09',
    'activity ratio': '234',
    distance: '2223',
    data: {
      'Left clicks': '2341',
      'Right clicks': '231',
      'Middle clicks': '32',
      'Mouse 4/5': '4',
      scroll: '112',
    },
  },
  {
    date: '18',
    month: 'May',
    time: '0',
    'activity ratio': '0',
    distance: '0',
  },
]
