import React from 'react'
import './ShareProfileModal.scss'
import CommonModalWindow from '../../../common/dialogWindow/CommonModalWindow'
import ModalContent from './ModalContent'
import { RiShareBoxLine } from 'react-icons/ri'

const ShareProfileModal = ({ isOpen, toggleOpen }) => {
  return (
    <CommonModalWindow
      isActive={isOpen}
      className="share-profile-modal"
      toggleModal={toggleOpen}
      headerKey="share_profile"
      icon={<RiShareBoxLine />}
      withBody
    >
      <ModalContent toggleOpen={toggleOpen} />
    </CommonModalWindow>
  )
}

export default ShareProfileModal
