import React, { useEffect } from 'react'
import { inject } from 'mobx-react'
import View from './View'
import Filter from './Filter'
import usePagination from '../../../../hooks/table/usePagination'

const MyDevicesPage = ({
  cleanUp,
  getDeviceCategories,
  deviceCategoriesCleanUp,
}) => {
  const { limit, offset, onPage } = usePagination(cleanUp)

  useEffect(() => {
    getDeviceCategories()
    return deviceCategoriesCleanUp
  }, [])

  return (
    <>
      <div className="p-fluid page-content-wrapper">
        <Filter />
        <View limit={limit} offset={offset} onPage={onPage} />
      </div>
    </>
  )
}

const withData = inject((stores) => ({
  cleanUp: stores.DevicesStore.cleanUp,
  getDeviceCategories: stores.DevicesStore.getDeviceCategories,
  deviceCategoriesCleanUp: stores.DevicesStore.deviceCategoriesCleanUp,
}))

export default withData(MyDevicesPage)
