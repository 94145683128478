import React from 'react'
import { inject, observer } from 'mobx-react'

import { pipe } from '../../../../utils/function'
import RenderIf from '../../../hoc/RenderIf'
import InputVerifyCode, {
  google2faLabel,
} from '../../../Auth/Form/InputVerifyCode'

const TwoFaConfirm = ({ qrCode }) => (
  <RenderIf condition={qrCode}>
    <div className="p-col-12">
      <img src={qrCode} alt="2FA QR" />
    </div>
    <div className="p-col-12 p-md-6">
      <InputVerifyCode
        label={google2faLabel}
        name="twoFactorAuthenticationCode"
      />
    </div>
  </RenderIf>
)

const withData = inject((stores) => ({
  qrCode: stores.UsersStore.qrCode,
}))

export default pipe(observer, withData)(TwoFaConfirm)
