import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './DesktopRedirectPage.scss'
import api from '../../configs/api'

const DesktopRedirectPage = () => {
  const location = useLocation()
  const { t } = useTranslation()

  useEffect(() => {
    const uri = `${api.desktopAppUri}${location.search || ''}`
    window.open(uri, '_self')
  }, [])

  return (
    <div className="desktop-redirect-page">
      {t('labels.desktop_redirect_message')}
    </div>
  )
}

export default DesktopRedirectPage
