import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import './MenuBar.scss'
import Header from './Header'
import config from './config'
import { bindClassName } from '../../utils/react'
import useToggler from '../../hooks/useToggler'
import RenderIf from '../hoc/RenderIf'

const ignoreRoutes = ['/callback', '/desktop-version']

const Menubar = ({ isLogin, logout, user, isDesktop }) => {
  const { isVerified } = user
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [isOpenNav, toggleOpenNav] = useToggler()

  const listItems = config
    .filter(({ isPublic, isPrivate, needVerify }) => {
      if (isPublic) return true
      return isPrivate === isLogin && (!needVerify || isVerified)
    })
    .map(
      ({
        className,
        url,
        icon,
        labelKey,
        isLogout,
        renderBeforeLink,
        renderAfterLink,
      }) => {
        const props = isLogout ? { onClick: logout, to: '#' } : { to: url }
        const label = t(`menubar.${labelKey}`)

        const isActive =
          url === '/' ? pathname === url : url && pathname.startsWith(url)

        return (
          <li
            onClick={toggleOpenNav}
            key={label}
            {...bindClassName(
              'nav-bar-menu-item',
              [className],
              [isActive, 'active'],
            )}
          >
            {renderBeforeLink?.()}
            <Link {...props}>
              {icon && icon}
              {label}
            </Link>
            {renderAfterLink?.()}
          </li>
        )
      },
    )

  return (
    <RenderIf
      condition={
        !isDesktop &&
        isLogin &&
        !ignoreRoutes.some((route) => pathname.startsWith(route))
      }
    >
      <Header isOpenNav={isOpenNav} toggleOpenNav={toggleOpenNav}>
        {listItems}
      </Header>
    </RenderIf>
  )
}

const withData = inject((stores) => ({
  isLogin: stores.AuthStore.isLogin,
  logout: stores.AuthStore.logout,
  user: stores.AuthStore.user,
  isDesktop: stores.AppStore.isDesktop,
  list: stores.NotificationsStore.list,
}))

export default withData(observer(Menubar))
