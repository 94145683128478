import React from 'react'

import { buildQuery } from '../../../../../helpers/http'
import SecondaryButton from '../../../../buttons/SecondaryButton'

const UsersProfilesActionBody = ({ id, name }) => {
  return (
    <div className="settings-button-block">
      <div>
        <SecondaryButton
          isAbsolute={false}
          overrideClassName="danger-tertiary-button tertiary-button p-button ml-3"
          labelKey="report"
          to={`/contact${buildQuery({ subject: 'report_public', name })}`}
        />
      </div>
      <div className="p-flex">
        <SecondaryButton
          isAbsolute={false}
          to={`/community/profile/${id}`}
          icon="pi pi-cloud-download"
          overrideClassName="common-button-rounded success-round-button ml-3"
        />
      </div>
    </div>
  )
}

export default UsersProfilesActionBody
