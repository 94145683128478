import React, { useCallback } from 'react'
import { inject } from 'mobx-react'

import Profiles, {
  publicProfilesBaseQuery,
  lightningEffectsConfig,
} from '../../Profiles'

const LightEffect = ({ getAll }) => {
  const customizedGetAll = useCallback(
    (fields) =>
      getAll({
        ...fields,
        ...publicProfilesBaseQuery,
        isLightingEffectsOnly: true,
      }),
    [getAll],
  )

  return <Profiles getAll={customizedGetAll} {...lightningEffectsConfig} />
}

const withData = inject((stores) => ({
  getAll: stores.CommunityStore.getAll,
}))

export default withData(LightEffect)
