import React from 'react'

import CommonModalWindow from '../../../common/dialogWindow/CommonModalWindow'
import Redactor from './Redactor'

const CropperModal = ({ isModalOpen, toggleModalOpen }) => (
  <CommonModalWindow
    isActive={isModalOpen}
    className="cropper-modal-window"
    toggleModal={toggleModalOpen}
    headerKey="cropper"
  >
    <Redactor closeModal={toggleModalOpen} />
  </CommonModalWindow>
)

export default CropperModal
