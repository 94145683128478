import { observable, action, computed } from 'mobx'

import Http from '../../helpers/http'
import AuthStore from '../Auth/mobx'
import api from '../../configs/api'
import { noop } from '../../utils/function'
import { success, Notification } from '../../helpers/notifications'
import { ErrorMsg } from '../../constants/errorMsg'
import { findById } from '../../utils/array'

class UsersStore {
  resource = `${api.auth}/v1/users`

  @observable isLoading = false
  @observable isUserLoading = false
  @observable errorMsg = null
  @observable settingsErrorMsg = null
  @observable notificationSettingsErrorMsg = null
  @observable avatarErrorMsg = null
  @observable usersList = []
  @observable usersCount = 0
  @observable user = null
  @observable qrCode = null

  isUserDeletable = (user = this.user) => {
    return user.isDeletable && AuthStore.hasPrivilegeOver(user)
  }

  @computed get currentUserAvatarUrl() {
    const { avatar, id } = AuthStore.user || {}

    if (avatar) {
      return `${this.resource}/${id}/avatars/${avatar}`
    }
  }

  @action
  setSettingsError = (err) => {
    this.settingsErrorMsg = err
  }

  @action
  uploadAvatar = async (avatar) => {
    const { id } = AuthStore.user

    this.isLoading = true

    const data = new FormData()
    data.set('avatar', avatar)

    const { result, message = null } = await Http.put(
      `${this.resource}/${id}/avatars`,
      data,
    )

    this.setAvatarError(message)
    if (result) {
      this.updateUserLocalInfo(result, id)
      success(Notification.NEW_AVATAR_UPLOADED)
    }

    this.isLoading = false
  }

  @action
  removeAvatar = async () => {
    const { id, avatar: name } = AuthStore.user
    this.isLoading = true

    const { result, message = null } = await Http.delete(
      `${this.resource}/${id}/avatars/${name}`,
    )

    this.setAvatarError(message)
    if (result) {
      this.updateUserLocalInfo(result, id)
      success(Notification.AVATAR_REMOVED)
    }

    this.isLoading = false
  }

  @action
  setAvatarError = (error) => {
    this.avatarErrorMsg = error
  }

  @action
  unlinkSocial = async ({ oauth, id = AuthStore.user.id }) => {
    this.isLoading = true
    const { message, result } = await Http.patch(
      `${this.resource}/${id}/oauth/unlink`,
      { oauth },
    )
    if (message) {
      this.settingsErrorMsg = message
    } else {
      this.updateUserLocalInfo(result, id)
      this.settingsErrorMsg = null
    }
    this.isLoading = false
  }

  @action
  toggleUser = async (user = this.user) => {
    const { id } = user
    this.isLoading = true
    let response, notification
    if (this.isUserDeletable(user)) {
      response = await Http.delete(`${this.resource}/${id}`)
      notification = Notification.USER_REMOVED
    } else {
      this.settingsErrorMsg = ErrorMsg.CANT_DELETE_THIS_USER
      this.isLoading = false
      return
    }

    if (response.message) {
      this.settingsErrorMsg = response.message
    } else {
      success(notification, { replacers: { id } })

      const updated = findById(this.usersList, id)
      if (updated) updated.isDeleted = !updated.isDeleted

      if (AuthStore.user?.id == id) {
        void AuthStore.logout()
      }

      this.settingsErrorMsg = null
    }

    this.isLoading = false
  }

  @action
  resetPasswordEmail = async () => {
    const { email } = this.user
    this.isLoading = true
    const response = await AuthStore.resetPasswordEmail(email)
    this.settingsErrorMsg = response?.message || null
    success(Notification.RESET_PASSWORD_LINK_SENT, { replacers: { email } })
    this.isLoading = false
  }

  @action
  disable2fa = () => {
    const { id, enabled2fa, enabledSms } = this.user
    if (enabled2fa || enabledSms)
      return this.updateSettings({ id, enabled2fa: false, enabledSms: false })
  }

  @action
  updateUserInfo = async ({ id, ...payload }, { resetForm }) => {
    const response = await this.update(`${this.resource}/${id}`, payload)
    this.postUpdate(
      response,
      id,
      'errorMsg',
      Notification.INFO_UPDATED,
      resetForm,
    )
  }

  @action
  updateSettings = async ({ id, ...payload }) => {
    const response = await this.update(
      `${this.resource}/${id}/settings`,
      payload,
    )
    this.postUpdate(
      response,
      id,
      'settingsErrorMsg',
      Notification.SETTINGS_UPDATED,
      (result) => {
        if (result.enabled2fa) this.removeQrCode()
      },
    )
  }

  @action
  setCurrentUserLanguage = async (language) => {
    if (!AuthStore.isLogin) return

    const { result } = await Http.patch(`${this.resource}/current/language`, {
      language,
    })

    this.updateUserLocalInfo(result)
  }

  @action
  updateNotificationSettings = async (
    { id, createdAt, updatedAt, ...payload },
    { resetForm },
  ) => {
    const response = await this.update(
      `${this.resource}/current/settings/notifications`,
      payload,
    )
    this.postUpdate(
      response,
      AuthStore.user.id,
      'notificationSettingsErrorMsg',
      Notification.NOTIFICATION_SETTINGS_UPDATED,
      resetForm,
    )
  }

  @action
  removeQrCode = () => {
    this.qrCode = null
  }

  @action
  generate2FA = async () => {
    const response = await Http.get(`${api.auth}/v1/2fa/generate`, {
      isBlob: true,
    })
    if (response.message) {
      this.setError(response.message)
    } else {
      this.setError(null)
      this.qrCode = window.URL.createObjectURL(response)
    }
  }

  @action
  setError = (error) => {
    this.errorMsg = error
  }

  @action
  update = async (url, payload) => {
    payload = AuthStore.processAuthData(payload)
    this.isLoading = true
    return Http.put(url, payload)
  }

  @action
  postUpdate = (
    { message = null, result },
    id,
    errKey,
    successMsg,
    onSuccess = noop,
  ) => {
    this[errKey] = message
    if (result) {
      success(successMsg)
      this.updateUserLocalInfo(result, id)
      onSuccess(result)
    }
    this.isLoading = false
  }

  @action
  updateUserLocalInfo = (result, id = result?.id) => {
    if (!result) return

    const { user, updateCurrentUserInfo } = AuthStore
    if (id == user.id) updateCurrentUserInfo(result)
  }

  @action
  cleanUp = () => {
    this.profileCleanUp()
  }

  @action
  profileCleanUp = () => {
    this.removeQrCode()
    this.errorMsg = null
    this.settingsErrorMsg = null
    this.notificationSettingsErrorMsg = null
    this.avatarErrorMsg = null
    this.isLoading = false
  }
}

export default new UsersStore()
