import React from 'react'
import { Field } from 'formik'
import * as yup from 'yup'

import CountriesSelect from '../../../common/CountriesSelect'

export const countryShape = {
  country: yup.string().trim().required('Please, enter your country'),
}

const Country = () => (
  <div className="p-grid p-dir-col p-nogutter p-my-2">
    <Field name="country" component={CountriesSelect} />
  </div>
)

export default Country
