import React, { useEffect, useMemo, useState } from 'react'
import Condition, { Else, If } from '../../../../hoc/Condition'
import { useTranslation } from 'react-i18next'
import api from '../../../../../configs/api'
import detectDefaultDeviceImage from '../../../../../helpers/detectDefaultDeviceImage'
import DevicesSelectBtn from './DevicesSelectBtn'

const DevicesBlock = ({ devices }) => {
  const { t } = useTranslation()

  const [activeDevice, setDevice] = useState(null)

  useEffect(() => {
    const InitItem = devices.find((el) => !!el?.Device)
    setDevice(InitItem?.Device)
    return () => setDevice(null)
  }, [])
  const deviceCategory = activeDevice?.DeviceCategory.name
  const altImg = useMemo(() => detectDefaultDeviceImage(deviceCategory), [
    activeDevice,
    deviceCategory,
  ])
  const url = useMemo(() => {
    const filename =
      !!activeDevice?.images &&
      activeDevice?.images.find((el) => el?.filename)?.filename
    return !!filename
      ? `${api.devices}/v1/devices/${activeDevice?.id}/images/${filename}`
      : altImg?.filename
  }, [activeDevice])
  return (
    <div className="devise-community-block">
      <span className="model-name">{activeDevice?.model}</span>
      <Condition condition={!!devices.length}>
        <If>
          <div className="community-devise-img-block">
            <img src={url} />
          </div>
          <div className="community-devise-btn-block">
            {activeDevice &&
              devices.map((item) => (
                <DevicesSelectBtn
                  setDevice={setDevice}
                  activeDevice={activeDevice}
                  key={item.id}
                  item={item}
                />
              ))}
          </div>
        </If>
        <Else>
          <span className="alt-msg">{t('labels.no_profile_devices')}</span>
        </Else>
      </Condition>
    </div>
  )
}

export default DevicesBlock
