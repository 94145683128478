import React, { useState } from 'react'
import './DevicesFilter.scss'
import ComparisonBar from '../../ComparisonBar'
import DeviceBlock from './DeviceBlock'
import { inject } from 'mobx-react'
import SecondaryChart from '../../statisticsType/ChartsStatistic/ChartsWrapper/SecondaryChart'

const DevicesFilter = ({
  devicesFilter,
  setDevicesFilter,
  deviceName,
  setDeviceName,
  statistics,
  statisticsNormalised,
}) => {
  const [typeChart, setTypeChart] = useState('liner')
  return (
    <div className="common-devices-filter">
      <div className="devices-filter-block">
        {!!statistics?.length && (
          <DeviceBlock
            {...{
              devicesFilter,
              deviceName,
              setDeviceName,
              setDevicesFilter,
              statisticsNormalised,
            }}
          />
        )}
        <ComparisonBar />
      </div>
      <SecondaryChart typeChart={typeChart} />
    </div>
  )
}

const withData = inject((stores) => ({
  statistics: stores.StatisticsStore.statistics,
  statisticsNormalised: stores.StatisticsStore.statisticsNormalised,
}))

export default withData(DevicesFilter)
