import React from 'react'

import { bindClassName } from '../../../../utils/react'

const Selected = ({ isoCode, phoneCode, toggleSelectOpen }) => (
  <div
    className="custom-input-phone-group__selected"
    onClick={toggleSelectOpen}
  >
    <div {...bindClassName('flag', [isoCode])} />
    <span className="countrycode">+{phoneCode}</span>
    <div className="dropdown-arrow" />
  </div>
)

export default React.memo(Selected)
