import React from 'react'
import { useTranslation } from 'react-i18next'
import logo from '../../../assets/images/logo_animation_2eyes.gif'
import { useLocation } from 'react-router-dom'
import './AuthHeadLine.scss'
import { Action } from '../OAuth'

const AuthHeadLine = ({ oauthAction }) => {
  const { pathname } = useLocation()
  const condition = pathname.startsWith('/forgot-password')

  const label = condition
    ? 'auth.oauth.label.password_recovery'
    : oauthAction === Action.SignIn
    ? 'auth.oauth.label.sign_in'
    : 'auth.oauth.label.sign_up'
  const { t } = useTranslation()

  const title = oauthAction === Action.Access ? 'access' : 'title'

  return (
    <div className="auth-head-line">
      <img className="logo" src={logo} alt="Lorgar" />
      <span className="auth-head-line-label">{t(`${label}`)}</span>
      <span className="auth-head-line-title">{t(`auth.oauth.${title}`)}</span>
    </div>
  )
}

export default AuthHeadLine
