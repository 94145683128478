import React from 'react'
import { inject, observer } from 'mobx-react'
import { Field, Form, Formik } from 'formik'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

import CustomInput from '../../common/CustomInput'
import { noPassConfig, withPassConfig } from './config'
import { ChangePasswordError } from '../../messages/Error'
import RenderIf from '../../hoc/RenderIf'
import { RiLock2Line } from 'react-icons/ri'

const ChangePassword = ({ isLoading, changePassword, hasPassword }) => {
  const { t } = useTranslation()

  const { initialValues, validationSchema, submitKey } = hasPassword
    ? withPassConfig
    : noPassConfig

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={changePassword}
      enableReinitialize
    >
      {({ dirty, values }) => (
        <Form>
          <div className="p-grid">
            <ChangePasswordError />
            <RenderIf condition={hasPassword && 'currentPassword' in values}>
              <div className="p-col-12 p-md-6 profile-info-input-wrapper">
                <Field
                  type="password"
                  name="currentPassword"
                  placeholder="current_password"
                  component={CustomInput}
                />
                <RiLock2Line />
              </div>
            </RenderIf>
            <div className="p-col-12 p-grid ">
              <div className="p-col-12 p-md-6 profile-info-input-wrapper">
                <Field
                  type="password"
                  name="newPassword"
                  placeholder="new_password"
                  component={CustomInput}
                />
                <RiLock2Line />
              </div>
              <div className="p-col-12 p-md-6 profile-info-input-wrapper">
                <Field
                  type="password"
                  name="confirmPassword"
                  placeholder="confirm_new_password"
                  component={CustomInput}
                />
                <RiLock2Line />
              </div>
            </div>
            <div className="p-col-12 profile-info-submit-wrapper">
              <Button
                type="submit"
                label={t(`actions.${submitKey}`)}
                disabled={!(dirty && !isLoading)}
                className="common-button p-button p-component profile-info-submit-btn"
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const withData = inject((stores) => ({
  isLoading: stores.ProfileStore.isPasswordLoading,
  changePassword: stores.ProfileStore.changePassword,
  hasPassword: stores.AuthStore.user.hasPassword,
}))

export default withData(observer(ChangePassword))
