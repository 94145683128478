import React from 'react'
import { Field } from 'formik'

import useSelectOptions from '../../../../../hooks/form/useSelectOptions'
import { CommunityProfileStatus } from '../../../../../constants/profiles'
import CustomSelect from '../../../../common/CustomSelect'

const MyPublicationsFilters = ({ gridClassName }) => {
  const statuses = useSelectOptions(CommunityProfileStatus)

  return (
    <div className={gridClassName}>
      <Field
        name="status"
        placeholder="status"
        component={CustomSelect}
        options={statuses}
      />
    </div>
  )
}

export default MyPublicationsFilters
