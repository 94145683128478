import React from 'react'
import { useTranslation } from 'react-i18next'
import './VersionInfo.scss'

const VersionInfo = ({ version, date }) => {
  const { t } = useTranslation()

  return (
    <div className="document-version">
      <span>
        {t('actions.version')}
        {': '}
        {version}
      </span>
      <span>{date}</span>
    </div>
  )
}
export default VersionInfo
