import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'

import MediaItem from '../../../promotion/MediaItem'
import Links from '../../../promotion/Links'
import { getMediaLink } from '../../../../helpers/promotions'
import Header from './ui/Header'
import Fallback from './Fallback'
import Body from './ui/Body'
import useKeyboard, { KEYBOARD } from '../../../../hooks/useKeyboard'
import PromotionDatesView from '../../../promotion/PromotionDatesView'

const ModalContent = ({ promotionView, unsetPromotionViewId }) => {
  const keyboardConfig = useMemo(
    () => [
      {
        keys: KEYBOARD.ESCAPE,
        handler: unsetPromotionViewId,
      },
    ],
    [unsetPromotionViewId],
  )

  useKeyboard(keyboardConfig)

  if (!promotionView) return <Fallback />
  const { links, mediaType, content, shortText } = promotionView || {}

  return (
    <>
      <Header />
      <Body>
        <div className="promotion-view-media">
          <MediaItem
            mediaType={mediaType}
            mediaLink={getMediaLink(promotionView)}
          />
          <div className="promotion-view-info">
            <Links links={links} isLg />
            <PromotionDatesView {...promotionView} />
          </div>
        </div>
        <div className="promotion-view-content">
          <div className="promotion-view-short-text">{shortText}</div>
          <div className="promotion-view-description">{content}</div>
        </div>
      </Body>
    </>
  )
}

const withData = inject((stores) => ({
  unsetPromotionViewId: stores.PromotionsStore.unsetPromotionViewId,
  promotionView: stores.PromotionsStore.promotionView,
}))

export default withData(observer(ModalContent))
