import React, { useCallback, useState } from 'react'
import { Button } from 'primereact/button'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { useTranslation } from 'react-i18next'
import { inject } from 'mobx-react'
import { BiRightArrowAlt } from 'react-icons/bi'

const DeleteAccBtn = ({ toggleUser, user }) => {
  const { t } = useTranslation()
  const [isVisible, setVisible] = useState(false)
  const toddleConfirm = useCallback(() => setVisible(true), [setVisible])
  const acceptDeleteAccount = useCallback(() => {
    toggleUser(user)
  }, [toggleUser, user])

  return (
    user.isVerified && (
      <>
        <ConfirmDialog
          className="delete-confirm-dialog"
          visible={isVisible}
          onHide={() => setVisible(false)}
          message={t('confirm.delete_account_message')}
          header={t('confirm.delete_account')}
          icon="pi pi-exclamation-triangle"
          accept={acceptDeleteAccount}
          reject={toddleConfirm}
          acceptLabel={t('actions.yes')}
          rejectLabel={t('actions.no')}
          acceptClassName="common-btn danger-tertiary-button"
          rejectClassName="p-button-secondary"
        />
        <div className="delete-your-account" onClick={toddleConfirm}>
          {t(`actions.delete_your_account`)}
          <BiRightArrowAlt />
        </div>
      </>
    )
  )
}
const withData = inject((stores) => ({
  toggleUser: stores.UsersStore.toggleUser,
  isUserDeletable: stores.UsersStore.isUserDeletable,
  hasPrivilegeOver: stores.AuthStore.hasPrivilegeOver,
  user: stores.AuthStore.user,
}))

export default withData(DeleteAccBtn)
