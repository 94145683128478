import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'

import CommonCard from '../../common/CommonCard'
import DownloadButtonGroup from './DownloadButtons'
import Condition, { Else, If } from '../../hoc/Condition'
import Empty from '../../Empty'
import RenderIf from '../../hoc/RenderIf'
import { osDesktopPlatform } from '../../../helpers/platform'
import { mapPlatformToTabName } from '../config'

const PlatformDownload = ({
  title,
  icon,
  platforms,
  availableList,
  tabName,
}) => {
  const versionList = useMemo(() => {
    return availableList.filter(({ platform }) => {
      return platforms.includes(platform)
    })
  }, [availableList, platforms])

  const isCurrPlatform = mapPlatformToTabName(osDesktopPlatform) === tabName

  return (
    <CommonCard
      title={title}
      icon={icon}
      className="user-profile-card p-mx-auto"
    >
      <Condition condition={versionList.length}>
        <If>
          <DownloadButtonGroup
            versionList={versionList}
            platforms={platforms}
            tabName={tabName}
          />
          <RenderIf condition={isCurrPlatform}>
            <Empty labelKey="your_platform" className="center" />
          </RenderIf>
        </If>
        <Else>
          <Empty labelKey="no_desktop_apps" className="center" />
        </Else>
      </Condition>
    </CommonCard>
  )
}

const withData = inject((stores) => ({
  getAll: stores.DesktopApplicationStore.getAll,
  cleanUp: stores.DesktopApplicationStore.cleanUp,
  isLoading: stores.DesktopApplicationStore.isLoading,
  availableList: stores.DesktopApplicationStore.availableList,
}))

export default withData(observer(PlatformDownload))
