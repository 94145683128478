import React from 'react'

const CommonBody = ({ children }) => (
  <div className="common-modal-body">
    <div className="modal-body-description" />
    {children}
  </div>
)

export default CommonBody
