import React from 'react'

import useToggler from '../../../hooks/useToggler'
import ShareProfileModal from './ShareProfileModal'
import { useTranslation } from 'react-i18next'
import { RiAddFill } from 'react-icons/ri'

const ShareGroup = () => {
  const { t } = useTranslation()
  const [isOpen, toggleOpen] = useToggler()

  return (
    <>
      <button
        className="p-button p-component secondary-btn p-ml-auto"
        onClick={toggleOpen}
      >
        <RiAddFill />
        {t('actions.share_profile')}
      </button>
      <ShareProfileModal isOpen={isOpen} toggleOpen={toggleOpen} />
    </>
  )
}

export default ShareGroup
