import React from 'react'

import Auth from '../Auth'
import Form from './Form'

const ChangePasswordPage = () => (
  <Auth form={Form} className="change-password" />
)

export default ChangePasswordPage
