export const DesktopPlatform = {
  Windows64: 'win64',
  Windows32: 'win32',
  Linux: 'linux',
  MacOs: 'macos',
}

export const desktopPlatformLabelsMapping = {
  [DesktopPlatform.Windows64]: 'Windows (x64)',
  [DesktopPlatform.Windows32]: 'Windows (x32)',
  [DesktopPlatform.Linux]: 'Linux',
  [DesktopPlatform.MacOs]: 'MacOs',
}

export const SHOW_LAST_VERSIONS_COUNT = 2
