import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { BreadCrumb as BreadCrumb } from 'primereact/breadcrumb'
import { useTranslation } from 'react-i18next'

export * from './model'

const CustomBreadCrumb = ({
  model,
  wrapperClassName,
  isLogin,
  isDesktop,
  condition = true,
  ...rest
}) => {
  const history = useHistory()
  const { t } = useTranslation()

  const home = useMemo(
    () => ({ icon: 'pi pi-home', command: () => history.push('/') }),
    [history],
  )

  const processedModel = useMemo(
    () =>
      model.map(({ url, labelKey, ...rest }) => {
        const extra = {}
        if (url) {
          if (url.startsWith('/')) extra.command = () => history.push(url)
          else extra.url = url
        }
        return { ...rest, ...extra, label: t(`breadcrumb.${labelKey}`) }
      }),
    [model, history],
  )

  if (isDesktop || !condition) return null

  return <BreadCrumb {...rest} home={home} model={processedModel} />
}

const withData = inject((stores) => ({
  isLogin: stores.AuthStore.isLogin,
  isDesktop: stores.AppStore.isDesktop,
}))

export default withData(observer(CustomBreadCrumb))
