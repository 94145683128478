import Awards from './statisticsType/Awards'
import CommonStatistic from './CommonStatistics'
import MyDevicesPage from './statisticsType/MyDevicesPage'
import DailyStatistics from './statisticsType/DailyStatistics'

export const statisticTypeConfig = [
  {
    key: 'common_statistic',
    label: 'Statistic',
    name: 'Statistic',
    isCalendar: true,
    Component: CommonStatistic,
  },
  {
    key: 'daily_statistic',
    label: 'Daily',
    name: 'Daily',
    isCalendar: false,
    Component: DailyStatistics,
  },
  {
    key: 'awards',
    label: 'Awards',
    name: 'Awards',
    isCalendar: false,
    Component: Awards,
  },
  {
    key: 'devices',
    label: 'My devices',
    name: 'My devices',
    isCalendar: false,
    Component: MyDevicesPage,
  },
]

export const linerChartRenderConfig = {
  liner: {
    series: [
      {
        name: 'Scroll',
        data: [
          7.0, 6.9, 9.5, 14.5, 18.4, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6,
        ],
      },
      {
        name: 'Total distance',
        data: [
          3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0, 16.6, 14.2, 10.3, 6.6, 4.8,
        ],
        color: `red`,
      },
    ],
    title: {
      text: 'Mouse distance',
      style: {
        color: `#76859c`,
      },
    },
    yAxis: {
      title: {
        text: 'distance (m)',
        style: {
          color: `#76859c`,
        },
      },
    },
  },
  speed: {
    series: [
      {
        name: 'Activity ratio',
        data: [
          7.0, 6.9, 9.5, 14.5, 18.4, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6,
        ],
        color: `#730738`,
      },
      {
        name: 'Total distance',
        data: [
          3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0, 16.6, 14.2, 10.3, 6.6, 4.8,
        ],
        color: `#26ff2a`,
      },
    ],
    title: {
      text: 'Mouse distance',
      style: {
        color: `#76859c`,
      },
    },
    yAxis: {
      title: {
        text: 'distance (m)',
        style: {
          color: `#76859c`,
        },
      },
    },
  },
}
