import React, { useEffect, useMemo, useState } from 'react'
import { inject, observer } from 'mobx-react'

import PersonalInfo from './PersonalInfo'
import ChangePassword from './ChangePassword'
import SecuritySettings from './SecuritySettings'
import NotificationSettings from './NotificationSettings'
import { ProfileTab } from '../../constants'
import BannerVerifiedInfo from './BannerVerifiedInfo'
import RenderIf from '../hoc/RenderIf'
import SettingNavigation from './SettingNavigation/SettingNavigation'
import { useTranslation } from 'react-i18next'

import './ProfilePage.scss'
import { FaRegUserCircle } from 'react-icons/fa'
import { BiLock } from 'react-icons/bi'
import { MdSecurity } from 'react-icons/md'
import { CgBell } from 'react-icons/cg'
import ActiveProfilePage from './ActiveProfilePage'

const ProfilePage = ({ cleanUp, hasPassword, isVerified, logout }) => {
  const { t } = useTranslation()
  useEffect(() => cleanUp, [])
  const [activeItem, setActiveItem] = useState(ProfileTab.Personal)

  const settingsConfig = useMemo(
    () => [
      /*  {
          title: 'profile_photo',
          Component: ProfilePhoto,
          item: ProfileTab.Avatar,
        },*/
      {
        Component: PersonalInfo,
        item: ProfileTab.Personal,
        icon: <FaRegUserCircle />,
      },
      {
        key: 'password',
        title: hasPassword ? 'change_your_password' : 'set_your_password',
        Component: ChangePassword,
        item: ProfileTab.Password,
        icon: <BiLock />,
      },
      {
        title: 'security_settings',
        Component: SecuritySettings,
        item: ProfileTab.Security,
        icon: <MdSecurity />,
      },
      {
        title: 'notification_settings',
        Component: NotificationSettings,
        item: ProfileTab.NotificationSettings,
        icon: <CgBell />,
      },
    ],
    [hasPassword],
  )

  const activePage = settingsConfig.find((elem) => elem?.item === activeItem)

  return (
    <>
      <RenderIf condition={!isVerified}>
        <BannerVerifiedInfo isVerified={isVerified} />
      </RenderIf>
      <div className="page-profiles-wrapper">
        <SettingNavigation
          activItem={activeItem}
          setActiveItem={setActiveItem}
          settingsConfig={settingsConfig}
        />
        <ActiveProfilePage pageConfig={activePage} />
      </div>
    </>
  )
}

const withData = inject((stores) => ({
  cleanUp: stores.ProfileStore.cleanUp,
  hasPassword: stores.AuthStore.user.hasPassword,
  isVerified: stores.AuthStore.user?.isVerified,
  logout: stores.AuthStore.logout,
}))

export default withData(observer(ProfilePage))
