import React from 'react'

import Auth, { Action } from '../Auth'
import Form from './Form'

const LoginPage = () => {
  return <Auth form={Form} oauthAction={Action.SignIn} />
}

export default LoginPage
