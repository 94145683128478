import React, { useCallback } from 'react'
import { Redirect, Route, withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

import Condition, { Else, If } from './components/hoc/Condition'
import { pipe } from './utils/function'
import authInitControl from './components/hoc/authInitControl'
import { notAuthHomeRoute } from './constants'
import RouteWrapper from './components/RouteWrapper'
import DesktopVersionPage from './components/DesktopVersionPage'

const PrivateRoute = ({ component: Component, isLogin, title, ...rest }) => {
  const render = useCallback(
    (props) => (
      <Condition condition={isLogin}>
        <If>
          <RouteWrapper title={title}>
            <Component {...props} />
            <DesktopVersionPage />
          </RouteWrapper>
        </If>
        <Else>
          <Redirect to={notAuthHomeRoute} />
        </Else>
      </Condition>
    ),
    [isLogin, title, Component],
  )

  return <Route {...rest} render={render} />
}

const withData = inject((stores) => ({
  isLogin: stores.AuthStore.isLogin,
}))

export default pipe(
  observer,
  withData,
  withRouter,
  authInitControl,
)(PrivateRoute)
