import React from 'react'

import Button, { Action } from './Button'
import { OAuth } from '../../../../constants'
import { bindClassName } from '../../../../utils/react'

export { Action }

const Buttons = ({
  action,
  childrenClass,
  className,
  component: Component = Button,
}) => (
  <div {...bindClassName('oauth-button-group', [className])}>
    <Component className={childrenClass} action={action} type={OAuth.Google} />
    <Component
      className={childrenClass}
      action={action}
      type={OAuth.Facebook}
    />
  </div>
)

export default Buttons
