import { action, computed, observable } from 'mobx'

import api from '../../configs/api'
import Http, { buildQuery } from '../../helpers/http'
import { findById } from '../../utils/array'
import { pipe } from '../../utils/function'
import { formatFilters } from '../../hooks/table/useApplyFilters'

class PromotionsStore {
  resource = `${api.devices}/v1/promotions`

  @observable isLoading = false
  @observable isFetched = false
  @observable list = []
  @observable count = 0
  @observable item = null
  @observable errorMsg = null
  @observable promotionViewId = null

  @computed get promotionView() {
    if (!this.promotionViewId) return null
    const result =
      this.item?.id === this.promotionViewId
        ? this.item
        : findById(this.list, this.promotionViewId)
    return result || null
  }

  @action
  unsetPromotionViewId = () => {
    this.setPromotionViewId(null)
    this.item = null
  }

  @action
  setPromotionViewId = (id) => {
    this.promotionViewId = id

    if (id) {
      void this.getOne(id)
    }
  }

  @action
  getAll = async (fields) => {
    this.isFetched = false
    this.isLoading = true
    const query = pipe(formatFilters, buildQuery)(fields)
    const { message = null, result = [], count = 0 } = await Http.get(
      `${this.resource}${query}`,
    )
    this.errorMsg = message
    this.list = result
    this.count = count
    this.isLoading = false
    this.isFetched = true
  }

  @action
  getOne = async (id) => {
    this.isLoading = true
    const { message = null, result = null } = await Http.get(
      `${this.resource}/${id}`,
    )
    this.errorMsg = message
    this.item = result
    this.isLoading = false
  }

  @action
  cleanUp = () => {
    this.isLoading = false
    this.isFetched = false
    this.list = []
    this.count = 0
    this.item = null
    this.errorMsg = null
    this.promotionViewId = null
  }
}

export default new PromotionsStore()
