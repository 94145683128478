import React from 'react'
import { useTranslation } from 'react-i18next'

const CountryInfo = () => {
  const { t } = useTranslation()
  return (
    <div className="valid-country-msg">{t('labels.set_valid_country')}</div>
  )
}

export default CountryInfo
