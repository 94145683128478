import React from 'react'
import { Button } from 'primereact/button'

import RenderIf from '../../../hoc/RenderIf'
import CommonInputSegmented from '../../../common/CommonInputSegmented'

const SEGMENTS_COUNT = 6

export const smsLabel = 'Recovery code'
export const google2faLabel = 'Password from Google Authenticator app'

const InputVerifyCode = ({ handleResend, name, label }) => (
  <CommonInputSegmented
    segmentsCount={SEGMENTS_COUNT}
    name={name}
    label={label}
  >
    <RenderIf condition={handleResend}>
      <Button
        type="button"
        label="Resend code"
        className="action-link-btn p-0 p-grid ml-auto"
        onClick={handleResend}
      />
    </RenderIf>
  </CommonInputSegmented>
)

export default InputVerifyCode
