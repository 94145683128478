import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { documentationConfig } from './config'
import { documentationTab } from '../../constants'
import InfoNavigaton from './InfoNavigation'
import VersionInfo from './VersionInfo'

import './DocumentationPage.scss'

const DocumentationPage = ({ language }) => {
  const [activeItem, setActiveItem] = useState(documentationTab.Cookies)
  const [updateInfo, setUpdateInfo] = useState(null)
  const activeDate = documentationConfig.find(
    (elem) => elem?.item === activeItem,
  )

  useEffect(() => {
    fetch('assets/i18n/documents/updateDocConfig.json')
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        setUpdateInfo(data)
      })
  }, [language, activeItem])

  if (!updateInfo) return

  const Component = activeDate.Component
  const activeUpdateInfo = updateInfo[language][activeDate.title]

  return (
    <>
      <InfoNavigaton activePage={activeItem} setPage={setActiveItem} />
      <div className="information-document-wrapper">
        <VersionInfo
          version={activeUpdateInfo.version}
          date={activeUpdateInfo.date}
        />
        <Component policyAction={activeDate.item} />
      </div>
    </>
  )
}

const withData = inject((stores) => ({
  language: stores.LanguageStore.language,
}))

export default withData(observer(DocumentationPage))
