import React from 'react'
import { useTranslation } from 'react-i18next'
import { documentationConfig } from '../config'
import DownLoadBtn from './DownLoadBtn'
import './DownLoadBtn/DownLoadBtn.scss'

const InfoNavigation = ({ setPage, activePage }) => {
  const { t } = useTranslation()
  const menu = documentationConfig.map(({ item, title }) => (
    <div
      className={`${activePage === item ? 'active' : ''} community-nav-item`}
      onClick={() => setPage(item)}
      key={item}
    >
      {t(`documents.tab_labels.${title}`)}
    </div>
  ))
  return (
    <div className="community-navigation-wrapper">
      {menu}
      <DownLoadBtn activePage={activePage} />
    </div>
  )
}

export default InfoNavigation
