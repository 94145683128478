import React, { useCallback, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import * as yup from 'yup'

import Profile from '../Profile'
import { DeviceProfilesError } from '../../messages/Error'
import { ProfileDeviceActionType } from '../../../constants'
import PublishFormEntities from './PublishFormEntities'
import { ErrorMsg } from '../../../constants/errorMsg'
import './MyDeviceProfile.scss'

const publishValues = {
  activeTo: null,
  isLightingEffectsOnly: false,
  withMacros: false,
}

const shape = {
  activeTo: yup
    .date()
    .nullable()
    .typeError(ErrorMsg.ACTIVE_TO_REQUIRED)
    .required(ErrorMsg.ACTIVE_TO_REQUIRED),
}

const MyDeviceProfile = (props) => {
  const {
    id,
    getOne,
    submitOne,
    publishProfile,
    postPublish,
    deleteProfileDevice,
    item,
    isLoading,
    isPublish,
  } = props

  useEffect(() => {
    getOne(id)
  }, [])

  const handlePublish = useCallback(
    (...args) => publishProfile(...args, postPublish),
    [publishProfile, postPublish],
  )

  const profileProps = isPublish
    ? {
        itemActionType: ProfileDeviceActionType.Select,
        submitLabelKey: 'share',
        onSubmit: handlePublish,
        FormEntities: PublishFormEntities,
        values: publishValues,
        shape,
      }
    : {
        itemActionType: ProfileDeviceActionType.Delete,
        onItemClick: deleteProfileDevice,
        onSubmit: submitOne,
      }

  return (
    <div className="device-card-content-wrapper p-d-flex overflow-auto">
      <Profile
        profile={item}
        isLoading={isLoading}
        Error={DeviceProfilesError}
        {...profileProps}
      />
    </div>
  )
}

const withData = inject((stores) => ({
  getOne: stores.DeviceProfilesStore.getOne,
  submitOne: stores.DeviceProfilesStore.submitOne,
  publishProfile: stores.DeviceProfilesStore.publishProfile,
  deleteProfileDevice: stores.DeviceProfilesStore.deleteProfileDevice,
  item: stores.DeviceProfilesStore.item,
  isLoading: stores.DeviceProfilesStore.isLoading,
}))

export default withData(observer(MyDeviceProfile))
