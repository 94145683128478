import React from 'react'

import Description from './Description'
import Instructions from './Instructions'

import './Documentation.scss'

const Documents = ({ id }) => (
  <>
    <Description />
    <Instructions id={id} />
  </>
)

export default Documents
