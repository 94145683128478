import React from 'react'
import { bindClassName } from '../../../utils/react'
import RenderIf from '../../hoc/RenderIf'
import ModalBody from './ModalBody'
import ModalHeader from './ModalHeader'
import './AgreementModal.scss'

const Modal = ({ isActive, toggleModal, policyAction }) => {
  return (
    <div {...bindClassName('access-modal-wrapper', [isActive, 'active'])}>
      <div className="access-modal-content">
        <RenderIf condition={isActive}>
          <ModalHeader toggleModal={toggleModal} label={policyAction} />
          <ModalBody policyAction={policyAction} />
        </RenderIf>
      </div>
    </div>
  )
}

export default Modal
