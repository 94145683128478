import { action, computed, observable } from 'mobx'

import api from '../../configs/api'
import Http, { buildQuery } from '../../helpers/http'
import {
  Notification,
  notifyErrorOccurred,
  success,
} from '../../helpers/notifications'
import Publisher from '../../utils/Publisher'
import { NOTIFICATION_FAST_TIMEOUT } from '../../constants'
import { unique } from '../../utils/array'
import { NotificationType } from '../../constants/system-notifications'

const notify = (successNotification) => {
  if (!successNotification) return

  success(successNotification, {
    timeout: NOTIFICATION_FAST_TIMEOUT,
  })
}

const DURATION_TIME = 500

const MAX_SHOW_PROMOTION_BANNERS_IN_ROW = 2

class NotificationsStore {
  resource = `${api.notifications}/v1/notifications`
  bulkResource = `${this.resource}/bulk`

  @observable isLoading = false
  @observable isCountLoading = false
  @observable list = []
  @observable isModalOpen = false
  @observable activeBannerId = null
  @observable timeout = null
  @observable shownBannerIdList = []

  @computed get count() {
    return this.list.length
  }

  @computed get notSeenPromotionsCount() {
    return this.notSeenPromotionsList.length
  }

  @computed get notSeenPromotionsList() {
    return this.list.filter(
      (item) =>
        item.type === NotificationType.Promotions &&
        !item.isSeen &&
        !item?.Promotion?.isArchived,
    )
  }

  @computed get notSeenCount() {
    return this.list.filter(({ isSeen }) => !isSeen).length
  }

  @computed get activeBanner() {
    return this.notSeenPromotionsList.find(
      (item) => item?.PromotionId === this.activeBannerId,
    )
  }

  @computed get nextActiveItem() {
    return (
      this.notSeenPromotionsList.find((item) => {
        return (
          !item?.isSeen && !this.shownBannerIdList.includes(item?.PromotionId)
        )
      })?.PromotionId || null
    )
  }

  constructor() {
    Publisher.subscribe(Publisher.Logout, () => this.cleanUp())
  }

  @action
  setActiveBannerId = (id) => {
    this.activeBannerId = id
    this.setShownBannerId(id)
  }

  @action
  setShownBannerId = (id) => {
    if (this.shownBannerIdList.includes(id)) return
    this.shownBannerIdList = [...this.shownBannerIdList, id]
  }

  @action
  changeActiveItem = () => {
    this.stopTimeout()
    this.activeBannerId = null
    this.timeout = setTimeout(() => {
      this.setActiveBannerId(this.nextActiveItem)
    }, DURATION_TIME)
  }

  @action
  toggleModalOpen = () => {
    if (this.isModalOpen) return this.closeModal()

    this.isModalOpen = !this.isModalOpen
  }

  @action
  closeModal = () => {
    if (!this.isModalOpen) return

    this.isModalOpen = false
    if (this.notSeenCount) void this.markAllAsSeen()
  }

  @action
  deleteAllNotifications = () => {
    return this.deleteNotifications(
      { isAll: true },
      Notification.ALL_NOTIFICATIONS_DELETED,
    )
  }

  @action
  deleteNotificationsById = (id) => {
    return this.deleteNotifications({ id }, Notification.NOTIFICATION_DELETED)
  }

  @action
  deleteNotifications = async (query, successNotification) => {
    this.isLoading = true
    query = buildQuery(query)

    const { message, result } = await Http.delete(
      `${this.bulkResource}${query}`,
    )
    if (message) {
      notifyErrorOccurred(message)
    } else {
      this.onNotificationsDeleted(result)
      notify(successNotification)
    }

    this.isLoading = false
  }

  @action
  markAllAsSeen = () => {
    return this.setIsSeen({ isAll: true, isSeen: true })
  }

  @action
  setIsSeen = async (body) => {
    this.isLoading = true

    const { message, result } = await Http.patch(this.bulkResource, body)
    if (message) {
      notifyErrorOccurred(message)
    } else {
      this.onNotificationsUpdated(result)
    }

    this.isLoading = false
  }

  @action
  onNotifications = ({ result: notifications, isInit = false }) => {
    this.list = unique(
      [...notifications, ...this.list],
      (item, _item) => item.hash == _item.hash,
    )

    if (isInit) {
      this.shownBannerIdList = [
        ...this.shownBannerIdList,
        ...this.notSeenPromotionsList
          .slice(MAX_SHOW_PROMOTION_BANNERS_IN_ROW)
          .map((item) => item.PromotionId),
      ]
    } else if (!this.isModalOpen) {
      notify(Notification.NEW_NOTIFICATION)
    }
  }

  @action
  onNotificationsDeleted = ({ ids, isAll }) => {
    if (isAll) {
      this.list = []
    } else {
      this.list = this.list.filter((item) => !ids.find((id) => id == item.id))
    }
  }

  @action
  onNotificationsUpdated = ({ ids, isAll, isSeen, updatedAt } = {}) => {
    this.getList(ids, isAll).forEach((item) => {
      item.isSeen = isSeen
      item.updatedAt = updatedAt
    })
  }

  /**@private*/
  getList = (ids, isAll) => {
    return isAll ? this.list : this.list.filter(({ id }) => ids.includes(id))
  }

  @action
  cleanUp = () => {
    this.isLoading = false
    this.isCountLoading = false
    this.list = []
  }

  stopTimeout() {
    if (!this.timeout) return
    clearTimeout(this.timeout)
    this.timeout = null
  }
}

export default new NotificationsStore()
