import React from 'react'

import './HeadLine.scss'
import { deviceCategory } from '../../../configs/devices'
import { useTranslation } from 'react-i18next'

const Headline = ({ item }) => {
  const { t } = useTranslation()
  const { model, DeviceCategory } = item || {}

  return (
    <div className="common-device-headline">
      <span className="device-category">
        {t(`devices.categories_name.${deviceCategory[DeviceCategory?.name]}`)}{' '}
      </span>
      <span className="device-model">{model}</span>
    </div>
  )
}

export default Headline
