import React, { useCallback } from 'react'
import { InputSwitch } from 'primereact/inputswitch'

import { bindClassName } from '../../../utils/react'
import { useFormError } from '../../../hooks/form/useError'
import useLabel from '../../../hooks/form/useLabel'

const CustomSwitch = ({
  form,
  labelText: labelKey,
  field,
  onChange,
  className,
}) => {
  const { error } = useFormError(form, field)

  const handleChange = useCallback(
    (value) => {
      field.onChange(value)
      if (onChange) onChange(value)
    },
    [field.onChange, onChange],
  )

  const { labelText } = useLabel({ labelKey, field })

  return (
    <>
      <div {...bindClassName('my-2 p-align-center p-d-flex', [className])}>
        <InputSwitch
          name={field.name}
          checked={field.value}
          onChange={handleChange}
        />
        <span className="ml-3">{labelText}</span>
      </div>
      {error}
    </>
  )
}

export default CustomSwitch
