import React from 'react'

import CustomCalendarInput from '../CustomCalendarInput'

const CustomCalendar = (props) => (
  <CustomCalendarInput
    selectionMode={props.selectionMode || 'range'}
    readOnlyInput
    {...props}
  />
)

export default CustomCalendar
