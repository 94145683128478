import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import usePrevious from '../../../hooks/usePrevious'
import useSearchParams from '../../../hooks/useSearchParams'
import { parseBoolean } from '../../../utils/string'
import { buildQuery } from '../../../helpers/http'

const PageTracker = ({ submitForm, ...props }) => {
  const prev = usePrevious(props)
  const history = useHistory()
  const { pathname } = history.location

  const { forceSearch, ...restSearchParams } = useSearchParams()

  const prevSearchParams = usePrevious({ forceSearch })

  useEffect(() => {
    if (
      forceSearch !== prevSearchParams.forceSearch &&
      parseBoolean(forceSearch)
    ) {
      submitForm()
      history.push(`${pathname}${buildQuery(restSearchParams)}`)
    }
  }, [forceSearch, submitForm, restSearchParams, pathname, history])

  useEffect(() => {
    submitForm()
  }, [])

  useEffect(() => {
    if (
      ['limit', 'offset', 'sortField', 'sortOrder'].some(
        (key) => prev[key] !== props[key],
      )
    )
      submitForm()
  }, [submitForm, props])

  return null
}

export default React.memo(PageTracker)
