import React from 'react'

import useOutsideElementClick from '../../../../hooks/useOutsideElementClick'
import countriesData from '../../../../configs/countriesData'
import CountryItem from './CountryItem'

const ignoreSelectors = ['.custom-input-phone-group__selected']

const CountriesSelect = ({
  selectedCountryInfo,
  toggleSelectOpen,
  setCountryInfo,
}) => {
  useOutsideElementClick(
    '.custom-input-phone-group .countries-select',
    toggleSelectOpen,
    'body',
    ignoreSelectors,
  )

  return (
    <ul className="countries-select">
      {countriesData.map((info) => (
        <CountryItem
          key={info.name}
          isSelected={info === selectedCountryInfo}
          toggleSelectOpen={toggleSelectOpen}
          setCountryInfo={setCountryInfo}
          info={info}
        />
      ))}
    </ul>
  )
}

export default CountriesSelect
