import React, { useCallback, useMemo } from 'react'
import { Dropdown } from 'primereact/dropdown'

import { bindClassName } from '../../../utils/react'
import useLabel from '../../../hooks/form/useLabel'
import { useFormError } from '../../../hooks/form/useError'
import { useTranslation } from 'react-i18next'
import { deviceCategory } from '../../../configs/devices'
import { inject } from 'mobx-react'

const CustomSelect = ({
  form,
  options,
  placeholder,
  field,
  itemTemplate,
  labelText,
  optionLabel,
  optionValue,
  className,
  isRequired,
  customError,
  value,
  showClear = true,
  ...rest
}) => {
  const { t } = useTranslation()
  const i18nextOptions = JSON.parse(JSON.stringify(options)).map((el) => {
    el.name = t(`devices.categories_name.${deviceCategory[el.name]}`)
    return el
  })
  const { error, showError } = useFormError(form, field)
  const { label, _placeholder } = useLabel({
    labelKey: labelText,
    field,
    placeholder,
    isRequired,
  })

  form = form || {}
  field = field || {}

  if (value === void 0) value = field.value
  return (
    <div className="p-grid p-dir-col p-nogutter">
      {label}
      <Dropdown
        {...bindClassName([className], [showError, 'p-error'])}
        value={value}
        name={field.name}
        options={i18nextOptions}
        onChange={field.onChange}
        placeholder={_placeholder}
        showClear={showClear}
        itemTemplate={itemTemplate}
        optionLabel={optionLabel}
        optionValue={optionValue}
        onBlur={() => form?.setFieldTouched?.(field.name, true, true)}
        {...rest}
      />
      {error}
      {customError}
    </div>
  )
}

export default CustomSelect
