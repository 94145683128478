import React from 'react'
import { useTranslation } from 'react-i18next'

const Header = ({ setBannerStatus }) => {
  const { t } = useTranslation()
  return (
    <div className="promotion-banner-header" onClick={setBannerStatus}>
      <i className="pi pi-times" />
      <span className="mr-2"> {t('messages.YOUR_EMAIL_NOT_VERIFIED')}</span>
    </div>
  )
}

export default Header
