import React from 'react'
import { inject, observer } from 'mobx-react'

import './PromotionView.scss'
import ModalContent from './ModalContent'
import { bindClassName } from '../../../utils/react'
import Loadable from '../../hoc/Loadable'
import Tracker from './Tracker'

const PromotionView = ({ promotionViewId, isLoading, isFetched }) => (
  <div {...bindClassName('promotion-view-page', [promotionViewId, 'active'])}>
    <Tracker />
    <div className="promotion-view-wrapper">
      <Loadable loading={isLoading}>
        {promotionViewId && isFetched && <ModalContent />}
      </Loadable>
    </div>
  </div>
)

const withData = inject((stores) => ({
  promotionView: stores.PromotionsStore.promotionView,
  isFetched: stores.PromotionsStore.isFetched,
  promotionViewId: stores.PromotionsStore.promotionViewId,
  isLoading: stores.PromotionsStore.isLoading,
}))

export default withData(observer(PromotionView))
