import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom'
import DevicesBlock from '../DevicesBlock'
import { deviceCategory } from '../../../../configs/devices'

const DeviceItem = ({ item }) => {
  const { t } = useTranslation()
  const {
    model,
    DeviceCategory: { name },
    DeviceProfile: {},
    id,
  } = item

  return (
    <div className="common-devices-item-wrapper">
      <div className="common-devices-item-body">
        <DevicesBlock item={item} />
      </div>
      <div className="common-devices-item-footer">
        <span className="devices-item-name">
          {t(`devices.categories_name.${deviceCategory[name]}`)} {model}
        </span>
        <Link to={`/catalog/device/${id}`} className="profile-download-link">
          <Button
            type="download"
            label={t('actions.preview')}
            className="common-button secondary-btn"
          />
        </Link>
      </div>
    </div>
  )
}

export default DeviceItem
