import React from 'react'
import { useTranslation } from 'react-i18next'
import { BsExclamationCircle } from 'react-icons/bs'

const RegistryMsg = ({ errorMsg }) => {
  const { t } = useTranslation()
  return (
    <div className="registry-msg-wrapper">
      <div className="registry-msg-icon">
        <BsExclamationCircle />
      </div>
      <div className="registry-msg">
        {errorMsg || t('auth.oauth.registry_msg')}
      </div>
    </div>
  )
}

export default RegistryMsg
