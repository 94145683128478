import React from 'react'
import { inject, observer } from 'mobx-react'
import DeviceItem from './DeviceItem'
import RenderIf from '../../../hoc/RenderIf'
import Loadable from '../../../hoc/Loadable'
import Condition from '../../../hoc/Condition'
import Else from '../../../hoc/Condition/Else'
import If from '../../../hoc/Condition/If'
import { useTranslation } from 'react-i18next'
import { AiOutlineReload } from 'react-icons/ai'
import { GrDocumentText } from 'react-icons/gr'

const View = ({ limit, offset, onPage, list, isLoading, count }) => {
  const { t } = useTranslation()

  const deviseList = list.map((item) => {
    return <DeviceItem item={item} key={item.id} />
  })
  const loadButton = (
    <button
      className={`p-button p-component secondary-btn load-promotion-btn`}
      onClick={onPage}
    >
      <AiOutlineReload />
      {t('labels.show_more')}
    </button>
  )

  return (
    <div className="devices-scroll-content">
      <Loadable loading={isLoading}>
        <Condition condition={list.length}>
          <If>
            <div className="devices-content">{deviseList}</div>
            <RenderIf condition={list.length < count}>{loadButton}</RenderIf>
          </If>
          <Else>
            <div className="no-profile-msg-block">
              <div className="no-profile-icon">
                <GrDocumentText />
              </div>
              <span className="no-profile-msg">
                {t('labels.no_profiles_found')}
              </span>
            </div>
          </Else>
        </Condition>
      </Loadable>
    </div>
  )
}

const withData = inject((stores) => ({
  list: stores.DeviceProfilesStore.list,
  getOne: stores.DeviceProfilesStore.getOne,
  item: stores.DeviceProfilesStore.item,
  count: stores.DeviceProfilesStore.count,
  isLoading: stores.DeviceProfilesStore.isLoading,
}))

export default withData(observer(View))
