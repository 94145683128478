import React, { useCallback } from 'react'
import { InputText } from 'primereact/inputtext'

import { bindClassName } from '../../../utils/react'
import useLabel from '../../../hooks/form/useLabel'
import { useFormError } from '../../../hooks/form/useError'

const CustomInput = ({
  form,
  type,
  labelText,
  field,
  step,
  className,
  placeholder,
  overrideOnChange,
  afterLabel = null,
  isRequired,
  ...props
}) => {
  const { error, showError } = useFormError(form, field)

  const setTouched = useCallback(
    () => form.setFieldTouched(field.name, true, true),
    [form.setFieldTouched, field.name],
  )

  const { label, _placeholder } = useLabel({
    labelKey: labelText,
    isRequired,
    field,
    placeholder,
  })

  return (
    <div className="p-grid p-dir-col p-nogutter">
      {label}
      <InputText
        {...bindClassName(['common-input', className], [showError, 'p-error'])}
        key={field.name}
        name={field.name}
        placeholder={_placeholder}
        value={field.value === null ? '' : field.value}
        type={type}
        step={step}
        onChange={overrideOnChange || field.onChange}
        onBlur={setTouched}
        {...props}
      />
      {error}
    </div>
  )
}

export default CustomInput
