import React, { useMemo } from 'react'
import { MdClear } from 'react-icons/md'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import './NotificationModalWindow.scss'
import RenderIf from '../../hoc/RenderIf'
import ModalBody from './ModalBody'
import { bindClassName } from '../../../utils/react'
import useOutsideElementClick from '../../../hooks/useOutsideElementClick'
import { iconClassName } from './NotificationItem/config'
import useKeyboard, { KEYBOARD } from '../../../hooks/useKeyboard'

const className = 'common-notification-modal-wrapper'

const ignoreSelectors = [`.${iconClassName}`]

const NotificationModalWindow = ({
  isModalOpen,
  closeModal,
  list,
  deleteAll,
}) => {
  const { t } = useTranslation()

  useOutsideElementClick(`.${className}`, closeModal, 'body', ignoreSelectors)

  const keyboardConfig = useMemo(
    () => [
      {
        keys: KEYBOARD.ESCAPE,
        handler: closeModal,
      },
    ],
    [closeModal],
  )

  useKeyboard(keyboardConfig)

  return (
    <div {...bindClassName(className, [isModalOpen, 'active'])}>
      <RenderIf condition={isModalOpen}>
        <span className="common-modal-header">
          <button className="clear-notification-modal" onClick={closeModal}>
            {t('actions.close')}
          </button>
          <RenderIf condition={!!list.length}>
            <button className="clear-notification-modal" onClick={deleteAll}>
              <MdClear />
              {t('actions.delete_all')}
            </button>
          </RenderIf>
        </span>
        <ModalBody list={list} />
      </RenderIf>
    </div>
  )
}

const withData = inject((stores) => ({
  toggleModalOpen: stores.NotificationsStore.toggleModalOpen,
  closeModal: stores.NotificationsStore.closeModal,
  isModalOpen: stores.NotificationsStore.isModalOpen,
  list: stores.NotificationsStore.list,
  deleteAll: stores.NotificationsStore.deleteAllNotifications,
}))

export default withData(observer(NotificationModalWindow))
