import React from 'react'
import { Action } from '../../OAuth/Buttons/Button'

import AlternativeAuthContent from '../../AlternativeAuthContent'

const authLinks = [
  {
    action: Action.SignIn,
    to: '/',
    labelKey: 'sign_in',
    comment: 'sign_up_comment',
  },
  {
    action: Action.SignUp,
    to: '/registration',
    labelKey: 'sign_up',
    comment: 'sign_in_comment',
  },
]

const SignHeadLine = ({ oauthAction }) => {
  const activeItem = authLinks.find((elem) => elem?.action !== oauthAction)

  return <AlternativeAuthContent activeItem={activeItem} />
}

export default SignHeadLine
