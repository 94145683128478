import React, { useCallback } from 'react'
import { BiFullscreen } from 'react-icons/bi'
import { inject, observer } from 'mobx-react'

import MediaItem from '../../../promotion/MediaItem'
import PromotionDatesView from '../../../promotion/PromotionDatesView'
import { getMediaLink } from '../../../../helpers/promotions'
import RenderIf from '../../../hoc/RenderIf'
import NewActionItem from './NewActionItem'

const PromotionItem = ({
  promotion,
  setPromotionViewId,
  notSeenPromotionsList,
}) => {
  const { links, shortText, mediaType, id } = promotion
  const isSeenItem = notSeenPromotionsList.find(
    (item) => item?.PromotionId === id,
  )

  const openPromotion = useCallback(() => setPromotionViewId(id), [
    setPromotionViewId,
  ])

  return (
    <div className="promotion-item-wrapper">
      <div className="promotion-item" onClick={openPromotion}>
        <RenderIf condition={isSeenItem}>
          <NewActionItem />
        </RenderIf>
        <div className="promotion-item-media">
          <MediaItem
            mediaType={mediaType}
            mediaLink={getMediaLink(promotion)}
          />
        </div>
        <div className="promotion-item-info">
          <div className="promotion-item-content">{shortText}</div>
          <PromotionDatesView {...promotion} />
        </div>
      </div>
    </div>
  )
}

const withData = inject((stores) => ({
  setPromotionViewId: stores.PromotionsStore.setPromotionViewId,
  notSeenPromotionsList: stores.NotificationsStore.notSeenPromotionsList,
}))

export default withData(observer(PromotionItem))
