import React, { useEffect, useState } from 'react'
import './StatisticsPage.scss'
import { inject } from 'mobx-react'
import { deviceCategory } from '../../configs/devices'
import TabsStatistic from './TabsStatistic'
import { statisticTypeConfig } from './config'

const StatisticsPage = ({ getAll, cleanUp }) => {
  const [activeApp, setActiveApp] = useState('all')
  const [dates, setDates] = useState('')
  const [statisticType, setStatisticType] = useState(statisticTypeConfig[0])
  const [deviceName, setDeviceName] = useState('')
  const [devicesFilter, setDevicesFilter] = useState(deviceCategory.Mouse)
  const Component = statisticType.Component
  useEffect(() => {
    getAll()
    return cleanUp
  }, [])

  return (
    <div className="p-fluid page-statistic">
      <TabsStatistic
        {...{ statisticType, setStatisticType, dates, setDates }}
      />
      <Component
        {...{
          activeApp,
          setActiveApp,
          devicesFilter,
          setDevicesFilter,
          deviceName,
          setDeviceName,
        }}
      />
    </div>
  )
}

const withData = inject((stores) => ({
  getAll: stores.StatisticsStore.getAllApplications,
  cleanUp: stores.StatisticsStore.cleanUp,
}))

export default withData(StatisticsPage)
