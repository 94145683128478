import React from 'react'
import CookieContent from './PolicyContent/CookieContent'
import PrivacyContent from './PolicyContent/PrivacyContent'
import License from './PolicyContent/Licence'

const ModalBody = ({ policyAction }) => {
  const Component =
    policyAction === 'cookie'
      ? CookieContent
      : policyAction === 'licence'
      ? License
      : PrivacyContent
  return (
    <div className="access-modal-body">
      <Component policyAction={policyAction} />
    </div>
  )
}

export default ModalBody
