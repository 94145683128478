import React from 'react'
import { inject, observer } from 'mobx-react'

import Loadable from './Loadable'
import { pipe } from '../../utils/function'

export default (Component) => {
  const AuthInitControl = ({ isInitting, ...props }) => (
    <Loadable loading={isInitting}>
      <Component {...props} />
    </Loadable>
  )

  const withData = inject((stores) => ({
    isInitting: stores.AuthStore.isInitting,
  }))

  return pipe(observer, withData)(AuthInitControl)
}
