import React, { useCallback, useEffect, useState } from 'react'
import { deviceCategory } from '../../../../../configs/devices'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'primereact/dropdown'

const DeviceBlock = ({
  devicesFilter,
  deviceName,
  setDeviceName,
  setDevicesFilter,
  statisticsNormalised,
}) => {
  useEffect(() => {
    const value = statisticsNormalised[devicesFilter][0].label
    setDeviceName(value)
  }, [devicesFilter, setDeviceName])

  const { t } = useTranslation()
  const deviceCategory = useCallback(() => {
    const options = Object.keys(statisticsNormalised).map((item) => {
      return { label: item, value: item }
    }, {})
    return (
      <Dropdown
        value={devicesFilter}
        name={'category'}
        onChange={(e) => setDevicesFilter(e?.value)}
        options={options}
        placeholder={devicesFilter}
      />
    )
  }, [statisticsNormalised, devicesFilter, setDevicesFilter])

  const deviceModel = useCallback(() => {
    const options = statisticsNormalised[devicesFilter].map((item) => {
      item.label = item.model
      item.name = item.model
      return item
    })
    return (
      <Dropdown
        value={deviceName}
        name={'model'}
        onChange={(e) => setDeviceName(e?.target.value.label)}
        options={options}
        placeholder={deviceName}
      />
    )
  }, [statisticsNormalised, devicesFilter, deviceName, setDeviceName])

  return (
    <>
      {deviceCategory()}
      {deviceModel()}
    </>
  )
}

export default DeviceBlock
