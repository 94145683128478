import React from 'react'

const SettingNavigation = ({ activItem, setActiveItem, settingsConfig }) => {
  const list = settingsConfig.map(({ icon, item }) => {
    return (
      <div
        key={item}
        id={item}
        className={`${activItem === item ? 'active' : ''} 
           setting-navigation-item`}
        onClick={() => setActiveItem(item)}
      >
        {icon}
      </div>
    )
  })
  return <div className="setting-navigation-wrapper">{list}</div>
}

export default SettingNavigation
