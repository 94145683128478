import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { Button } from 'primereact/button'
import { bindClassName } from '../../../utils/react'
import { BiDislike, BiLike } from 'react-icons/bi'

const ToggleLike = ({
  id,
  toggleLike,
  getIsLikable,
  getIsLiked,
  className,
  ...props
}) => {
  const isLikable = getIsLikable(id)
  const isLiked = getIsLiked(id)

  const handleLikeClick = useCallback(() => isLikable && toggleLike(id), [
    toggleLike,
    id,
    isLikable,
  ])
  const LikeBtn = isLiked ? BiDislike : BiLike
  return <LikeBtn onClick={handleLikeClick} />
}

const withData = inject((stores) => ({
  toggleLike: stores.CommunityStore.toggleLike,
  getIsLiked: stores.CommunityStore.getIsLiked,
  getIsLikable: stores.CommunityStore.getIsLikable,
}))

export default withData(observer(ToggleLike))
