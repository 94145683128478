import React from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button'

import Auth from '../Auth'
import Error from '../messages/Error'
import SuccessMessage from '../messages/SuccessMessage'
import { authHomeRoute, notAuthHomeRoute } from '../../constants'
import useLabel from '../../hooks/form/useLabel'
import useSearchParams from '../../hooks/useSearchParams'

const CallbackPage = ({ isLogin }) => {
  const { errorMsg, successMsg, ...replacers } = useSearchParams()

  const { labelKey, to } = isLogin
    ? { labelKey: 'go_home', to: authHomeRoute }
    : { labelKey: 'sign_in', to: notAuthHomeRoute }

  const { labelText } = useLabel({ labelKey })

  return (
    <Auth withHeadline={false}>
      <Error errorMsg={errorMsg} replacers={replacers} />
      <SuccessMessage msg={successMsg} replacers={replacers} />
      <div className="p-col-12">
        <Link to={to}>
          <Button label={labelText} />
        </Link>
      </div>
    </Auth>
  )
}

const withData = inject((stores) => ({
  isLogin: stores.AuthStore.isLogin,
}))

export default withData(observer(CallbackPage))
