import React, { useMemo } from 'react'
import { Field } from 'formik'

import CustomCalendar from '../../../common/CustomCalendar'

const DateRangeField = ({ className, ...rest }) => {
  const maxDate = useMemo(() => new Date(), [])

  return (
    <div className={className}>
      <Field
        name="dateRange"
        component={CustomCalendar}
        maxDate={maxDate}
        placeholder="created"
        {...rest}
      />
    </div>
  )
}

export default React.memo(DateRangeField)
