import React from 'react'
import { Helmet } from 'react-helmet'

const RouteWrapper = ({ title, children }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="main-page-wrapper">{children}</main>
    </>
  )
}

export default RouteWrapper
