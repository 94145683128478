import React from 'react'
import { inject, observer } from 'mobx-react'

import PromotionDatesView from '../../../../../promotion/PromotionDatesView'

const Header = ({ unsetPromotionViewId }) => (
  <div className="promotion-view-header">
    <i className="pi pi-times" onClick={unsetPromotionViewId} />
  </div>
)

const withData = inject((stores) => ({
  promotionView: stores.PromotionsStore.promotionView,
  unsetPromotionViewId: stores.PromotionsStore.unsetPromotionViewId,
}))

export default withData(observer(Header))
