/**@see http://gcctech.org/csc/javascript/javascript_keycodes.htm */
export const KEYBOARD = {
  ESCAPE: 27,
  ENTER: 13,
  ALT: 18,
  SHIFT: 16,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  ADD: 107,
  SUBTRACT: 109,
  NUMPAD_ADD: 187,
  NUMPAD_SUBTRACT: 189,
  BACKSPACE: 8,
  TAB: 9,
  CTRL: 17,
  PAUSE: 19,
  CAPS_LOCK: 20,
  PAGE_UP: 33,
  SPACE: 32,
  PAGE_DOWN: 34,
  END: 35,
  HOME: 36,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
  PRINT_SCREEN: 44,
  INSERT: 45,
  DELETE: 46,
  ZERO: 48,
  ONE: 49,
  TWO: 50,
  THREE: 51,
  FOUR: 52,
  FIVE: 53,
  SIX: 54,
  SEVEN: 55,
  EIGHT: 56,
  NINE: 57,
  A: 65,
  B: 66,
  C: 67,
  D: 68,
  E: 69,
  F: 70,
  G: 71,
  H: 72,
  I: 73,
  J: 74,
  K: 75,
  L: 76,
  M: 77,
  N: 78,
  O: 79,
  P: 80,
  Q: 81,
  R: 82,
  S: 83,
  T: 84,
  U: 85,
  V: 86,
  W: 87,
  X: 88,
  Y: 89,
  Z: 90,
  LEFT_WINDOW_KEY: 91,
  RIGHT_WINDOW_KEY: 92,
  SELECT_KEY: 93,
  NUMPAD_0: 96,
  NUMPAD_1: 97,
  NUMPAD_2: 98,
  NUMPAD_3: 99,
  NUMPAD_4: 100,
  NUMPAD_5: 101,
  NUMPAD_6: 102,
  NUMPAD_7: 103,
  NUMPAD_8: 104,
  NUMPAD_9: 105,
  MULTIPLY: 106,
  DECIMAL_POINT: 110,
  DIVIDE: 111,
  F1: 112,
  F2: 113,
  F3: 114,
  F4: 115,
  F5: 116,
  F6: 117,
  F7: 118,
  F8: 119,
  F9: 120,
  F10: 121,
  F11: 122,
  F12: 123,
  NUM_LOCK: 144,
  SCROLL_LOCK: 145,
  MY_COMPUTER: 182,
  MY_CALCULATOR: 183,
  SEMICOLON: 186,
  EQUAL: 187,
  COMMA: 188,
  DASH: 189,
  PERIOD: 190,
  FORWARD_SLASH: 191,
  OPEN_BRACKET: 219,
  BACK_SLASH: 220,
  CLOSE_BRACKET: 221,
  SINGLE_QUOTE: 222,
}
