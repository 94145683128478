import React, { useEffect, useState } from 'react'
import './DeviceProfilesPage.scss'

import MyPrivateProfiles from '../devices/MyPrivateProfiles'
import { inject } from 'mobx-react'
import { ShareContext } from '../../context'

const DeviceProfilesPage = ({
  cleanUp,
  getDeviceCategories,
  deviceCategoriesCleanUp,
}) => {
  const [shareContext, setShareContext] = useState(null)

  useEffect(() => {
    getDeviceCategories()

    return () => {
      deviceCategoriesCleanUp()
      cleanUp()
    }
  }, [])

  return (
    <div className="page-content-wrapper devices-profiles-content-wrapper">
      <ShareContext.Provider value={[shareContext, setShareContext]}>
        <MyPrivateProfiles />
      </ShareContext.Provider>
    </div>
  )
}

const withData = inject((stores) => ({
  cleanUp: stores.DeviceProfilesStore.cleanUp,
  getDeviceCategories: stores.DevicesStore.getDeviceCategories,
  deviceCategoriesCleanUp: stores.DevicesStore.deviceCategoriesCleanUp,
  categoriesList: stores.DevicesStore.categoriesList,
}))

export default withData(DeviceProfilesPage)
