import React, { useEffect, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import '../../../DevicesCatalog/FilterForm/FilterForm.scss'

import { pipe } from '../../../../utils/function'
import CommonFiltersForm from '../../../table/CommonFiltersForm'
import { Field } from 'formik'
import CustomInput from '../../../common/CustomInput'
import DeviceCategoryId from '../../DeviceCategoryId'

const className = 'p-col-12 p-sm-6 p-lg-3 p-xl-2'

const FilterForm = ({
  limit,
  offset,
  sortField,
  sortOrder,
  isLoading,
  cleanUp,
  getAll,
  isFilter,
}) => {
  useEffect(() => {
    getAll()
  }, [])

  const initialValues = useMemo(
    () => ({
      dateRange: null,
      name: '',
      desc: '',
      DeviceCategoryId: null,
      deviceModel: '',
    }),
    [],
  )

  return (
    <CommonFiltersForm
      cleanUp={cleanUp}
      getAll={getAll}
      limit={limit}
      offset={offset}
      sortField={sortField}
      sortOrder={sortOrder}
      isLoading={isLoading}
      initialValues={initialValues}
      className={isFilter ? 'active' : ''}
    >
      <div className={className}>
        <Field
          type="text"
          name="name"
          placeholder="name"
          component={CustomInput}
        />
      </div>
      <div className={className}>
        <Field
          type="text"
          name="desc"
          placeholder="description"
          component={CustomInput}
        />
      </div>
      <div className={className}>
        <Field
          name="DeviceCategoryId"
          placeholder="DeviceCategoryId"
          component={DeviceCategoryId}
        />
      </div>
      <div className={className}>
        <Field
          type="text"
          name="deviceModel"
          placeholder="model"
          component={CustomInput}
        />
      </div>
    </CommonFiltersForm>
  )
}
const withData = inject((stores) => ({
  isLoading: stores.DevicesStore.isLoading,
  getAll: stores.DeviceProfilesStore.getAll,
  DeviceCategoryList: stores.DevicesStore.categoriesList,
  getDeviceCategories: stores.DevicesStore.getDeviceCategories,
  cleanUp: stores.DeviceProfilesStore.cleanUp,
  deviceCategoriesCleanUp: stores.DevicesStore.deviceCategoriesCleanUp,
}))

export default pipe(observer, withData)(FilterForm)
