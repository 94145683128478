import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'

import useSearchParams from '../../hooks/useSearchParams'
import { parseBoolean } from '../../utils/string'
import MyDeviceProfile from '../devices/MyDeviceProfile'

const DeviceProfilePage = ({ match, cleanUp }) => {
  const { id } = match.params
  const { isPublish } = useSearchParams()

  useEffect(() => cleanUp, [])

  return <MyDeviceProfile id={id} isPublish={parseBoolean(isPublish)} />
}

const withData = inject((stores) => ({
  cleanUp: stores.DeviceProfilesStore.cleanUp,
}))

export default withData(observer(DeviceProfilePage))
