export const fallbackLng = 'en'
export const selectionLng = [
  {
    value: 'en',
    label: 'EN',
  },
  {
    value: 'ru',
    label: 'РУС',
  },
  {
    value: 'ua',
    label: 'УКР',
  },
  {
    value: 'chi',
    label: '中文',
  },
  {
    value: 'fr',
    label: 'Francais',
  },
  {
    value: 'de',
    label: 'Deutsch',
  },
  {
    value: 'ar',
    label: 'عربي',
  },
]

export const availableLanguages = selectionLng.map((v) => v.value)
