import React from 'react'
import BarChart from './StackedChart'
import Highcharts from 'highcharts'
import { deviceCategory } from '../../../../../configs/devices'
import KeyboardChart from './KeyboardChart'
import BarItem from '../../../ComparisonBar/BarItem'

require('highcharts/indicators/indicators')(Highcharts)
require('highcharts/indicators/pivot-points')(Highcharts)
require('highcharts/indicators/macd')(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts-3d')

const ChartsWrapper = ({ devicesFilter }) => {
  const mouseBlock = (
    <div className="common-devices-filter">
      <BarItem />
      <BarChart />
    </div>
  )
  const keyboard = <KeyboardChart />

  const item = devicesFilter === deviceCategory.Mouse ? mouseBlock : keyboard
  return <div className="chart-wrap">{item}</div>
}

export default ChartsWrapper
