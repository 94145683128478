import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import useSearchParams from '../../../hooks/useSearchParams'
import { feedbackSubjects } from '../../../constants/feedback'
import CustomSelect from '../../common/CustomSelect'
import { buildQuery } from '../../../helpers/http'

const CommonSubjects = ({ className, setFieldValue }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { pathname } = history.location
  const searchParams = useSearchParams()

  const { subject: querySubject = null, name: queryName = '{{name}}' } =
    searchParams

  const options = useMemo(() => {
    const mapped = feedbackSubjects.map((v) => ({
      label: t(`subject_contact_titles.${v}`),
      value: v,
    }))
    return [...mapped, { label: t('labels.other'), value: 'other' }]
  }, [t])

  const onChange = useCallback(
    (e) => {
      const { value } = e.target

      const translated =
        value && value !== 'other'
          ? t(`subject_contact.${value}`, { name: queryName })
          : ''
      setFieldValue('subject', translated)

      history.push(
        `${pathname}${buildQuery({ ...searchParams, subject: value })}`,
      )
    },
    [t, searchParams, queryName],
  )
  return (
    <div className={className}>
      <CustomSelect
        options={options}
        value={querySubject}
        onChange={onChange}
        labelText="common_subjects"
        placeholder="select_subject"
      />
    </div>
  )
}

export default CommonSubjects
