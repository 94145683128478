import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'

import Profile from '../devices/Profile'
import { CommunityError } from '../messages/Error'
import { ProfileDeviceActionType } from '../../constants'
import CommunityProfileActivity from './CommunityProfileActivity'

const DeviceProfilePage = ({
  match,
  getOne,
  cleanUp,
  downloadCommunityProfile,
  item,
  isLoading,
}) => {
  const { id } = match.params

  useEffect(() => {
    if (id) getOne(id)

    return cleanUp
  }, [id])

  return (
    <>
      <div className="device-card-content-wrapper p-d-flex overflow-auto">
        <Profile
          profile={item}
          isLoading={isLoading}
          Error={CommunityError}
          itemActionType={ProfileDeviceActionType.Select}
          submitLabelKey="download"
          onSubmit={downloadCommunityProfile}
        >
          <CommunityProfileActivity />
        </Profile>
      </div>
    </>
  )
}

const withData = inject((stores) => ({
  getOne: stores.CommunityStore.getOne,
  downloadCommunityProfile: stores.CommunityStore.downloadCommunityProfile,
  cleanUp: stores.CommunityStore.cleanUp,
  item: stores.CommunityStore.item,
  isLoading: stores.CommunityStore.isLoading,
}))

export default withData(observer(DeviceProfilePage))
