import React from 'react'
import { NotificationContainer } from 'react-notifications'

import Menubar from '../../Menubar'
import InfoDialog from '../../dialogs/InfoDialog'
import CustomConfirmDialog from '../../dialogs/CustomConfirmDialog'
import PromotionActionBanner from '../../promotion/PromotionActionBanner'
import NotificationModalWindow from '../../Notifications/NotificationModalWindow'

const Layout = ({ children }) => (
  <>
    <Menubar />
    <PromotionActionBanner />
    <NotificationContainer />
    <InfoDialog />
    <CustomConfirmDialog />
    <NotificationModalWindow />
    {children}
  </>
)

export default Layout
