import {
  FcGraduationCap,
  FcLandscape,
  FcLike,
  FcNightLandscape,
  FcOldTimeCamera,
  FcOnlineSupport,
  FcPlus,
  FcQuestions,
  FcRating,
  FcReddit,
  FcShipped,
} from 'react-icons/all'

export const awardsConfig = {
  'Super Hero': [
    {
      name: 'Quicker',
      icon: FcShipped,
      disabled: false,
    },
    {
      name: 'Super Star',
      icon: FcRating,
      disabled: false,
    },
    {
      name: 'Good Men',
      icon: FcReddit,
      disabled: true,
    },
    {
      name: 'Something',
      icon: FcNightLandscape,
      disabled: false,
    },
    {
      name: 'Windows',
      icon: FcLike,
      disabled: true,
    },
    {
      name: 'Quicker',
      icon: FcGraduationCap,
      disabled: true,
    },
    {
      name: 'Windows',
      icon: FcOnlineSupport,
      disabled: false,
    },
    {
      name: 'Quicker',
      icon: FcShipped,
      disabled: true,
    },
  ],

  'The Best Player': [
    {
      name: 'Quicker',
      icon: FcPlus,
      disabled: false,
    },
    {
      name: 'Super Star',
      icon: FcOldTimeCamera,
      disabled: false,
    },
    {
      name: 'Good Men',
      icon: FcLandscape,
      disabled: true,
    },
    {
      name: 'Something',
      icon: FcQuestions,
      disabled: true,
    },
    {
      name: 'Windows',
      icon: FcOnlineSupport,
      disabled: true,
    },
    {
      name: 'Quicker',
      icon: FcShipped,
      disabled: true,
    },
  ],
}
