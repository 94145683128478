import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'primereact/button'
import { ShareContext } from '../../../../../../context'

const ActionBody = ({ id, toggleOpen }) => {
  const [, setId] = useContext(ShareContext)
  const { t } = useTranslation()
  const publish = useCallback(() => {
    setId(id)
    toggleOpen()
  }, [setId, id, toggleOpen])

  return (
    <Button
      className="p-button p-component common-button"
      label={t('actions.share')}
      onClick={publish}
    />
  )
}

export default ActionBody
