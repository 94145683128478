import React from 'react'
import { inject, observer } from 'mobx-react'

import { Field } from 'formik'

import CommonFiltersForm, {
  DateRangeField,
} from '../../table/CommonFiltersForm'
import CustomSelect from '../../common/CustomSelect'
import useSelectOptions from '../../../hooks/form/useSelectOptions'
import { PromotionMediaType } from '../../../constants/promotions'
import CustomInput from '../../common/CustomInput'
import CustomCalendarInput from '../../common/CustomCalendarInput'

const initialValues = {
  startDate: null,
  endDate: null,
  mediaType: null,
  content: '',
  shortText: '',

  // mustn't appear in filters UI!
  isActive: true,
}

const RowClassName = 'p-col-12 p-sm-6 p-lg-4  p-xl-2'
const Filter = ({
  getAll,
  limit,
  offset,
  cleanUp,
  isLoading,
  className = '',
}) => {
  const typeOptions = useSelectOptions(PromotionMediaType)

  return (
    <CommonFiltersForm
      cleanUp={cleanUp}
      getAll={getAll}
      limit={limit}
      offset={offset}
      sLoading={isLoading}
      initialValues={initialValues}
      applyLabel="find"
      className={className}
    >
      {/*<DateRangeField className={className} />*/}
      <div className={RowClassName}>
        <Field
          name="startDate"
          placeholder="start_date"
          component={CustomCalendarInput}
        />
      </div>
      <div className={RowClassName}>
        <Field
          name="endDate"
          placeholder="end_date"
          component={CustomCalendarInput}
        />
      </div>
      <div className={RowClassName}>
        <Field
          name="mediaType"
          placeholder="media_type"
          component={CustomSelect}
          options={typeOptions}
        />
      </div>
      <div className={RowClassName}>
        <Field
          name="content"
          component={CustomInput}
          placeholder="promo_content"
        />
      </div>
      <div className={RowClassName}>
        <Field
          name="shortText"
          component={CustomInput}
          placeholder="short_content"
        />
      </div>
    </CommonFiltersForm>
  )
}

const withData = inject((stores) => ({
  getAll: stores.PromotionsStore.getAll,
  isLoading: stores.PromotionsStore.isLoading,
  cleanUp: stores.PromotionsStore.cleanUp,
}))

export default withData(observer(Filter))
