import React from 'react'

import UserAvatar from '../../../../UserAvatar'

const UserInfo = ({ User, className }) => {
  const { email, nickname } = User || {}

  return (
    <>
      <UserAvatar user={User} className={className} />
      <span className="user-auth-info">{nickname || email}</span>
    </>
  )
}

export default UserInfo
