export const humanizeSize = (() => {
  const sizes = [
    'B',
    'kB',
    'MB',
    'GB',
    'TB',
    'PB',
    'EB',
    'ZB',
    'YB',
    'XB',
    'SB',
    'DB',
  ]

  return (size) => {
    size = +size
    if (!size) return '0 B'
    const i = Math.floor(Math.log(size) / Math.log(1024))
    return `${(size / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`
  }
})()

export const unhumanizeSize = (() => {
  const powers = {
    b: 0,
    k: 1,
    m: 2,
    g: 3,
    t: 4,
    p: 5,
    e: 6,
    z: 7,
    y: 8,
    x: 9,
    s: 11,
    d: 12,
  }
  const regex = /(\d+(?:\.\d+)?)\s?(b|k|m|g|t|p|e|z|y|x|s|d)?b?/i

  return (size) => {
    size = size.toString()
    const res = regex.exec(size)

    return res[1] * Math.pow(1024, powers[res[2].toLowerCase()])
  }
})()
