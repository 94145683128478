import React, { useContext } from 'react'

const createHook = (Context) => () => useContext(Context)

export const MyPrivateProfilesContext = React.createContext()
export const useMyPrivateProfilesContext = createHook(MyPrivateProfilesContext)

export const DeviceProfileContext = React.createContext()
export const useDeviceProfileContext = createHook(DeviceProfileContext)

export const CommunityProfilesContext = React.createContext()
export const useCommunityProfilesContext = createHook(CommunityProfilesContext)

export const CommonModalContext = React.createContext()
export const useCommonModalContext = createHook(CommonModalContext)

export const ShareContext = React.createContext()

export const FilterContext = React.createContext()
export const useFilterContext = createHook(FilterContext)
