import React from 'react'
import { useTranslation } from 'react-i18next'

const PrivacyContent = ({ policyAction }) => {
  const { t } = useTranslation()
  return (
    <div className="modal-body-content">
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.1`)}
      </p>
      <h3> {t(`confidentiality.${policyAction}.headline.1`)}</h3>
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.2`)}
      </p>
      <h3> {t(`confidentiality.${policyAction}.headline.2`)}</h3>
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.3`)}
      </p>
      <h3> {t(`confidentiality.${policyAction}.headline.3`)}</h3>
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.4`)}
      </p>
      <h3> {t(`confidentiality.${policyAction}.headline.4`)}</h3>
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.5`)}
      </p>
      <h3> {t(`confidentiality.${policyAction}.headline.5`)}</h3>
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.6`)}
      </p>
      <h3> {t(`confidentiality.${policyAction}.headline.6`)}</h3>
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.7`)}
      </p>
      <h3> {t(`confidentiality.${policyAction}.headline.7`)}</h3>
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.8`)}
      </p>
      <h3> {t(`confidentiality.${policyAction}.headline.8`)}</h3>
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.9`)}
      </p>
      <h3> {t(`confidentiality.${policyAction}.headline.9`)}</h3>
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.10`)}
      </p>
      <h3> {t(`confidentiality.${policyAction}.headline.10`)}</h3>
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.11`)}
      </p>
      <h3> {t(`confidentiality.${policyAction}.headline.11`)}</h3>
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.12`)}
      </p>
      <h3> {t(`confidentiality.${policyAction}.headline.12`)}</h3>
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.13`)}
      </p>
      <h3> {t(`confidentiality.${policyAction}.headline.13`)}</h3>
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.14`)}
      </p>
      <h3> {t(`confidentiality.${policyAction}.headline.14`)}</h3>
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.15`)}
      </p>
      <h3> {t(`confidentiality.${policyAction}.headline.15`)}</h3>
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.16`)}
      </p>
    </div>
  )
}

export default PrivacyContent
