import React from 'react'
import './ShareItem.scss'
import moment from 'moment'
import { GoCalendar } from 'react-icons/go'
import { useTranslation } from 'react-i18next'
import { deviceCategory } from '../../../../../../configs/devices'

const ShareItem = ({ item, ActionBody, toggleOpen }) => {
  const { t } = useTranslation()
  const { name, desc, id, createdAt, devices } = item

  const deviceList = devices.map((el) => (
    <div className="item-device">
      {t(
        `devices.categories_name.${
          deviceCategory[el?.Device?.DeviceCategory?.name]
        }`,
      )}
    </div>
  ))
  if (!deviceList.length) return null

  return (
    <div className="share-item-wrapper">
      <div className="share-item-content">
        <span className="community-item-active-date">
          <GoCalendar />
          <span className="active-date">
            {moment(createdAt).format('DD.MM.YYYY HH:mm')}
          </span>
        </span>
        <div className="share-item-name">{name}</div>
        <div className="share-item-desc">{desc}</div>
        <div className="share-item-devices">{deviceList}</div>
      </div>
      <ActionBody toggleOpen={toggleOpen} id={id} />
    </div>
  )
}

export default ShareItem
