export const PromotionMediaType = {
  Image: 'image',
  Video: 'video',
  None: 'none',
}

export const PromotionMediaResourceType = {
  Url: 'url',
  File: 'file',
}

export const PromotionFileTypes = {
  Images: 'images',
}
