import React from 'react'
import { inject, observer } from 'mobx-react'

import SecondaryButton from '../SecondaryButton'
import { authHomeRoute, notAuthHomeRoute } from '../../../constants'

const GoToMainPage = ({ isLogin }) => (
  <div className="p-col-12 p-md-6 p-flex p-align-center p-justify-center go-to-main-page">
    <SecondaryButton
      isAbsolute={false}
      to={isLogin ? authHomeRoute : notAuthHomeRoute}
      labelKey="go_to_main_page"
      wrapperClassName="link"
    />
  </div>
)

const withData = inject((stores) => ({
  isLogin: stores.AuthStore.isLogin,
}))

export default withData(observer(GoToMainPage))
