import React, { useCallback, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import './FilterForm.scss'

import { pipe } from '../../../utils/function'
import { deviceCategory } from '../../../configs/devices'
import { useTranslation } from 'react-i18next'

const FilterForm = ({
  getAll,
  DeviceCategoryList,
  deviceCategoriesCleanUp,
  getDeviceCategories,
}) => {
  const [filterFields, setFilterFields] = useState([])
  const { t } = useTranslation()
  useEffect(() => {
    const fields = {
      DeviceCategoryId: filterFields,
    }
    getAll(fields)
  }, [filterFields])

  useEffect(() => {
    getDeviceCategories()
    return () => {
      deviceCategoriesCleanUp()
    }
  }, [])

  const toggleFilter = useCallback(
    (id) => {
      const condition = filterFields.includes(id)
      if (condition) setFilterFields(filterFields.filter((i) => i !== id))
      else setFilterFields([...filterFields, id])
    },
    [filterFields],
  )

  const filterBlock = DeviceCategoryList.filter((item) => item.isActive).map(
    (i) => {
      return (
        <div
          className={`${
            filterFields.includes(i.id) ? 'active' : ''
          } filter-item`}
          onClick={() => toggleFilter(i.id)}
          key={i.id}
        >
          {t(`devices.categories_name.${deviceCategory[i.name]}`)}
        </div>
      )
    },
  )

  return (
    <div className="filter-nav-bar">
      <div
        className={`${!filterFields.length ? 'active' : ''} filter-item`}
        onClick={() => setFilterFields([])}
        key={100}
      >
        {t(`devices.categories_name.all`)}
      </div>
      {filterBlock}
    </div>
  )
}
const withData = inject((stores) => ({
  isLoading: stores.DevicesStore.isLoading,
  getAll: stores.DevicesStore.getAll,
  DeviceCategoryList: stores.DevicesStore.categoriesList,
  getDeviceCategories: stores.DevicesStore.getDeviceCategories,
  deviceCategoriesCleanUp: stores.DevicesStore.deviceCategoriesCleanUp,
}))

export default pipe(observer, withData)(FilterForm)
