import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom'

import './SecondaryButton.scss'
import { bindClassName } from '../../../utils/react'
import Condition, { If, Else } from '../../hoc/Condition'
import PageActionsWrapper from '../../common/PageActionsWrapper'

const SecondaryButton = ({
  labelKey,
  to,
  isAbsolute = true,
  overrideClassName = 'secondary-btn',
  className,
  wrapperClassName,
  ...props
}) => {
  const { t } = useTranslation()

  const label = labelKey ? t(`actions.${labelKey}`) : void 0

  const btn = (
    <Button
      {...bindClassName('btn', [overrideClassName], [className])}
      label={label}
      {...props}
    />
  )

  const wrappedBtn = (
    <Condition condition={to}>
      <If>
        <Link to={to || ''}>{btn}</Link>
      </If>
      <Else>{btn}</Else>
    </Condition>
  )

  return (
    <Condition condition={isAbsolute}>
      <If>
        <PageActionsWrapper>{wrappedBtn}</PageActionsWrapper>
      </If>
      <Else>{wrappedBtn}</Else>
    </Condition>
  )
}

export default SecondaryButton
