import React from 'react'
import { GrWindows } from 'react-icons/gr'
import { FaLinux } from 'react-icons/fa'
import { ImAppleinc } from 'react-icons/im'

import PlatformDownload from './PlatformDownload'
import { DesktopPlatform } from '../../constants/desktop'
import { osDesktopPlatform } from '../../helpers/platform'

export const DesktopVersionTab = {
  Windows: 'windows',
  Linux: 'linux',
  MacOs: 'macos',
}
export const tabs = [
  {
    platforms: [DesktopPlatform.Windows32, DesktopPlatform.Windows64],
    header: 'windows',
    Component: PlatformDownload,
    icon: <GrWindows />,
    tabName: DesktopVersionTab.Windows,
  },
  {
    platforms: [DesktopPlatform.Linux],
    header: 'linux',
    Component: PlatformDownload,
    icon: <FaLinux />,
    tabName: DesktopVersionTab.Linux,
  },
  {
    platforms: [DesktopVersionTab.MacOs],
    header: 'mac_os',
    Component: PlatformDownload,
    icon: <ImAppleinc />,
    tabName: DesktopVersionTab.MacOs,
  },
]

export const mapPlatformToTabName = (platform = osDesktopPlatform) => {
  return tabs.find(({ platforms }) => platforms.includes(platform))?.tabName
}
