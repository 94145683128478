import React from 'react'

const Video = ({ mediaLink }) => {
  return (
    <iframe
      src={mediaLink}
      frameBorder="0"
      allow="accelerometer;
            autoplay;
            clipboard-write;
             encrypted-media;
             gyroscope;
             picture-in-picture"
      allowFullScreen
    />
  )
}

export default Video
