import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'

import { pipe } from '../../../../utils/function'
import CustomSwitch from '../../../common/CustomSwitch'

const TwoFaSwitch = ({
  enabledSms,
  setFieldValue,
  is2faAlreadyEnabled,
  generate2FA,
  removeQrCode,
  hasQrCode,
}) => {
  const { t } = useTranslation()

  const handleSwitch = useCallback(
    ({ value }) => {
      if (value) {
        if (enabledSms) setFieldValue('enabledSms', false)
        if (!is2faAlreadyEnabled) generate2FA()
      } else if (hasQrCode) {
        removeQrCode()
      }
    },
    [
      enabledSms,
      setFieldValue,
      is2faAlreadyEnabled,
      generate2FA,
      removeQrCode,
      hasQrCode,
    ],
  )

  return (
    <div className="p-col-12 p-md-6 two-factor-checkbox">
      <Field
        name="enabled2fa"
        labelText={t('two_factor')}
        component={CustomSwitch}
        onChange={handleSwitch}
      />
    </div>
  )
}

const withData = inject((stores) => ({
  generate2FA: stores.UsersStore.generate2FA,
  removeQrCode: stores.UsersStore.removeQrCode,
  hasQrCode: !!stores.UsersStore.qrCode,
}))

export default pipe(observer, withData)(TwoFaSwitch)
