import React from 'react'
import moment from 'moment'
import { inject, observer } from 'mobx-react'

import { useTranslation } from 'react-i18next'

const Header = ({ startDate, changeActiveItem }) => {
  const { t } = useTranslation()
  return (
    <div className="promotion-banner-header" onClick={changeActiveItem}>
      <i className="pi pi-times" />
      <span>
        <span className="mr-2"> {t('labels.start_date')}</span>
        {moment(startDate).format('DD/MM/YYYY HH:mm')}
      </span>
    </div>
  )
}
const withData = inject((stores) => ({
  changeActiveItem: stores.NotificationsStore.changeActiveItem,
}))

export default withData(observer(Header))
