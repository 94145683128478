import React from 'react'
import { inject } from 'mobx-react'

import './PromotionsPage.scss'
import Filter from './Filter'
import usePagination from '../../hooks/table/usePagination'
import PromotionItems from './PromotionItems'
import PromotionView from './PromotionView'

const PromotionsPage = ({ cleanUp, list }) => {
  const paginationProps = usePagination(cleanUp, list)
  return (
    <>
      <div className="p-fluid page-content-wrapper promotion-content-wrapper">
        <Filter {...paginationProps} className="active" />
        <PromotionItems />
        <PromotionView />
      </div>
    </>
  )
}

const withData = inject((stores) => ({
  cleanUp: stores.PromotionsStore.cleanUp,
}))

export default withData(PromotionsPage)
