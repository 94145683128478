import PublicProfiles from './PublicProfiles'
import LightEffect from './LightEffect'
import MyPublication from './MyPublications'

export const CommunityTab = {
  Public: 'public',
  LightEffect: 'light-effects',
  MyPublications: 'my-publications',
}

export const tabsConfig = [
  {
    header: 'public_profiles',
    Component: PublicProfiles,
    tabName: CommunityTab.Public,
  },
  {
    header: 'public_light_effect',
    Component: LightEffect,
    tabName: CommunityTab.LightEffect,
  },
  {
    header: 'my_publication',
    Component: MyPublication,
    tabName: CommunityTab.MyPublications,
  },
]
