import React from 'react'

import useError from '../../../../hooks/form/useError'
import ErrorBase, { createError } from '../../../messages/Error'

const Error = ({ errorMsg }) => {
  const error = useError(errorMsg)

  return <ErrorBase errorMsg={error} />
}

const AuthError = createError(Error, 'AuthStore')
export default AuthError
