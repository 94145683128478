import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Condition, { If, ElseIf, Else } from '../../../../hoc/Condition'

const SettingsItem = ({
  labelKey,
  icon: Icon,
  component: Component,
  render,
  onClick,
  to,
  close,
}) => {
  const { t } = useTranslation()

  const iconJsx = Icon && <Icon />
  const componentJsx = Component && <Component />

  const labelJsx = labelKey && (
    <span className="settings-item-label">
      {t(`settings.${labelKey}`, labelKey)}
    </span>
  )

  const inner = (
    <>
      {iconJsx}
      {componentJsx}
      {labelJsx}
    </>
  )

  if (to && !onClick) onClick = close

  return (
    <div className="settings-item" onClick={onClick}>
      <Condition condition={!!render}>
        <If>{render?.({ iconJsx, componentJsx, labelJsx })}</If>
        <ElseIf condition={to}>
          <Link to={to || ''}>{inner}</Link>
        </ElseIf>
        <Else>{inner}</Else>
      </Condition>
    </div>
  )
}

export default SettingsItem
