import React from 'react'

import Buttons, { Action } from '../../../Auth/OAuth/Buttons'
import Button from './Button'

const SocialButtons = ({ className = '' }) => (
  <Buttons
    className="p-col-12 p-md-6"
    action={Action.Link}
    component={Button}
    childrenClass={className}
  />
)

export default SocialButtons
