import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const useSelectOptions = (Enum) => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      Object.values(Enum).map((value) => {
        return {
          value,
          label: t(`labels.${value}`),
        }
      }),
    [Enum, t],
  )
}

export default useSelectOptions
