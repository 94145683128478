export const DEFAULT_LIMIT = 12

export const body = document.querySelector('body')

export const OAuth = {
  Google: 'google',
  Facebook: 'facebook',
}

export const rowsPerPageOptions = [DEFAULT_LIMIT, 100, 200]

export const NOTIFICATION_FAST_TIMEOUT = 750
export const NOTIFICATION_TIMEOUT = 3000

export const authHomeRoute = '/profile'
export const notAuthHomeRoute = '/'

export const ProfileDeviceActionType = {
  Delete: 'delete',
  Select: 'select',
}

export const ProfileTab = {
  Avatar: 'avatar',
  Personal: 'personal',
  Password: 'password',
  Security: 'security',
  NotificationSettings: 'notification-settings',
  Settings: 'settings',
}

export const documentationTab = {
  Cookies: 'cookie',
  PrivacyPolicy: 'privacy',
  Licence: 'licence',
  Documents: 'documents',
}

export const Order = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const tableOrderMapping = {
  1: Order.ASC,
  '-1': Order.DESC,
}
