import React from 'react'
import { Badge } from 'primereact/badge'
import { inject, observer } from 'mobx-react'
import { CgBell } from 'react-icons/cg'

import './Notification.scss'
import RenderIf from '../../hoc/RenderIf'
import { iconClassName } from '../NotificationModalWindow/NotificationItem/config'

const NotificationIcon = ({ notSeenCount, toggleModalOpen }) => {
  return (
    <div className={iconClassName}>
      <CgBell onClick={toggleModalOpen} />
      <RenderIf condition={notSeenCount}>
        <Badge value="" severity="danger" />
      </RenderIf>
    </div>
  )
}
const withData = inject((store) => ({
  toggleModalOpen: store.NotificationsStore.toggleModalOpen,
  notSeenCount: store.NotificationsStore.notSeenCount,
}))

export default withData(observer(NotificationIcon))
