import React, { useCallback } from 'react'
import { FiX } from 'react-icons/fi'

import { useDeviceProfileContext } from '../../../../../../context'
import ItemUI from '../ItemUI'

const DeletableItem = ({ profileDevice }) => {
  const { onItemClick } = useDeviceProfileContext()

  const { id: profileDeviceId } = profileDevice || {}

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation()
      onItemClick(profileDeviceId)
    },
    [profileDeviceId],
  )

  const actionUI = <FiX onClick={handleClick} />

  return <ItemUI actionUI={actionUI} profileDevice={profileDevice} />
}

export default DeletableItem
