import React from 'react'
import { observer } from 'mobx-react'

import './Configurations.scss'
import Empty from '../../../Empty'
import Condition, { Else, If } from '../../../hoc/Condition'
import { useDeviceProfileContext } from '../../../../context'

const Configurations = ({ activeDevice, setActiveDevice }) => {
  const { profile, ConfigurationItem } = useDeviceProfileContext()
  const devices = profile?.devices || []
  return (
    <Condition condition={devices.length}>
      <If>
        {devices.map((profileDevice) => (
          <ConfigurationItem
            profile={profile}
            key={profileDevice.id}
            profileDevice={profileDevice}
            activeDevice={activeDevice}
            setActiveDevice={setActiveDevice}
          />
        ))}
      </If>
      <Else>
        <Empty labelKey="no_profile_devices" />
      </Else>
    </Condition>
  )
}

export default observer(Configurations)
