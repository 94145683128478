import React from 'react'
import { inject, observer } from 'mobx-react'

import './Links.scss'
import { bindClassName } from '../../../utils/react'

const Links = ({ links, isLg, isDesktop }) => {
  if (!links?.length) return null

  const [{ to, text } = {}] = links || []

  return (
    <div
      {...bindClassName('promotion-item-link', [
        isLg,
        'promotion-item-link--lg',
      ])}
    >
      <a
        href={to}
        target={isDesktop ? '_self' : '_blank'}
        rel="noopener noreferrer"
      >
        {text}
      </a>
    </div>
  )
}

const withData = inject((stores) => ({
  isDesktop: stores.AppStore.isDesktop,
}))

export default withData(observer(Links))
