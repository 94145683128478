import api from '../configs/api'
import socketConfig from '../configs/socket'
import Message, { MessageType } from './Message'
import { bindAllMethods } from '../utils/function'
import Publisher from '../utils/Publisher'
import { ErrorMsg } from '../constants/errorMsg'
import { refreshTokenRequest } from '../helpers/http'
import stores from '../stores'

const { AuthStore, NotificationsStore } = stores

class Socket {
  constructor() {
    this.timeout = null
    this.isOpen = false
    this.isOpenning = false
    this.isDisconnected = false
    this.isRefreshingToken = false

    bindAllMethods(this)
    this.subscribe()
  }

  subscribe() {
    Publisher.subscribe(Publisher.Login, () => this.connect())
    Publisher.subscribe(Publisher.Logout, () => this.disconnect())
  }

  connect = (attempt) => {
    if (this.isOpen || this.isOpenning) return
    this.isDisconnected = false
    this.isOpenning = true

    this.clearTimeout()

    const reconnect = () => {
      this.isOpen = false
      if (!this.isDisconnected && !this.isRefreshingToken) {
        this.reconnect(++attempt)
      }
    }

    try {
      this.socket = new WebSocket(api.ws)

      this.socket.onopen = () => {
        attempt = 0
        this.isOpen = true
        this.isOpenning = false
      }

      this.socket.onmessage = ({ data }) => {
        if (typeof data !== 'string') return

        try {
          const { type, payload } = Message.from(data)
          const { result = {} } = payload || {}
          switch (type) {
            case MessageType.Error:
              return this.onError(payload)
            case MessageType.UserUpdate:
              return AuthStore.updateCurrentUserInfo(result)
            case MessageType.Notifications:
              return NotificationsStore.onNotifications(payload)
            case MessageType.NotificationUpdate:
              return NotificationsStore.onNotificationsUpdated(result)
            case MessageType.NotificationRemove:
              return NotificationsStore.onNotificationsDeleted(result)
          }
        } catch (e) {}
      }

      this.socket.onclose = reconnect
      this.socket.onerror = reconnect
    } catch (e) {
      reconnect()
    }
  }

  onError = async ({ message } = {}) => {
    if (message === ErrorMsg.INVALID_TOKEN) {
      this.isRefreshingToken = true
      await refreshTokenRequest()
      this.isRefreshingToken = false
      if (!this.isOpen) this.connect()
    }
  }

  reconnect = (attempt = 0) => {
    this.timeout = setTimeout(
      () => this.connect(attempt),
      socketConfig.reconnectTimeoutOnError + attempt * 1000,
    )
  }

  send = (message) => {
    if (this.socket.readyState !== this.socket.OPEN) return
    if (typeof message === 'string') message = new Message(message)

    return this.socket.send(message.toJSON())
  }

  disconnect = () => {
    this.clearTimeout()
    this.isOpen = false
    this.isOpenning = false
    this.isDisconnected = true
    if (
      ![this.socket.CLOSED, this.socket.CLOSING].includes(
        this.socket.readyState,
      )
    ) {
      this.socket.close()
    }
  }

  clearTimeout() {
    if (!this.timeout) return
    clearTimeout(this.timeout)
    this.timeout = null
  }
}

export default new Socket()
