import { createDeviseStatistic } from '../helper'
import { deviceCategory } from '../../../configs/devices'

export const useStatisticsEntity = (statistics) => {
  return statistics.reduce((acc, elem) => {
    const result = createDeviseStatistic(elem)
    const objValue = deviceCategory[result.category]
    acc[objValue] = [...(acc?.[objValue] || []), result]
    return acc
  }, {})
}
