import React from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import Auth from '../Auth'
import Form from './Form'
import { pipe } from '../../utils/function'

const ForgotPasswordPage = ({ enabledSms }) => {
  const { t } = useTranslation()
  const label = t(
    `labels.message.${enabledSms ? 'is_enabled_sms' : 'not_enabled_sms'}`,
  )

  return <Auth toLabel={label} to="/registration" form={Form} />
}
const withData = inject((stores) => ({
  enabledSms: stores.AuthStore.enabledSms,
}))

export default pipe(observer, withData)(ForgotPasswordPage)
