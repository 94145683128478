export default class Publisher {
  static channels = {}

  static Login = 'login'
  static Logout = 'logout'
  static SettingsUpdate = 'settings/update'

  static subscribe(channel, listener) {
    if (!this.channels[channel]) {
      this.channels[channel] = []
    }
    this.channels[channel].push(listener)

    return () => this.unsubscribe(channel, listener)
  }

  static unsubscribe(channel, listener) {
    const index = this.channels[channel].indexOf(listener)
    this.channels[channel].splice(index, 1)
    if (!this.channels[channel].length) {
      delete this.channels[channel]
    }
  }

  static publish(channel, ...args) {
    const currentChannel = this.channels[channel]
    if (!currentChannel || !currentChannel.length) return
    currentChannel.forEach((listener) => listener(...args))
  }
}
