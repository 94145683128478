import React from 'react'
import CustomBreadCrumb, { Model } from '../breadcrumb/CustomBreadCrumb'

const RulesPage = () => {
  return (
    <>
      <CustomBreadCrumb model={Model.RulesPage} />
      <div className="p-fluid page-content-wrapper">
        <span className="common-pages-title-info">
          Wishes for drawing up a formula Formalization of the publication of
          the declared idea is necessary for its identification, which will help
          to compare it with other ideas (hypotheses, concepts, techniques,
          etc.) to identify differences and novelty. The proposed formalization
          also contributes to a laconic and concentrated expression of the
          essence of the idea. Formalization is carried out in the form of a
          formula. For a better presentation of the essence of the idea in the
          formula, it is advisable to briefly state: currently known features,
          purpose, new unknown features or the essence of the idea. Signs here
          mean the elements that make up ideas. It is possible to use several
          types of formulas: Source:
          https://www.a-priority.ru/Priority/11Priority/PriorityPravila.html
        </span>
      </div>
    </>
  )
}

export default RulesPage
