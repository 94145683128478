import React from 'react'
import { inject, observer } from 'mobx-react'

import Header from '../ui/Header'
import Body from '../ui/Body'
import Error from '../../../../messages/Error'
import { ErrorMsg } from '../../../../../constants/errorMsg'

const Fallback = ({ errorMsg }) => (
  <>
    <Header />
    <Body>
      <Error errorMsg={errorMsg || ErrorMsg.PROMOTION_FAILED_TO_LOAD} />
    </Body>
  </>
)

const withData = inject((stores) => ({
  errorMsg: stores.PromotionsStore.errorMsg,
}))

export default withData(observer(Fallback))
