import React from 'react'
import { statisticTypeConfig } from '../config'
import { useTranslation } from 'react-i18next'
import CalendarBlock from '../CommonStatistics/DevicesFilter/CalendarBlock'

const TabsStatistic = ({
  statisticType,
  setStatisticType,
  dates,
  setDates,
}) => {
  const { t } = useTranslation()
  return (
    <div className="tabs-statistic-wrapper">
      {statisticTypeConfig.map((item) => {
        return (
          <div
            className={`${
              statisticType.name === item.name ? 'active' : ''
            } filter-item`}
            onClick={() => setStatisticType(item)}
            key={item.name}
          >
            {item.name}
            {/*{t(`devices.categories_name.${name}`)}*/}
          </div>
        )
      })}
      {statisticType.isCalendar && <CalendarBlock {...{ dates, setDates }} />}
    </div>
  )
}

export default TabsStatistic
