import { action, computed, observable } from 'mobx'
import api from '../../configs/api'
import Http from '../../helpers/http'
import { useStatisticsEntity } from './statisticsHook/useStatisticsEntity'
import UsersStore from '../Users/mobx'

const appResource = `${api.applications}/v1/applications`
const statisticResource = `${api.statistics}/v1/users/current/device-statistics`

export const buildAppImageUrl = (app) => {
  const { id, image } = app || {}
  return image ? `${appResource}/${id}/images/${image.filename}` : null
}

class StatisticsStore {
  @observable applicationsList = []
  @observable applicationActiveItem = null
  @observable statistics = []

  @computed get statisticsNormalised() {
    return useStatisticsEntity(this.statistics)
  }

  @action
  getAllApplications = async () => {
    this.applicationsList = await Http.get(appResource)
  }
  @action
  getAllStatistic = async () => {
    const res = await Http.get(statisticResource)
    this.statistics = res.result
  }

  @action
  cleanUp = async () => {
    this.applicationsList = []
  }
}

export default new StatisticsStore()
