import React from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { ConfirmDialog } from 'primereact/confirmdialog'

const CustomConfirmDialog = ({ dialogProps, accept, reject }) => {
  const { t } = useTranslation()

  const { message, header } = dialogProps || {}

  return (
    <ConfirmDialog
      className="delete-confirm-dialog"
      visible={!!dialogProps}
      message={message && t(`confirm.${message}`)}
      header={header && t(`confirm.${header}`)}
      icon="pi pi-exclamation-triangle"
      accept={accept}
      onHide={reject}
      reject={reject}
      acceptLabel={t('actions.yes')}
      rejectLabel={t('actions.no')}
      acceptClassName="common-btn danger-tertiary-button"
      rejectClassName="p-button-secondary"
    />
  )
}

const withData = inject((stores) => ({
  dialogProps: stores.ConfirmDialogStore.dialogProps,
  accept: stores.ConfirmDialogStore.accept,
  reject: stores.ConfirmDialogStore.reject,
}))

export default withData(observer(CustomConfirmDialog))
