import React from 'react'
import { inject, observer } from 'mobx-react'
import { Dialog } from 'primereact/dialog'
import { useTranslation } from 'react-i18next'

const InfoDialog = ({ messageKey, close }) => {
  const { t } = useTranslation()

  return (
    <Dialog
      className="popup-width"
      visible={!!messageKey}
      onHide={close}
      appendTo={document.body}
      blockScroll
      modal
    >
      <span>{t(`info_dialog.${messageKey}`)}</span>
    </Dialog>
  )
}

const withData = inject((stores) => ({
  messageKey: stores.InfoDialogStore.messageKey,
  close: stores.InfoDialogStore.close,
}))

export default withData(observer(InfoDialog))
