import React, { useCallback, useState } from 'react'
import { Field } from 'formik'
import * as yup from 'yup'

import CustomInput from '../../../common/CustomInput'
import { ErrorMsg } from '../../../../constants/errorMsg'
import {
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
  PASSWORD_REGEXP,
} from '../../../../constants/user'

export const passwordShape = {
  password: yup
    .string()
    .trim()
    .required(ErrorMsg.PASSWORD_REQUIRED)
    .min(MIN_PASSWORD_LENGTH, ErrorMsg.PASSWORD_IS_SHORT)
    .max(MAX_PASSWORD_LENGTH, ErrorMsg.PASSWORD_IS_SHORT)
    .matches(PASSWORD_REGEXP, ErrorMsg.PASSWORD_NOT_VALID),
}

export const confirmPasswordShape = {
  confirmPassword: yup
    .string()
    .required(ErrorMsg.CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref('password')], ErrorMsg.PASSWORD_NOT_MATCH),
}

const Password = ({
  name = 'password',
  labelText = 'password',
  isRequired = true,
  ...props
}) => {
  const [isShowPassword, setShowStatus] = useState(true)
  const icon = isShowPassword ? 'pi-eye' : 'pi-eye-slash'
  const type = isShowPassword ? 'password' : 'text'
  const setShowStatusAction = useCallback(() => {
    setShowStatus(!isShowPassword)
  }, [setShowStatus, isShowPassword])

  return (
    <div className="field-wrapper relative password-custom-input p-my-2 p-col-12">
      <Field
        type={type}
        name={name}
        placeholder={labelText}
        isRequired
        component={CustomInput}
        {...props}
      />
      <i className={`pi ${icon}`} onClick={setShowStatusAction} />
    </div>
  )
}

export default Password
