import React, { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import AuthForm, { confirmPasswordShape, passwordShape } from '../Auth/Form'

const validationSchema = yup
  .object()
  .shape({ ...passwordShape, ...confirmPasswordShape })

const Form = ({ changePassword }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { token } = useParams()
  const values = useMemo(() => ({ password: '', confirmPassword: '', token }), [
    token,
  ])

  const onSubmit = useCallback((data) => changePassword(data, history), [
    history,
    changePassword,
  ])

  return (
    <AuthForm
      validationSchema={validationSchema}
      values={values}
      onSubmit={onSubmit}
      submitLabel={t('actions.change_password')}
    >
      <AuthForm.Password />
      <AuthForm.Password name="confirmPassword" labelText="repeat_password" />
    </AuthForm>
  )
}

const withData = inject((stores) => ({
  changePassword: stores.AuthStore.changePassword,
}))

export default withData(Form)
