import { documentationTab } from '../../../constants'
import CookieContent from '../../Agreement/Modal/PolicyContent/CookieContent'
import Licence from '../../Agreement/Modal/PolicyContent/Licence'
import PrivacyContent from '../../Agreement/Modal/PolicyContent/PrivacyContent'
import Instructions from '../Instructions'

export const documentationConfig = [
  {
    title: 'cookies',
    Component: CookieContent,
    item: documentationTab.Cookies,
    version: '2.01.1',
    date: '12.02.2022',
  },
  {
    title: 'privacy_policy',
    Component: PrivacyContent,
    item: documentationTab.PrivacyPolicy,
    version: '1.0.1',
    date: '04.04.2022',
  },
  {
    title: 'terms_and',
    Component: Licence,
    item: documentationTab.Licence,
    version: '1.0.02',
    date: '05.04.2022',
  },
  {
    title: 'documents',
    Component: Instructions,
    item: documentationTab.Documents,
    version: '1.12.03',
    date: '23.03.2022',
  },
]
