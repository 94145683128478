import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import BannerBody from '../BannerBody'
import RenderIf from '../../../hoc/RenderIf'

const BannerWrapper = ({
  activeBannerId,
  setActiveBannerId,
  notSeenPromotionsList,
  nextActiveItem,
}) => {
  useEffect(() => {
    setActiveBannerId(nextActiveItem)
  }, [notSeenPromotionsList])

  const banner = notSeenPromotionsList.find(
    (item) => item?.PromotionId === activeBannerId,
  )

  return (
    <RenderIf condition={banner}>
      <BannerBody banner={banner} />
    </RenderIf>
  )
}

const withData = inject((stores) => ({
  activeBannerId: stores.NotificationsStore.activeBannerId,
  setActiveBannerId: stores.NotificationsStore.setActiveBannerId,
  notSeenPromotionsList: stores.NotificationsStore.notSeenPromotionsList,
  nextActiveItem: stores.NotificationsStore.nextActiveItem,
}))

export default withData(observer(BannerWrapper))
