import { action, observable, computed } from 'mobx'

import api from '../../configs/api'
import Http, { buildQuery } from '../../helpers/http'
import { findInCollection } from '../../utils/array'
import { SHOW_LAST_VERSIONS_COUNT } from '../../constants/desktop'

class DesktopApplicationStore {
  resource = `${api.desktop}/v1/desktop/files`

  @observable isLoading = false
  @observable list = []
  @observable count = 0
  @observable errorMsg = null
  @observable item = null
  @observable isOpenModal = false

  getDownloadLink = (id) => {
    return `${this.resource}/${id}/download`
  }

  @computed get availableList() {
    return this.sortedList.reduce((acc, curr) => {
      const { platform, version, id, createdAt } = curr

      let existingItem = findInCollection('platform', acc, platform)

      if (!existingItem) {
        existingItem = { platform, versions: [] }
        acc.push(existingItem)
      }

      if (existingItem.versions.length < SHOW_LAST_VERSIONS_COUNT) {
        existingItem.versions.push({ version, id, createdAt })
      }

      return acc
    }, [])
  }
  @computed get sortedList() {
    return this.list.sort((a, b) =>
      b.version.localeCompare(a.version, void 0, { numeric: true }),
    )
  }

  @action
  getAll = async (fields) => {
    this.isLoading = true
    const query = buildQuery(fields)
    const { message = null, result = [], count = 0 } = await Http.get(
      `${this.resource}${query}`,
    )
    this.errorMsg = message
    this.list = result
    this.count = count
    this.isLoading = false
  }

  @action
  toggleModal = () => {
    this.isOpenModal = !this.isOpenModal
  }
  @action
  cleanUp = () => {
    this.isLoading = false
    this.list = []
    this.count = 0
    this.errorMsg = null
    this.item = null
    this.isOpenModal = false
  }
}

export default new DesktopApplicationStore()
