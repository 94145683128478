import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import ImgPreview from '../../../ImgPreview'
import Condition, { Else, If } from '../../../hoc/Condition'
import { USER_AVATAR_LIMIT_BYTES } from '../../../../constants/user'
import { ErrorMsg } from '../../../../constants/errorMsg'
import defaultAvatar from '../../../../assets/images/avatar.png'

const rootProps = { className: 'dropzone' }

const Avatar = ({
  avatarUrl,
  setAvatar,
  setAvatarError,
  toggleCropperModal,
}) => {
  const { t } = useTranslation()

  const onDrop = useCallback(
    ([file]) => {
      if (file.size > USER_AVATAR_LIMIT_BYTES) {
        return setAvatarError(ErrorMsg.EXCEEDED_FILE_LIMIT)
      }

      setAvatarError(null)
      setAvatar(file)
      toggleCropperModal()
    },
    [setAvatar, setAvatarError, toggleCropperModal],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop,
    multiple: false,
  })

  const msg = t(`avatar.${isDragActive ? 'drop_here' : 'drag_n_drop'}`)
  return (
    <div className="">
      <div className="avatar-wrapper">
        <div {...getRootProps(rootProps)}>
          <input {...getInputProps()} />
          <Condition condition={isDragActive || !avatarUrl}>
            <If>
              <ImgPreview className="user-avatar-profile" src={defaultAvatar} />
            </If>
            <Else>
              <ImgPreview className="user-avatar-profile" src={avatarUrl} />
            </Else>
          </Condition>
        </div>
      </div>
    </div>
  )
}

const withData = inject((stores) => ({
  setAvatarError: stores.UsersStore.setAvatarError,
  avatarUrl: stores.ProfileStore.avatarUrl,
  setAvatar: stores.ProfileStore.setAvatar,
}))

export default withData(observer(Avatar))
