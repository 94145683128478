import React, { useCallback } from 'react'
import './DevicesSelectBtn.scss'
import { deviceCategory } from '../../../../../configs/devices'
import { useTranslation } from 'react-i18next'

const DevicesSelectBtn = ({ item, setDevice, activeDevice, icon }) => {
  const { t } = useTranslation()
  const isActive = activeDevice.id === item?.Device.id

  const setActive = useCallback(() => {
    setDevice(item?.Device)
  }, [item, setDevice, activeDevice])

  return (
    <div
      className={`${
        isActive ? 'active' : ''
      } p-button p-component secondary-btn device-category`}
      onClick={setActive}
    >
      {icon && <i className={`pi pi-${icon} p-mr-2`} />}
      {t(
        `devices.categories_name.${
          deviceCategory[item?.Device?.DeviceCategory?.name]
        }`,
      )}
    </div>
  )
}

export default DevicesSelectBtn
