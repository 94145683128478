import React, { useCallback } from 'react'
import { bindClassName } from '../../../../../utils/react'

const CountryItem = ({
  isSelected,
  toggleSelectOpen,
  setCountryInfo,
  info,
}) => {
  const { isoCode, name, phoneCode } = info

  const handleClick = useCallback(() => {
    if (!isSelected) setCountryInfo(info)
    toggleSelectOpen()
  }, [isSelected, toggleSelectOpen, setCountryInfo])

  return (
    <li
      {...bindClassName('countries-select__item', [isSelected, 'selected'])}
      onClick={handleClick}
    >
      <div {...bindClassName('flag', [isoCode])} />
      <span className="country-name">{name}</span>
      <span className="countrycode">+{phoneCode}</span>
    </li>
  )
}

export default React.memo(CountryItem)
