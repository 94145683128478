import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Error from '../../components/messages/Error'
import useSearchParams from '../useSearchParams'

const useError = (errorMsg) => {
  const { t } = useTranslation()

  const { errorMsg: errorFromQuery, provider } = useSearchParams()

  return useMemo(() => {
    const error = errorMsg || errorFromQuery

    return error ? t(`errors.${error}`, error, { provider }) : null
  }, [errorMsg, errorFromQuery, provider])
}

export const useFormError = (form, field) => {
  if (!form || !field) return {}

  const errorMsg = form.touched[field.name] && form.errors[field.name]

  const error = <Error errorMsg={errorMsg} />

  return { error, errorMsg, showError: !!errorMsg }
}

export default useError
