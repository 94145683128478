import React, { useCallback } from 'react'
import { useField } from 'formik'
import { Checkbox } from 'primereact/checkbox'

import ItemUI from '../ItemUI'

const SelectableItem = ({
  profile,
  profileDevice,
  activeDevice,
  setActiveDevice,
}) => {
  const [{ value: ids }, , { setValue: setIds }] = useField('ids')
  const currId = profileDevice.id
  const checked = ids.includes(currId)

  const toggleSelected = useCallback(() => {
    let newIds
    if (checked) {
      newIds = ids.filter((id) => id != currId)
    } else {
      newIds = [...ids, currId]
    }
    setIds(newIds)
  }, [setIds, ids, checked, currId])

  const actionUI = <Checkbox className="select-device" checked={checked} />

  return (
    <ItemUI
      ids={ids}
      profile={profile}
      setActiveDevice={setActiveDevice}
      activeDevice={activeDevice}
      actionUI={actionUI}
      onClick={toggleSelected}
      profileDevice={profileDevice}
    />
  )
}

export default SelectableItem
