import React from 'react'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

const ButtonSubmit = ({
  withWrapper = true,
  submitLabelKey = 'save',
  dirty,
  isLoading,
}) => {
  const { t } = useTranslation()

  const button = (
    <Button
      type="submit"
      label={t(`actions.${submitLabelKey}`)}
      disabled={!(dirty && !isLoading)}
      className="common-button  btn-padding"
    />
  )

  if (!withWrapper) return button

  return <div className="p-col-12 p-my-2">{button}</div>
}

export default ButtonSubmit
