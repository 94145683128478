import React, { useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useDeviceProfileContext } from '../../../../../../context'
import { bindClassName } from '../../../../../../utils/react'
import detectDefaultDeviceImage from '../../../../../../helpers/detectDefaultDeviceImage'
import api from '../../../../../../configs/api'
import DevicesSelectBtn from '../../../../../CommunityPage/CommunityItem/DevicesBlock/DevicesSelectBtn'

const ItemUI = ({
  actionUI,
  profile,
  profileDevice,
  ids,
  activeDevice,
  onClick,
  setActiveDevice,
}) => {
  const { itemActionType } = useDeviceProfileContext()
  const { t } = useTranslation()

  const { DeviceCategory, id, images, model } = profileDevice?.Device || {}

  const altImg = useMemo(() => detectDefaultDeviceImage(DeviceCategory.name), [
    DeviceCategory,
  ])

  const url = useMemo(() => {
    const filename = !!images && images.find((el) => el?.filename)?.filename
    return !!filename
      ? `${api.devices}/v1/devices/${id}/images/${filename}`
      : altImg?.filename
  }, [activeDevice])

  return (
    <div
      {...bindClassName(
        'device-config-item',
        [itemActionType],
        [activeDevice?.id === id, 'active'],
      )}
    >
      <div className="device-config-item__footer">
        {t('labels.model')} {model}
      </div>
      <div className="device-config-item__header">
        <div {...bindClassName('item-action', [itemActionType])}>
          <span onClick={onClick}>{actionUI}</span>{' '}
          <span>{t('labels.select_device')}</span>
        </div>
      </div>
      <div className="device-config-item__body">
        <img src={url} />
      </div>
      <div className="community-devise-btn-block">
        {activeDevice &&
          profile?.devices.map((item) => (
            <DevicesSelectBtn
              icon={ids.includes(item.id) ? 'check' : 'times'}
              setDevice={setActiveDevice}
              activeDevice={activeDevice}
              key={item.id}
              item={item}
            />
          ))}
      </div>
    </div>
  )
}

const withData = inject((stores) => ({
  deviceResource: stores.DevicesStore.resource,
}))

export default withData(observer(ItemUI))
