import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'

import useSearchParams from '../../../../hooks/useSearchParams'
import { buildQuery } from '../../../../helpers/http'
import usePrevious from '../../../../hooks/usePrevious'

const Tracker = ({ promotionViewId, setPromotionViewId }) => {
  const history = useHistory()
  const { pathname } = history.location

  const { id, ...params } = useSearchParams()

  const prev = usePrevious({ id, promotionViewId })

  useEffect(() => {
    const push = (data = {}) => {
      const query = buildQuery({ ...params, ...data })
      history.push(`${pathname}${query}`)
    }

    if (id && !promotionViewId && !prev.promotionViewId) {
      setPromotionViewId(+id)
    }

    if (promotionViewId && id != promotionViewId) {
      push({ id: promotionViewId })
    }

    if (id && !promotionViewId && prev.promotionViewId) {
      push()
    }
  }, [id, promotionViewId, history, pathname])

  return null
}

const withData = inject((stores) => ({
  promotionViewId: stores.PromotionsStore.promotionViewId,
  setPromotionViewId: stores.PromotionsStore.setPromotionViewId,
}))

export default withData(observer(Tracker))
