import React from 'react'

const BarItem = () => {
  return (
    <div className="common-bar-wrapper">
      <div className="quantity-call-info">
        <div className="quantity-call-info__elem">
          <div className="quantity-call-info__elem-value ">
            <span className="primary-meaning  " title="За выбранный период">
              <i className="fas fa-circle all-call"></i> 2312
            </span>
            <span
              className="percentage-change up"
              title="Улучшения показателя по сравнению с предидущим периодом"
            >
              - 6.8%
            </span>
            <span
              className="quantity-call-info__label"
              title="За предидущий период"
            >
              2512
            </span>
            <div className="trend-change__value-down">
              <svg
                className="down"
                fill="none"
                height="29"
                viewBox="0 0 24 29"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.9753 13.3998L21.6303 23.3084L11.7217 25.9634M21.5857 23.3421L9.3 16.249L12.1894 11.3336L2.39045 5.67672"
                  stroke="#FD8181"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                />
              </svg>
            </div>
          </div>
          <p className="quantity-call-info__label all-call">'Left clicks'</p>
        </div>
        <div className="quantity-call-info__elem">
          <div className="quantity-call-info__elem-value ">
            <span className="primary-meaning  " title="За выбранный период">
              <i className="fas fa-circle all-call"></i> 245{' '}
            </span>
            <span
              className="percentage-change up"
              title="Улучшения показателя по сравнению с предидущим периодом"
            >
              + 6.8%
            </span>
            <span
              className="quantity-call-info__label"
              title="За предидущий период"
            >
              267{' '}
            </span>
            <div className="trend-change__value-up">
              <svg
                className="up"
                fill="none"
                height="19"
                viewBox="0 0 26 19"
                width="26"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.7497 2.49219L24.0079 2.49219V12.7504M24.0289 2.544L13.9976 12.5752L9.99761 8.51211L1.99731 16.513"
                  stroke="#6DD453"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                />
              </svg>
            </div>
          </div>
          <p className="quantity-call-info__label all-call">Right clicks</p>
        </div>
        <div className="quantity-call-info__elem">
          <div className="quantity-call-info__elem-value ">
            <span className="primary-meaning  " title="За выбранный период">
              <i className="fas fa-circle all-call"></i> 12
            </span>
            <span
              className="percentage-change up"
              title="Улучшения показателя по сравнению с предидущим периодом"
            >
              + 13.8%
            </span>
            <span
              className="quantity-call-info__label"
              title="За предидущий период"
            >
              22
            </span>
            <div className="trend-change__value-up">
              <svg
                className="up"
                fill="none"
                height="19"
                viewBox="0 0 26 19"
                width="26"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.7497 2.49219L24.0079 2.49219V12.7504M24.0289 2.544L13.9976 12.5752L9.99761 8.51211L1.99731 16.513"
                  stroke="#6DD453"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                />
              </svg>
            </div>
          </div>
          <p className="quantity-call-info__label all-call">Middle clicks</p>
        </div>
        <div className="quantity-call-info__elem d-flex flex-column justify-content-start align-items-start">
          <div className="quantity-call-info__elem-value d-flex flex-nowrap justify-content-around align-items-center ">
            <span className="primary-meaning  " title="За выбранный период">
              <i className="fas fa-circle miss-call "></i>25
            </span>
            <span
              className="percentage-change down"
              title="Ухудшение показателя по сравнению с предидущим периодом"
            >
              - 1.4%
            </span>
            <span
              className="quantity-call-info__label"
              title="За предидущий период"
            >
              28
            </span>
            <div className="trend-change__value-down">
              <svg
                className="down"
                fill="none"
                height="29"
                viewBox="0 0 24 29"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.9753 13.3998L21.6303 23.3084L11.7217 25.9634M21.5857 23.3421L9.3 16.249L12.1894 11.3336L2.39045 5.67672"
                  stroke="#FD8181"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                />
              </svg>
            </div>
          </div>
          <p className="quantity-call-info__label miss-call">Mouse 4/5</p>
        </div>
      </div>
    </div>
  )
}

export default BarItem
