import React from 'react'
import ItemLink from '../ItemLink'
import { NotificationType } from '../../../../../constants/system-notifications'
import DownLoad from '../DownLoad'

const ActionItem = ({ notification, toggleModalOpen, to, type }) => {
  const Action = type === NotificationType.SystemUpdate ? DownLoad : ItemLink
  const label = type === NotificationType.SystemUpdate ? 'download' : 'view'

  return (
    <Action
      to={to}
      label={label}
      toggleModalOpen={toggleModalOpen}
      PromotionId={notification?.PromotionId}
      notification={notification}
    />
  )
}

export default ActionItem
