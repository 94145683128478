import countriesData from '../../../configs/countriesData'

export const processMask = (value, mask) => {
  let process = ''

  if (value) {
    let pointer = 0

    for (let entity of mask.split('')) {
      const char = value[pointer]
      if (!char) break
      if (entity !== '_') {
        process += entity
        if (char === entity) pointer++
      } else if (/\d/.test(char)) {
        process += char
        pointer++
      } else {
        break
      }
    }
  }

  return process
}

export const DEFAULT_COUNTRY_ISO = 'ua'

export const find = (isoCode) =>
  countriesData.find(
    (item) => item.isoCode.toLowerCase() === isoCode.toLowerCase(),
  )

export const detectCountry = (value) =>
  countriesData
    .filter(({ phoneCode }) => value.startsWith(phoneCode))
    .sort((a, b) => b.length - a.length)[0]

export const toRawNumber = (value) => value.replace(/[^\d]/g, '')
