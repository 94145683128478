import React from 'react'
import { Field } from 'formik'
import * as yup from 'yup'

import CustomInput from '../../../common/CustomInput'
import { ErrorMsg } from '../../../../constants/errorMsg'

export const emailShape = {
  email: yup
    .string()
    .trim()
    .email(ErrorMsg.EMAIL_NOT_VALID)
    .required(ErrorMsg.EMAIL_REQUIRED),
}

const Email = () => {
  return (
    <div className="field-wrapper relative mail-custom-input p-my-2 p-col-12">
      <Field
        isRequired
        type="text"
        name="email"
        placeholder="email"
        component={CustomInput}
      />
      <i className="pi pi-envelope" />
    </div>
  )
}

export default Email
