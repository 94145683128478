import React from 'react'
import { useTranslation } from 'react-i18next'

import { Action } from '../Auth'

import AuthHeadLine from '../Auth/AuthHeadLine'
import SignHeadLine from '../Auth/Form/SignHeadLine'
import LanguageSelect from '../Language/LanguageSelect'
import { Checkbox } from 'primereact/checkbox'
import { Button } from 'primereact/button'
import Modal from './Modal'
import RegistryMsg from './RegistryMsg'
import useAgreement from './hooks/useAgreement'
import Loadable from '../hoc/Loadable'

const Agreement = ({ withHeadline = true, onAgree, isLoading, errorMsg }) => {
  const { t } = useTranslation()
  const {
    isMsg,
    policyAction,
    toggleModal,
    toggleAccess,
    toggleLicence,
    toggleAccept,
    toggleAgreement,
    agree,
    isPrivacyPolicy,
    isLicence,
    isCookie,
    isAgreement,
    isModal,
  } = useAgreement({ onAgree })

  return (
    <>
      <div className="p-fluid auth-page-wrapper">
        <Loadable loading={isLoading}>
          <AuthHeadLine oauthAction={Action.Access} />
          <div className="sign-card relative">
            <div className="sign-card-checkbox-block">
              <div className="checkbox-wrapper">
                <label className="p-checkbox-label">
                  <Checkbox
                    name="privacy"
                    checked={isPrivacyPolicy}
                    onChange={toggleAccess}
                    className="mr-2"
                  />
                  {t('actions.privacy_policy')}
                </label>
                <button
                  onClick={() => toggleModal('privacy')}
                  className="view-terms"
                >
                  {t('actions.terms')}
                </button>
              </div>
              <div className="checkbox-wrapper">
                <label className="p-checkbox-label">
                  <Checkbox
                    name="licence"
                    checked={isLicence}
                    onChange={toggleLicence}
                    className="mr-2"
                  />
                  {t('actions.licence')}
                </label>
                <button
                  onClick={() => toggleModal('licence')}
                  className="view-terms"
                >
                  {t('actions.terms')}
                </button>
              </div>
              <div className="checkbox-wrapper last-agree">
                <label className="p-checkbox-label">
                  <Checkbox
                    name="cookie"
                    checked={isCookie}
                    onChange={toggleAccept}
                    className="mr-2"
                  />
                  {t('actions.cookie')}
                </label>
                <button
                  onClick={() => toggleModal('cookie')}
                  className="view-terms"
                >
                  {t('actions.terms')}
                </button>
              </div>
              <div className="checkbox-wrapper">
                <label className="p-checkbox-label">
                  <Checkbox
                    name="accept"
                    checked={isAgreement}
                    onChange={toggleAgreement}
                    className="mr-2"
                  />
                  {t('actions.have_accept')}
                </label>
              </div>
              <Button
                className="common-button submit-sigh-btn w-100"
                onClick={agree}
                label={t(`actions.accept`)}
              />
            </div>
            {(isMsg || errorMsg) && <RegistryMsg errorMsg={errorMsg} />}
            {withHeadline && <SignHeadLine oauthAction={Action.SignUp} />}
          </div>
        </Loadable>
      </div>
      <Modal
        isActive={isModal}
        toggleModal={toggleModal}
        policyAction={policyAction}
      />
      <LanguageSelect className="not-auth-lng" />
    </>
  )
}

export default Agreement
