import React from 'react'
import { useTranslation } from 'react-i18next'

const CookieContent = ({ policyAction }) => {
  const { t } = useTranslation()
  return (
    <div className="modal-body-content">
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.1`)}
      </p>
      <h3> {t(`confidentiality.${policyAction}.headline.1`)}</h3>
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.2`)}
      </p>
      <h3> {t(`confidentiality.${policyAction}.headline.2`)}</h3>
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.3`)}
      </p>
      <h3> {t(`confidentiality.${policyAction}.headline.3`)}</h3>
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.4`)}
      </p>
      <h3> {t(`confidentiality.${policyAction}.headline.4`)}</h3>
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.5`)}
      </p>
      <h3> {t(`confidentiality.${policyAction}.headline.5`)}</h3>
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.6`)}
      </p>
      <h3> {t(`confidentiality.${policyAction}.headline.6`)}</h3>
      <p className="common-text">
        {t(`confidentiality.${policyAction}.text.7`)}
      </p>
    </div>
  )
}

export default CookieContent
