import { NotificationType } from '../../../../constants/system-notifications'
import { desktopPlatformLabelsMapping } from '../../../../constants/desktop'
import { mapPlatformToTabName } from '../../../DesktopVersionPage/config'

const getDeviceLink = ({ DeviceId }) => `/catalog/device/${DeviceId}`

const getDeviceInfo = ({ Device }) => {
  if (!Device) return ''
  const { DeviceCategory, model } = Device

  return `${DeviceCategory?.name} ${model}`
}

export const config = {
  [NotificationType.NewDevices]: {
    getLink: getDeviceLink,
    getInfo: getDeviceInfo,
  },
  [NotificationType.UsingDeviceInstructionUpdate]: {
    getLink: getDeviceLink,
    getInfo: getDeviceInfo,
  },
  [NotificationType.SystemUpdate]: {
    getLink: ({ platform }) =>
      `/desktop-version?tab=${mapPlatformToTabName(platform)}`,
    getInfo: ({ payload }) => {
      const { platform, version } = payload
      return `${desktopPlatformLabelsMapping[platform]} (v: ${version})`
    },
  },
  [NotificationType.Promotions]: {
    getLink: () => '/promotions',
  },
}

export const iconClassName = 'notification-icon-wrapper'
