import React from 'react'
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom'
import { buildQuery } from '../../../../helpers/http'
import { useTranslation } from 'react-i18next'

const ReportButton = ({ name }) => {
  const { t } = useTranslation()
  return (
    <Link
      to={`/contact${buildQuery({ subject: 'report_rejected', name })}`}
      className="profile-download-link"
    >
      <Button label={t(`actions.report`)} className="danger--button" />
    </Link>
  )
}

export default ReportButton
