import React, { useMemo } from 'react'

import CustomSelect from '../CustomSelect'
import countriesData from '../../../configs/countriesData'
import useLabel from '../../../hooks/form/useLabel'

const mapped = countriesData.map(({ name, country }) => ({
  label: name,
  value: country,
}))

const CountriesSelect = (props) => {
  const { labelText } = useLabel({ labelKey: 'not_specified' })

  const options = useMemo(
    () => [{ label: labelText, value: '' }, ...mapped],
    [labelText],
  )

  return (
    <CustomSelect
      filter
      filterBy="label"
      resetFilterOnHide
      options={options}
      showClear={false}
      {...props}
    />
  )
}

export default CountriesSelect
