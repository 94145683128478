import React, { useMemo } from 'react'
import { Field, useField } from 'formik'
import moment from 'moment'

import CustomCalendarInput from '../../../common/CustomCalendarInput'
import CustomCheckbox from '../../../common/CustomCheckbox'
import RenderIf from '../../../hoc/RenderIf'

const PublishFormEntities = () => {
  const minDate = useMemo(() => moment().add(1, 'days').toDate(), [])

  const [{ value: isLightingEffectsOnly }] = useField('isLightingEffectsOnly')

  return (
    <div className="p-fluid p-grid p-col-12">
      <div className="p-col-12">
        <Field
          name="activeTo"
          labelText="active_to"
          minDate={minDate}
          showButtonBar={false}
          component={CustomCalendarInput}
        />
      </div>
      <div className="p-d-flex p-align-center p-col-12 p-md-6 p-mt-4">
        <Field
          name="isLightingEffectsOnly"
          labelText="is_lightning_effects_only"
          component={CustomCheckbox}
        />
      </div>
      <RenderIf condition={!isLightingEffectsOnly}>
        <div className="p-d-flex p-align-center p-col-12 p-md-6 p-mt-4">
          <Field
            name="withMacros"
            labelText="with_macros"
            component={CustomCheckbox}
          />
        </div>
      </RenderIf>
    </div>
  )
}

export default PublishFormEntities
