import React from 'react'

import PromotionsBadge from './PromotionsBadge'

const config = [
  {
    labelKey: 'promotions',
    url: '/promotions',
    isPrivate: true,
    needVerify: true,
    renderBeforeLink: () => <PromotionsBadge />,
  },
  {
    labelKey: 'device_profiles',
    url: '/device-profiles',
    isPrivate: true,
    needVerify: true,
  },
  {
    labelKey: 'community',
    url: '/community',
    isPrivate: true,
    needVerify: true,
  },
  {
    labelKey: 'device_catalog',
    url: '/catalog',
    isPrivate: true,
    needVerify: true,
  },

  {
    labelKey: 'contact',
    url: '/contact',
    isPrivate: true,
    needVerify: true,
  },
]

export default config
