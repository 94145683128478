import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner'
import { useTranslation } from 'react-i18next'

import './index.scss'
import { bindClassName } from '../../../utils/react'
import RenderIf from '../../hoc/RenderIf'

const Spinner = ({ className, labelKey }) => {
  const { t } = useTranslation()

  const label = labelKey && t(`spinner.${labelKey}`)

  return (
    <div
      {...bindClassName(
        'spinner-wrapper my-5 p-col-12 p-grid-col p-justify-center p-align-center',
        [className],
      )}
    >
      <ProgressSpinner className="p-col-12" />
      <RenderIf condition={label}>
        <p className="p-col-12 spinner-text">{label}</p>
      </RenderIf>
    </div>
  )
}

export default Spinner
