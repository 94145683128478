import React, { useEffect, useState } from 'react'
import DailyStatisticsContent from './DailyStatisticsContent'
import './DailyStatistics.scss'
import DeviceBlock from '../../CommonStatistics/DevicesFilter/DeviceBlock'
import { inject } from 'mobx-react'
import StatisticsBar from './DailyStatisticsContent/StatisticsBar'
import StatisticModal from '../../StatisticModal'

const DailyStatistics = ({
  devicesFilter,
  setDevicesFilter,
  deviceName,
  setDeviceName,
  statisticsNormalised,
  statistics,
}) => {
  const [activeDate, setActiveDate] = useState(false)
  return (
    <div className="statistic-content-wrapper">
      <StatisticModal
        {...{ activeDate, setActiveDate, deviceName, devicesFilter }}
      />
      <div className="devices-filter-block">
        {!!statistics?.length && (
          <DeviceBlock
            {...{
              devicesFilter,
              deviceName,
              setDeviceName,
              setDevicesFilter,
              statisticsNormalised,
            }}
          />
        )}
        <StatisticsBar />
      </div>
      <DailyStatisticsContent {...{ activeDate, setActiveDate }} />
    </div>
  )
}
const withData = inject((stores) => ({
  statistics: stores.StatisticsStore.statistics,
  statisticsNormalised: stores.StatisticsStore.statisticsNormalised,
}))

export default withData(DailyStatistics)
