import React, { useEffect } from 'react'
import { inject } from 'mobx-react'

import ActionBody from './ActionBody'
import { useCommonModalContext } from '../../../../../context'
import ShareItem from './ShareItem'

const ModalContent = ({
  list,
  getAll,
  toggleOpen,
  cleanUp,
  getDeviceCategories,
  deviceCategoriesCleanUp,
  icon,
}) => {
  useEffect(() => {
    getAll()
    getDeviceCategories()
    return deviceCategoriesCleanUp
  }, [])

  const { isUIOpened } = useCommonModalContext()
  useEffect(() => cleanUp, [])

  if (!isUIOpened) return null
  const shareList = list.map((item) => (
    <ShareItem
      key={item?.id}
      item={item}
      ActionBody={ActionBody}
      toggleOpen={toggleOpen}
      icon={icon}
    />
  ))
  return <>{shareList}</>
}

const withData = inject((stores) => ({
  cleanUp: stores.DeviceProfilesStore.cleanUp,
  list: stores.DeviceProfilesStore.list,
  getDeviceCategories: stores.DevicesStore.getDeviceCategories,
  deviceCategoriesCleanUp: stores.DevicesStore.deviceCategoriesCleanUp,
  getAll: stores.DeviceProfilesStore.getAll,
}))

export default withData(ModalContent)
