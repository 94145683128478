import { useRef, useEffect } from 'react'

const usePrevious = (data) => {
  const ref = useRef(data)
  useEffect(() => {
    ref.current = data
  })
  return ref.current
}

export default usePrevious
