import React from 'react'
import { inject, observer } from 'mobx-react'
import { Badge } from 'primereact/badge'

const PromotionsBadge = ({ notSeenPromotionsCount }) => {
  if (!notSeenPromotionsCount) return null

  return <Badge value={notSeenPromotionsCount} severity="danger" />
}

const withData = inject((stores) => ({
  notSeenPromotionsCount: stores.NotificationsStore.notSeenPromotionsCount,
}))

export default withData(observer(PromotionsBadge))
