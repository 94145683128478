import React, { useCallback } from 'react'
import { InputTextarea } from 'primereact/inputtextarea'

import { bindClassName } from '../../../utils/react'
import { useFormError } from '../../../hooks/form/useError'
import useLabel from '../../../hooks/form/useLabel'

const CustomTextarea = ({
  form,
  type,
  labelText,
  field,
  step,
  className,
  placeholder,
  afterLabel,
  isRequired,
  ...props
}) => {
  const { error, showError } = useFormError(form, field)

  const setTouched = useCallback(
    () => form.setFieldTouched(field.name, true, true),
    [form.setFieldTouched, field.name],
  )
  const { label, _placeholder } = useLabel({
    labelKey: labelText,
    field,
    placeholder,
  })

  return (
    <div className="p-grid p-dir-col p-nogutter p-my-2">
      <div className="p-flex p-justify-start p-align-end">
        {label}
        {afterLabel}
      </div>
      <InputTextarea
        {...bindClassName('common-input', [className], [showError, 'p-error'])}
        autoResize
        key={field.name}
        name={field.name}
        placeholder={_placeholder}
        value={field.value}
        type={type}
        step={step}
        onChange={field.onChange}
        onBlur={setTouched}
        {...props}
      />
      {error}
    </div>
  )
}

export default CustomTextarea
