import { observable } from 'mobx'

import { mapSearchParamsToObj } from '../hooks/useSearchParams'
import { parseBoolean } from '../utils/string'

export const initialSearchParams = mapSearchParamsToObj(window.location.search)
const { isDesktop } = initialSearchParams

class AppStore {
  @observable isDesktop = parseBoolean(isDesktop) || false
}

export default new AppStore()
