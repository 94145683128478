import React from 'react'
import { Calendar } from 'primereact/calendar'

const endYear = new Date().getFullYear()

const CalendarMultiple = ({ dates, setDates }) => {
  return (
    <Calendar
      monthNavigator
      yearNavigator
      yearRange={`2020:${endYear}`}
      mask="99/99/9999"
      dateFormat="dd/mm/yy"
      showIcon
      showButtonBar
      readOnlyInpu
      selectionMode="multiple"
      value={dates}
      onChange={(e) => setDates(e.value)}
    />
  )
}

export default CalendarMultiple
