import React, { useCallback, useEffect, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { ErrorMsg } from '../../constants/errorMsg'
import { ContactError } from '../messages/Error'
import Loadable from '../hoc/Loadable'
import CustomInput from '../common/CustomInput'
import CustomTextarea from '../common/CustomTextarea'
import useSearchParams from '../../hooks/useSearchParams'
import { feedbackSubjects } from '../../constants/feedback'
import CommonSubjects from './CommonSubjects'
import './ContactForm.scss'

const componentsMapping = {
  body: CustomTextarea,
}

const validationSchema = yup.object().shape({
  subject: yup.string().trim().required(ErrorMsg.CONTACT_SUBJECT_NOT_VALID),
  body: yup.string().trim().required(ErrorMsg.CONTACT_BODY_NOT_VALID),
})

const className = 'p-col-12'

const ContactForm = ({
  submitContact,
  isLoading,
  cleanUp,
  children,
  headerBtn,
}) => {
  const { t } = useTranslation()

  const { token } = useParams()
  const {
    subject: querySubject,
    name: queryName = '{{name}}',
  } = useSearchParams()

  const subjectValue = useMemo(() => {
    if (!querySubject || !feedbackSubjects.includes(querySubject)) return ''

    return t(`subject_contact.${querySubject}`, { name: queryName })
  }, [querySubject, t, queryName])

  useEffect(() => cleanUp, [])

  const initialValues = useMemo(() => ({ subject: subjectValue, body: '' }), [
    subjectValue,
  ])

  const handleSubmit = useCallback(
    (values, ...rest) => submitContact({ token, ...values }, ...rest),
    [subjectValue, token, submitContact],
  )

  return (
    <div className="contact-pages-wrapper">
      <div className="contact-content">
        <div className="p-grid">
          <p className="contact-pages-title-info">
            {t('contact.contact_head_line')}
          </p>
        </div>
        {headerBtn}

        <div className="common-card-form-block p-d-flex">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ dirty, setFieldValue }) => (
              <Form>
                <div className="p-fluid p-grid">
                  <ContactError />
                  <Loadable loading={isLoading}>
                    <CommonSubjects
                      className={className}
                      setFieldValue={setFieldValue}
                    />
                    {Object.keys(initialValues).map((key) => (
                      <div className={className} key={key}>
                        <Field
                          type="text"
                          name={key}
                          labelText={key}
                          component={componentsMapping[key] || CustomInput}
                        />
                      </div>
                    ))}
                    <Button
                      className="p-button-secondary contact-pages-submit-btn"
                      type="submit"
                      label={t('actions.send')}
                      disabled={!dirty}
                    />
                  </Loadable>
                </div>
              </Form>
            )}
          </Formik>
          {children}
        </div>
      </div>
    </div>
  )
}

const withData = inject((stores) => ({
  submitContact: stores.ContactStore.submitContact,
  cleanUp: stores.ContactStore.cleanUp,
  isLoading: stores.ContactStore.isLoading,
}))

export default withData(observer(ContactForm))
