import { useCallback } from 'react'
import moment from 'moment'

import { getDateRange, isValidDate } from '../../utils/date'
import { tableOrderMapping } from '../../constants'
import { defaultDatePredicate } from '../../utils/object'

export const formatFilters = (fields) => {
  return Object.entries(fields).reduce((acc, [key, value]) => {
    if (defaultDatePredicate(key, value) && isValidDate(value)) {
      value = moment(value).endOf('day').utc().format()
    }
    return { ...acc, [key]: value }
  }, {})
}

const useApplyFilters = (getAll, cleanUp, { sortOrder, ...pagination }) =>
  useCallback(
    ({ dateRange, ...rest }) => {
      const { from, to } = getDateRange(dateRange)
      cleanUp()
      getAll({
        ...rest,
        from,
        to,
        ...pagination,
        sortOrder: tableOrderMapping[sortOrder],
      })
    },
    [getAll, sortOrder, cleanUp, pagination],
  )

export default useApplyFilters
