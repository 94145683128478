import { useCallback, useEffect } from 'react'

import { partial, addListener } from '../utils/function'
import { isElemMatchesOrHasClosest } from '../utils/react'
import { mapToArray } from '../utils/array'

const useOutsideElementClick = (
  selector,
  cb,
  boundaryElemSelector = 'body',
  ignoreSelectors,
) => {
  const handleOutsideElementClick = useCallback(
    (e) => {
      const predicate = partial(isElemMatchesOrHasClosest, e.target)
      if (
        ![...mapToArray(ignoreSelectors), selector].some(predicate) &&
        predicate(boundaryElemSelector)
      ) {
        cb(e)
      }
    },
    [cb, ignoreSelectors, selector, boundaryElemSelector],
  )

  useEffect(
    () => addListener(document.body, 'click', handleOutsideElementClick),
    [handleOutsideElementClick],
  )
}

export default useOutsideElementClick
