import React from 'react'
import { inject, observer } from 'mobx-react'
import CommunityItem from '../../CommunityItem'
import { useTranslation } from 'react-i18next'
import { AiOutlineReload } from 'react-icons/ai'
import Loadable from '../../../hoc/Loadable'
import Condition from '../../../hoc/Condition'
import If from '../../../hoc/Condition/If'
import Else from '../../../hoc/Condition/Else'
import RenderIf from '../../../hoc/RenderIf'
import { GrDocumentText } from 'react-icons/gr'

const View = (props) => {
  const { t } = useTranslation()
  const {
    limit,
    offset,
    onPage,
    onSort,
    sortField,
    sortOrder,
    list,
    isLoading,
    count,
  } = props

  const profileList = list.map((item, index) => {
    return <CommunityItem item={item} key={index} />
  })
  const loadButton = (
    <button
      className={`p-button p-component secondary-btn load-promotion-btn`}
      onClick={onPage}
    >
      <AiOutlineReload />
      {t('labels.show_more')}
    </button>
  )

  return (
    <div className="community-scroll-content">
      <Loadable loading={isLoading}>
        <Condition condition={list.length}>
          <If>
            <div className="community-content">{profileList}</div>
            <RenderIf condition={list.length < count}>{loadButton}</RenderIf>
          </If>
          <Else>
            <div className="no-profile-msg-block">
              <div className="no-profile-icon">
                <GrDocumentText />
              </div>
              <span className="no-profile-msg">
                {t('labels.no_profiles_found')}
              </span>
            </div>
          </Else>
        </Condition>
      </Loadable>
    </div>
  )
}

const withData = inject((stores) => ({
  list: stores.CommunityStore.list,
  getOne: stores.CommunityStore.getOne,
  item: stores.CommunityStore.item,
  count: stores.CommunityStore.count,
  isLoading: stores.CommunityStore.isLoading,
}))

export default withData(observer(View))
