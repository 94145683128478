import React from 'react'
import { Form, Formik } from 'formik'

import DateRangeField from './DateRangeField'
import useApplyFilters from '../../../hooks/table/useApplyFilters'
import RenderIf from '../../hoc/RenderIf'
import PageTracker from '../PageTracker'
import ApplyFilters from '../ApplyFilters'
import Clear from '../Clear'
import Error from '../../messages/Error'
import './Filter.scss'

const CommonFiltersForm = ({
  getAll,
  limit,
  cleanUp,
  offset,
  sortField,
  sortOrder,
  applyLabel,
  initialValues,
  isLoading,
  validationSchema,
  errorMsg,
  className = '',
  errorComponent: ErrorComponent,
  children,
}) => {
  const applyFilters = useApplyFilters(getAll, cleanUp, {
    limit,
    offset,
    sortField,
    sortOrder,
  })
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={applyFilters}
      validationSchema={validationSchema}
    >
      {({ resetForm, submitForm, dirty }) => (
        <Form className={`filter-form ${className}`}>
          <RenderIf condition={limit !== void 0 || offset !== void 0}>
            <PageTracker
              submitForm={submitForm}
              limit={limit}
              offset={offset}
              sortField={sortField}
              sortOrder={sortOrder}
            />
          </RenderIf>
          <div className="p-d-flex filter-wrapper">
            {ErrorComponent && <ErrorComponent />}
            <Error errorMsg={errorMsg} />
            {children}
            <div className="p-col-12 p-sm-6 p-lg-3 p-xl-2 p-d-flex p-align-center p-dir-row">
              <ApplyFilters isLoading={isLoading} label={applyLabel} />
              <Clear
                dirty={dirty}
                resetForm={resetForm}
                submitForm={submitForm}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export { DateRangeField }

export default React.memo(CommonFiltersForm)
