import { action, observable } from 'mobx'

import api from '../../configs/api'
import Http, { buildQuery } from '../../helpers/http'
import AuthStore from '../Auth/mobx'
import ConfirmDialogStore from '../dialogs/CustomConfirmDialog/mobx'
import { Notification, success } from '../../helpers/notifications'
import { findById, findInCollection, findIndexById } from '../../utils/array'
import { NOTIFICATION_FAST_TIMEOUT } from '../../constants'
import { CommunityProfileStatus } from '../../constants/profiles'

class CommunityStore {
  resource = `${api.devices}/v1/community-profiles`

  NOTIFICATION_DELETED = Notification.COMMUNITY_PROFILE_DELETED

  @observable isLoading = false
  @observable list = []
  @observable count = 0
  @observable errorMsg = null
  @observable item = null

  @action
  downloadCommunityProfile = async ({ id, ...values }, { resetForm }) => {
    const isDownloaded = this.getIsDownloaded(id)
    if (isDownloaded === void 0) return

    if (isDownloaded) {
      const isConfirmed = await ConfirmDialogStore.open({
        header: 'profile_already_downloaded',
        message: 'profile_already_downloaded_message',
      })

      if (!isConfirmed) return
    }

    this.isLoading = true

    const { message = null, result } = await Http.post(
      `${this.resource}/${id}/device-profiles`,
      values,
    )
    this.errorMsg = message
    if (result) {
      resetForm()
      if (!isDownloaded) await this.getOne(id)
      success(Notification.COMMUNITY_PROFILE_DOWNLOADED)
    }
    this.isLoading = false
  }

  @action
  toggleLike = async (id) => {
    const isLiked = this.getIsLiked(id)
    if (isLiked === void 0 || !this.getIsLikable(id)) return

    const url = `${this.resource}/${id}/likes`

    this.isLoading = true

    let response, notification

    if (isLiked) {
      response = await Http.delete(url)
      notification = Notification.COMMUNITY_PROFILE_UN_LIKED
    } else {
      response = await Http.patch(url)
      notification = Notification.COMMUNITY_PROFILE_LIKED
    }

    const { message = null, result } = response
    this.errorMsg = message

    if (result) {
      if (this.item) this.item = { ...this.item, ...result }

      const idx = findIndexById(this.list, id)
      if (~idx) this.list[idx] = { ...this.list[idx], ...result }

      success(notification, {
        timeout: NOTIFICATION_FAST_TIMEOUT,
      })
    }

    this.isLoading = false
  }

  getIsLikable = (id) => {
    const item = this.getItem(id)
    if (!item) return

    return item.status === CommunityProfileStatus.Published
  }

  getIsLiked = (id) => {
    const item = this.getItem(id)
    if (!item) return

    return !!findInCollection(
      'UserId',
      item.CommunityProfileLikes,
      AuthStore.user.id,
    )
  }

  getIsDownloaded = (id) => {
    const item = this.getItem(id)
    if (!item) return

    return !!findInCollection(
      'UserId',
      item.CommunityProfileDownloads,
      AuthStore.user.id,
    )
  }

  getItem = (id) => {
    return this.item || findById(this.list, id)
  }

  @action
  getAll = async (fields = {}) => {
    this.isLoading = true
    const query = buildQuery({ ...fields, withNested: true })
    const { message = null, result = [], count = 0 } = await Http.get(
      `${this.resource}${query}`,
    )
    this.errorMsg = message
    this.list = [...this.list, ...result]
    this.count = count
    this.isLoading = false
  }

  @action
  deleteOne = async (id) => {
    const isConfirmed = await ConfirmDialogStore.open({
      header: 'delete_community_profile',
      message: 'delete_community_profile_message',
    })
    if (!isConfirmed) return

    this.isLoading = true
    const response = await Http.delete(`${this.resource}/${id}`)
    if (response?.message) {
      this.errorMsg = response.message
    } else {
      this.list = this.list.filter((elem) => elem.id != id)
      success(this.NOTIFICATION_DELETED)
    }
    this.isLoading = false
  }

  @action
  getOne = async (id) => {
    const profile = this.list.find((elm) => elm.id === id)
    if (profile?.devices) return

    this.isLoading = true
    const { message = null, result } = await Http.get(`${this.resource}/${id}`)
    this.errorMsg = message
    if (result) {
      if (profile) {
        profile.devices = result?.devices
      } else {
        this.item = result
      }
    }
    this.isLoading = false
  }

  @action
  cleanUp = () => {
    this.isLoading = false
    this.list = []
    this.count = 0
    this.errorMsg = null
    this.item = null
  }
}

export default new CommunityStore()
