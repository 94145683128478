import React from 'react'
import { inject, observer } from 'mobx-react'

import withEffect from '../../../../assets/images/commutity/lightning-effect/with.png'
import without from '../../../../assets/images/commutity/lightning-effect/without.png'

const LightningEffectsTag = ({ isLightingEffectsOnly }) => {
  const effectImg = isLightingEffectsOnly ? withEffect : without

  return <img className="light-effect-icon p-ml-4" src={effectImg} />
}

const withData = inject((stores) => ({
  isLightingEffectsOnly: stores.CommunityStore.item?.isLightingEffectsOnly,
}))

export default withData(observer(LightningEffectsTag))
