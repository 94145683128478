import React from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'

import { bindClassName } from '../../../utils/react'
import Logo from '../../Logo'
import UserInfo from '../UserInfo'
import SettingsMenu from './SettingsMenu'
import NotificationIcon from '../../Notifications/NotificationIcon'

const Header = ({ children, isOpenNav, toggleOpenNav }) => {
  return (
    <header>
      <div
        {...bindClassName('burger-menu', [!isOpenNav, 'active'])}
        onClick={toggleOpenNav}
      >
        <MdKeyboardArrowDown />
      </div>
      <div className="nav-container container">
        <Logo />
        <ul {...bindClassName('nav-bar-menu', [!isOpenNav, 'active'])}>
          {children}
        </ul>
        <div className="user-profile-block">
          <NotificationIcon />
          <SettingsMenu />
          <UserInfo />
        </div>
      </div>
    </header>
  )
}

export default Header
